import { useContext, useEffect } from 'react';
import { store } from '../../../context/Context';
import { capitalize } from 'app/utils';
import Dropdown from '../../Shared/Input/Dropdown';
import DropdownItem from '../../Shared/Input/DropdownItem';
import { Currency } from 'app/constants/types';
import { getData, setData } from '../../../utils/storage';
type Props = {
  onPress?: () => void;
};
const CurrencySwitcher = ({
  onPress = () => {
    return;
  },
}: Props) => {
  const {
    state: { currencies, currency },
    dispatch,
  } = useContext(store);

  const onCurrencyPress = (item: Currency) => {
    onPress();
    dispatch({
      type: 'update',
      key: 'currency',
      value: item,
    });
    setData('currency', item);
  };

  useEffect(() => {
    (async () => {
      const currency = await getData('currency');
      if (currency) {
        dispatch({
          type: 'update',
          key: 'currency',
          value: currency,
        });
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="xl:ml-4 xl:w-[105px]">
      <Dropdown
        menuButtonClassName="h-[40px]"
        options={currencies}
        renderValue={() => (
          <div className="flex items-center">
            <span className="ml-2 text-blue10 small1 font-black">
              {capitalize(currency?.symbol || '')}
            </span>
          </div>
        )}
        renderOptions={({ option: item, active }: any) => {
          const isSelected = capitalize(currency.symbol) === capitalize(item.symbol);
          return (
            <DropdownItem
              key={item.symbol}
              isSelected={isSelected}
              onClick={() => onCurrencyPress(item)}
              value={capitalize(item.symbol)}
              active={active}
              showIcon={false}
            />
          );
        }}
      />
    </div>
  );
};

export default CurrencySwitcher;

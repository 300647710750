import Card from '../../Shared/Card';
import GasBanner from './GasBanner';

const Gas = () => {
  return (
    <Card shadow>
      <GasBanner />
    </Card>
  );
};

export default Gas;

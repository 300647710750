import { highGas, lowGas } from '../constants/gas';
import { Network } from '../constants/types';
import { getGasQuote } from './api';

export const getGasPriceDetails = async (network: Network) => {
  const gasQuote = await getGasQuote(network);
  const historicalGasPrice = gasQuote?.gas_data.map((gas: any) => {
    return { x: gas.timestamp, y: gas.gas_price };
  });

  const gasPrice = gasQuote?.current_gas;
  const isHighestPrice = gasQuote?.gas_price > highGas;
  const isLowestPrice = gasQuote?.gas_price < lowGas;

  return [gasPrice, historicalGasPrice, isHighestPrice, isLowestPrice];
};

import { useContext, useEffect } from 'react';
import { store } from '../../../context/Context';
import TextInput from '../../Shared/Input/TextInput';
import { XIcon } from '@heroicons/react/outline';
import Button from '../../Shared/Button';
import ErrorAlert from '../../Shared/Alert/ErrorAlert';
import Spinner from '../../Shared/Spinner';
import {
  getPromotionRejectionStatus,
  postPromotionRejectionStatus,
  referralCodeObject,
} from 'app/common/Referral';
import { referralMessages } from 'app/constants';
import useReferral from './hooks/useReferral';

type Props = { setSucceed: (value: boolean) => any };

const ReferralBanner = ({ setSucceed }: Props) => {
  const {
    state: { profile, user },
  } = useContext(store);

  const {
    welcomeReferral,
    loading,
    setLoading,
    error,
    setError,
    referral,
    handleReferral,
    isHidden,
    setIsHidden,
    addReferralCode,
    message,
    setMessage,
  } = useReferral();

  useEffect(() => {
    async function CheckRejectionStatus() {
      const referralCode = referralCodeObject(profile.id, welcomeReferral);
      try {
        const promotionRejectionStatus = await getPromotionRejectionStatus(user, referralCode);
        setIsHidden(promotionRejectionStatus);
        setLoading(false);
      } catch (error) {
        setError(true);
        console.error(referralMessages.CHECK_PROMOTION_ERROR);
      }
    }
    setLoading(true);
    if (welcomeReferral) {
      CheckRejectionStatus();
    }
  }, [welcomeReferral, profile, user, setLoading, setIsHidden, setError]);

  const rejectReferralCode = async () => {
    const referralCode = referralCodeObject(profile.id, welcomeReferral);
    try {
      const res = await postPromotionRejectionStatus(user, referralCode);
      if (res?.msg) {
        setError(true);
        setMessage(referralMessages.CAN_NOT_CLOSE_BANNER);
      } else {
        setIsHidden(true);
      }
    } catch (error) {
      setError(true);
      setMessage(referralMessages.CLOSE_BANNER_ERROR);
    }
  };

  const addreferralcode = () => {
    addReferralCode();
    setSucceed(true);
  };

  if (isHidden) {
    return null;
  }

  return (
    <div className="bg-blue80 rounded-lg p-4">
      <div>
        <div className="flex justify-end">
          <button onClick={rejectReferralCode} className="">
            <XIcon className="w-5 h-5 text-grey40" />
          </button>
        </div>
        <p className="body2 text-blue10">Enter Referral Code</p>
        <p className="bodySmall1 text-blue10 mt-2 mb-2">Redeem your reward.</p>
      </div>
      <div>
        <TextInput
          placeholder="Enter your referral code here"
          value={referral}
          onChange={(e: any) => handleReferral(e)}
          style={{ background: '#FFFFFF' }}
          className="rounded-full small1"
        />
      </div>
      <div>
        {error && (
          <div className="mt-4">
            <ErrorAlert message={message} onClose={() => setMessage('')} />
          </div>
        )}
        <Button full className="mt-4" onClick={addreferralcode} disabled={Boolean(!referral)}>
          {loading ? <Spinner /> : 'Done'}
        </Button>
      </div>
    </div>
  );
};

export default ReferralBanner;

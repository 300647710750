import { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import Button from '../Shared/Button';
import BasicModal from '../Shared/BasicModal';
import Tooltip from '../Shared/Tooltip';

type Props = {
  title: string;
  content: string;
};

const TransactionFeeInfo = ({ title, content }: Props) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className="hidden md:flex">
        <Tooltip title={title} content={content}>
          <Button className="btn-ghost text-primary -mt-1 p-0 ml-2  btn-xs">
            <InformationCircleIcon className="h-4 w-4" />
          </Button>
        </Tooltip>
      </div>
      <div className="flex md:hidden">
        <Button
          className="btn-ghost text-primary -mt-1 p-0 ml-2 btn-xs"
          onClick={() => setOpenModal(true)}
        >
          <InformationCircleIcon className="h-4 w-4" />
        </Button>

        {openModal && (
          <BasicModal open={openModal} title={title} onClose={() => setOpenModal(false)}>
            <p className="body1 text-grey10 mb-4">{content}</p>
            <Button full onClick={() => setOpenModal(false)}>
              Got it
            </Button>
          </BasicModal>
        )}
      </div>
    </>
  );
};

export default TransactionFeeInfo;

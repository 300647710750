module.exports = {
  // Main
  primary: '#0070F4',
  blue10: '#132950', // Primary Text
  blue30: '#3861FB',
  blue40: '#EAF3FD', // Secondary Button
  blue60: '#CFDCF5',
  blue80: '#F4F8FB', //Input field
  grey10: '#808080',
  grey20: '#7E848F', //Secondary Text
  grey30: '#C4C4C4',
  grey40: '#C7C8CE', //Tertiary
  grey60: '#DBE0E9',
  grey70: '#EFF3F9', // Disabled
  grey80: '#F5F5F5',
  grey90: '#D3D3D3',
  red80: 'rgba(239, 82, 82, 0.08)', // #EF5252 with 8% opacity
  green80: 'rgba(49, 226, 141, 0.12)', // #31E28D with 12% opacity
  orange80: '#FF9900',
  orange40: '#FFEFE6',
  violet60: '#E6E1FB',
  violet80: '#7000FF',

  // System
  error: '#F84671',
  success: '#31E28D',
  info: '#FAEAE1',

  // gasFeeBackgound
  gasHighBackground: '#FDF0F0',
  gasAvgBackground: '#FFEFE5',
  gasLowBackground: '#E7FAE7',
  gasHighText: '#F84671',
  gasAvgText: '#FF9900',
  gasLowText: '#31E28D',
  // Gradient
  gradient0: '#5B207E',
  gradient1: '#2D1A6F',
  gradient2: '#131653',
  gradient3: '#EAE3FF',
  gradient4: '#F9F7FF',

  bannerGradient0: '#9370F4',
  bannerGradient1: '#F846AA',

  bannerGradient2: '#131653',
  bannerGradient3: '#2D1A6F',
  bannerGradient4: '#5B207E',

  bannerGradient5: '#DDDAFF',
  bannerGradient6: '#FFE1B4',

  slantedColor1: '#6DD2F5',
  slantedColor2: '#597DF7',
  slantedColor3: '#B16CF4',

  buttonGradient1: '#97E2F2',
  buttonGradient2: '#5D73DF',
  buttonGradient3: '#BB2CCF',

  // Others
  background: '#FEFEFE',
  text: '#000',
  horizontalDivider: '#F5F5F5',
  white: '#FFFFFF',

  // Palette for Graphs
  purple20: '#3F99F5',
  purple60: '#2C7BCC',
  teal20: '#8EDDFF',
  teal60: '#78C9EB',
  pink20: '#D086FD',
  pink60: '#AA69D2',
  chartVolume: '#b7d8ff',

  // Buttons
  primaryPressed: '#398FF4',
  secondaryPressed: '#C9E2FF',

  //Reports icons
  deposit: '#54D6DE',

  // Accordian Border
  accordianBorder: '#DFDFDF',

  //Collection List
  activeBackgroundBlue: '#F7F7FB',
};

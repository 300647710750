import React, { useState, useEffect, forwardRef } from 'react';
import Spinner from '../Spinner';
import { SearchIcon, XIcon } from '@heroicons/react/solid';
import TextInput from './TextInput';

type props = {
  value: string;
  onChangeText: (value: string) => void;
  onPressClear: () => void;
  className: any;
};
const SearchBar = forwardRef<HTMLInputElement, props>(
  ({ value, onChangeText, onPressClear, className }: props, ref) => {
    const [input, setInput] = useState(value);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const timeoutFunction = () => {
        onChangeText(input);
        setLoading(false);
      };

      const timeout = setTimeout(timeoutFunction, 500);
      setLoading(true);

      return () => {
        clearTimeout(timeout);
      };
    }, [input, onChangeText]);

    const onClear = () => {
      setInput('');
      onPressClear();
    };

    return (
      <div className={className}>
        <SearchIcon className="w-5 h-5 text-grey20 absolute left-0 ml-5 mr-4" />
        <TextInput
          placeholder="Search"
          value={value}
          onChange={(e: any) => onChangeText(e.target.value)}
          className="pl-14"
          ref={ref}
        />
        {Boolean(value) && !loading && (
          <XIcon
            onClick={onClear}
            className="w-5 h-5 text-grey20 absolute inset-y-0 right-0 ml-5 mr-4 mt-3.5"
          />
        )}
        {loading && (
          <Spinner className="text-primary absolute inset-y-0 right-0 ml-5 mr-4 mt-3.5" />
        )}
      </div>
    );
  },
);

SearchBar.displayName = 'SearchBar';

export default SearchBar;

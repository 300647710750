import React from 'react';
import { VictoryLine, VictoryContainer, VictoryArea, VictoryChart, VictoryAxis } from 'victory';
import colors from 'app/constants/colors';

interface Props {
  performanceData: Array<{ x: any; y: any }>;
}

export default function MiniPerformanceGraph({ performanceData }: Props) {
  //Victory line cannot render single data point so duplicate with x=x+1 to display as line
  if (performanceData.length === 1) {
    performanceData.push({ x: String(Number(performanceData[0].x) + 1), y: performanceData[0].y });
  }

  return (
    <div className="align-middle">
      <VictoryChart padding={12} containerComponent={<VictoryContainer />}>
        <>
          <defs>
            <linearGradient id="myGradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor={colors.orange80} />
              <stop offset="100%" stopColor="rgba(255, 153, 0, 0)" />
            </linearGradient>
          </defs>
        </>
        <VictoryLine
          style={{
            data: { stroke: colors.orange80, strokeWidth: 2.43 },
          }}
          data={performanceData}
          interpolation="natural"
        />
        <VictoryArea
          style={{
            data: { fill: 'url(#myGradient)' },
          }}
          data={performanceData}
          interpolation="natural"
        />
        <VictoryAxis
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            tickLabels: { fill: 'transparent' },
          }}
        />
      </VictoryChart>
    </div>
  );
}

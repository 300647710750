import React from 'react';
import { classNames } from 'app/utils';

type Props = {
  className?: string;
  color: string;
  backgroundColor: string;
  text: string;
};

const Chip = ({ className, color, backgroundColor, text }: Props) => {
  return (
    <div
      className={classNames('rounded-lg px-2 md:px-4 py-0.5 small2 md:bodySmall2', className || '')}
      style={{ color: color, backgroundColor: backgroundColor }}
    >
      {text}
    </div>
  );
};

export default Chip;

import { useCallback, useState } from 'react';
import { validationErrors } from 'app/constants';
import { AppState, Transaction, Transfer, Wallet } from '../../../constants/types';
import { handleWithdrawError } from 'app/common/Withdraw';
import useSendEthTrx from '../../Trade/hooks/useSendEthTrx';

type Props = {
  state: AppState;
  onTransactionSent: () => void;
  setOpenExternal: (open: boolean) => void;
  getTrxParams: (transfer: Transfer) => Promise<Transaction>;
  sendTransaction: (wallet: Wallet, params: Transaction) => Promise<any>;
};

const useEthTrx = ({
  state,
  onTransactionSent,
  setOpenExternal,
  getTrxParams,
  sendTransaction,
}: Props) => {
  const { wallet } = state;

  const [ethTrx, setEthTrx] = useState(null);
  const [sendingEthTrx, setSendingEthTrx] = useState(false);
  const [ethTrxError, setEthTrxError] = useState('');

  const handleCancel = useCallback(() => {
    setSendingEthTrx(false);
    setOpenExternal(false);
  }, [setOpenExternal]);

  useSendEthTrx({
    state,
    ethTrx,
    setEthTrx,
    setEthTrxError,
    setSendingEthTrx,
    handleCancel,
    onTransactionSent,
  });

  const sendEthTransaction = async (params: Transaction) => {
    try {
      setSendingEthTrx(true);
      const trx = await sendTransaction(wallet, params);

      if (trx && trx.hash) setEthTrx(trx);
      else setEthTrx({ hash: trx });
    } catch (e: any) {
      setEthTrxError(handleWithdrawError(e, validationErrors.FAILED_CREATE_TRANSACTION));
      handleCancel();
    }
  };

  const createEthTransaction = async (transfer: Transfer) => {
    try {
      // Get transfer params
      const params = await getTrxParams(transfer);

      // Send trx
      await sendEthTransaction(params);
    } catch (e: any) {
      setEthTrxError(handleWithdrawError(e, validationErrors.FAILED_CREATE_TRANSACTION));
      handleCancel();
    }
  };

  return {
    ethTrx,
    sendingEthTrx,
    ethTrxError,
    createEthTransaction,
    handleCancelEthTrx: handleCancel,
  };
};

export default useEthTrx;

import Link from 'next/link';
import { useState } from 'react';
import LandingPageContainer from './LandingPageContainer';

type Props = {
  href: string;
  canVisit?: boolean;
  [key: string]: any;
};

const RequireLoginLink = ({ children, href, canVisit = false, ...rest }: Props) => {
  const [openLogin, setOpenLogin] = useState(false);

  if (!canVisit) {
    return (
      <>
        <button onClick={() => setOpenLogin(true)}>{children}</button>
        <LandingPageContainer
          handleClose={() => setOpenLogin(false)}
          meta={{ title: 'Login' }}
          open={openLogin}
          redirectURL={href}
        />
      </>
    );
  }

  return (
    <Link href={href} {...rest}>
      {children}
    </Link>
  );
};

export default RequireLoginLink;

import { useEffect, useState, useContext } from 'react';
import { store } from '../../../context/Context';
import { formatMoney } from 'app/utils';
import { getGasPriceDetails } from 'app/utils/gas';
import { formatDateTime } from 'app/utils/date';
import MiniPerformanceGraph from '../../Explore/MiniPerformanceGraph';
import Card from '../../Shared/Card';
import Chip from '../../Shared/Chip';
import colors from 'app/constants/colors';

const GasBanner = () => {
  const [currentGasPrice, setCurrentGasPrice] = useState(null);
  const [historicalGasPrice, setHistoricalGasPrice] = useState(null);
  const [isHighest, setIsHighest] = useState(false);
  const [isLowest, setIsLowest] = useState(false);
  const { state } = useContext(store);

  useEffect(() => {
    (async () => {
      setCurrentGasPrice(null);
      setHistoricalGasPrice(null);

      const [gasPrice, historicalGasPrice, isHighestPrice, isLowestPrice] =
        await getGasPriceDetails(state.network);

      setCurrentGasPrice(gasPrice);
      setHistoricalGasPrice(historicalGasPrice);
      setIsHighest(isHighestPrice);
      setIsLowest(isLowestPrice);
    })();
  }, [state.network]);

  return (
    <div className="p-4">
      <div className="hidden xl:block mt-3 mb-7 pl-3">
        <div className="h2 text-blue10">Current network fees</div>
        <div className="">
          <span className="text-blue10 bodySmall1">
            {currentGasPrice
              ? formatDateTime(new Date(Number(currentGasPrice?.timestamp) * 1000))
              : formatDateTime(new Date())}
          </span>
        </div>
      </div>
      <div className="bg-blue80 px-4 xl:px-7 pb-4 rounded-lg space-y-4 mb-5 xl:mb-0">
        <div className="xl:hidden">
          <div className="bodySmall2 text-blue10 pt-4 pb-1.5">Current network fees</div>
          <div className="flex justify-between items-center">
            <div className="small1 text-grey20">
              {currentGasPrice
                ? formatDateTime(new Date(Number(currentGasPrice?.timestamp) * 1000))
                : formatDateTime(new Date())}
            </div>
          </div>
        </div>
        <div>
          <div className="bodySmall2 xl:body2 text-blue10 xl:pt-5 pb-1 xl:pb-0">
            Current gas fees
          </div>
          <div className="flex justify-between items-end">
            <div className="small2 xl:body2 text-grey20">
              {currentGasPrice ? `${currentGasPrice?.gas_price.toFixed(0)} GWEI` : '- GWEI'}
            </div>
            <Chip
              className="ml-1 xl:ml-0"
              color={isHighest ? colors.error : isLowest ? colors.success : colors.gasAvgText}
              backgroundColor={
                isHighest
                  ? colors.gasHighBackground
                  : isLowest
                  ? colors.gasLowBackground
                  : colors.info
              }
              text={isHighest ? 'High' : isLowest ? 'Low' : 'Average'}
            />
          </div>
        </div>
        <div>
          <div className="bodySmall2 xl:body2 text-blue10 pb-1 xl:pb-0">Transfer cost</div>
          <div className="flex justify-between items-center">
            <div className="small2 xl:body2 text-grey20">
              {currentGasPrice
                ? formatMoney(currentGasPrice.withdraw_fee * state.ethPrice, state.currency)
                : `-`}
            </div>
          </div>
        </div>
        <div>
          <div className="bodySmall2 xl:body2 text-blue10 pb-1 xl:pb-0">Swap cost</div>
          <div className="flex justify-between items-center">
            <div className="small2 xl:body2 text-grey20">
              {currentGasPrice
                ? formatMoney(currentGasPrice.swap_fee * state.ethPrice, state.currency)
                : `-`}
            </div>
          </div>
        </div>
        <div className="smallUpper xl:hidden pt-0.5">
          <a
            href="https://www.wonder.fi/resources/what-are-network-fees"
            target="_blank"
            className="text-primary"
            rel="noreferrer"
          >
            WHAT ARE NETWORK FEES?
          </a>
        </div>
      </div>
      <div className="hidden xl:block px-2">
        <h2 className="body2 mt-6 mb-4">Gas price history</h2>
        <Card className="border">
          {historicalGasPrice ? (
            <MiniPerformanceGraph performanceData={historicalGasPrice} />
          ) : (
            <div className="w-full h-30" />
          )}
        </Card>
      </div>
      <a
        href="https://www.wonder.fi/resources/what-are-network-fees"
        target="_blank"
        className="hidden xl:flex label xs:text-primary justify-center mt-5"
        rel="noreferrer"
      >
        WHAT ARE NETWORK FEES?
      </a>
    </div>
  );
};

export default GasBanner;

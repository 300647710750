import { User, ReferralCode } from '../../constants/types';
import {
  getAllPromotions,
  getUserPromotionRejectionStatus,
  postUserPromotionRejectionStatus,
  postUserPromotions,
} from '../../utils/api';

let res;
export const getPromotions = async (user: User) => {
  try {
    res = await getAllPromotions(user);
    if (res && res.length) {
      return res[res?.length - 1];
    }
  } catch (err) {
    console.error(err);
  }
};

export const getPromotionRejectionStatus = async (user: User, referralCode: ReferralCode) => {
  try {
    res = await getUserPromotionRejectionStatus(user, referralCode);
    return res && res?.referral_rejected;
  } catch (err) {
    console.error(err);
  }
};

export const postPromotionRejectionStatus = async (user: User, referralCode: ReferralCode) => {
  try {
    res = await postUserPromotionRejectionStatus(user, referralCode);
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const postPromotions = async (user: User, referralCode: ReferralCode) => {
  try {
    res = await postUserPromotions(user, referralCode);
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const referralCodeObject = (profile: string, referral: any) => {
  return {
    user_id: profile,
    unique_campaign_identifier: referral,
  };
};

import { useRouter } from 'next/router';
import { useContext } from 'react';
import { store } from '../../context/Context';
import LandingPageContainer from './LandingPageContainer';

type Props = {
  children: React.ReactNode;
  open?: boolean;
  onClose?: () => void;
  fallback?: React.ReactNode;
  includeLoginPopup?: boolean;
};

const RequireLogin = ({ children, open, onClose, fallback, includeLoginPopup = true }: Props) => {
  const {
    state: { user, network },
  } = useContext(store);

  const router = useRouter();

  const handleClose = () => {
    onClose ? onClose() : router.push(`/explore/${network?.prefix}`);
  };

  if (!user) {
    return (
      <>
        {fallback}
        {includeLoginPopup && (
          <LandingPageContainer handleClose={handleClose} meta={{ title: 'Login' }} open={open} />
        )}
      </>
    );
  }

  return <>{children}</>;
};

export default RequireLogin;

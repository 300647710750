import { ethers } from 'ethers';
import { Balance, Token, Stat, WalletBalances, Network, Currency } from '../constants/types';
import {
  formatMoney,
  formatCrypto,
  getTokenBalance,
  sortByBalance,
  formatCryptoBN,
  formatPercentage,
} from './';

export const getTokenImageUrl = (token: any) => {
  if (!token) {
    return '';
  }
  if (token.logoURI) {
    return token.logoURI;
  }
  if (token.image) {
    return token.image;
  }

  let address = token.address;
  if (address === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee' || address === 'ETH') {
    address = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'; // WETH
  }

  if (!address) {
    return '';
  }

  if (!ethers.utils.isAddress(address)) {
    return '';
  } else {
    return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${ethers.utils.getAddress(
      address,
    )}/logo.png`;
  }
};

export const filterTokens = (allTokens: Token[], tag: string) => {
  if (!allTokens) {
    return [];
  }
  return allTokens.filter((token) => !tag || token.tags.indexOf(tag) !== -1);
};

export const filterBalances = (allTokens: Token[], balances: Balance[], tag: string) => {
  if (!allTokens || !balances) {
    return [];
  }
  const filteredTokens = filterTokens(allTokens, tag).map((token) => token.address);

  return balances.filter((balance) => filteredTokens.indexOf(balance.token_address) !== -1);
};

export const getTokenStats = (
  stats: { [key: string]: number },
  token: Token,
  currency: Currency,
): Stat[] => {
  return [
    ...(token.supply_rate != 0
      ? [{ label: 'APY', value: formatPercentage(token.supply_rate * 100, 2) ?? '-' }]
      : []),
    { label: 'Market Cap Ranking', value: stats.market_cap_rank ?? 0 },
    { label: 'Launch Date', value: '-' },
    { label: 'Market Cap', value: formatMoney(stats.market_cap ?? 0, currency) },
    { label: 'Fully Diluted', value: '-' },
    { label: '24H Volume', value: formatMoney(stats.one_day_volume ?? 0, currency) },
    {
      label: '24H Low / High',
      value: `${formatMoney(stats.one_day_low ?? 0, currency)} / ${formatMoney(
        stats.one_day_high ?? 0,
        currency,
      )}`,
    },
    {
      label: 'Circulating Supply',
      value: stats.circulating_supply
        ? formatCrypto(stats.circulating_supply, token.symbol, 2)
        : '-',
    },
    {
      label: 'Max Supply',
      value: stats.max_supply ? formatCrypto(stats.max_supply, token.symbol, 2) : '-',
    },
  ];
};

export const matchTokenSearch = (token: Token, search: string) => {
  if (
    token.symbol.includes(search.toUpperCase()) ||
    token.name.toUpperCase().includes(search.toUpperCase())
  ) {
    return token;
  } else {
    return null;
  }
};

export const validateTokenInput = (input: string, token: Token) => {
  if (!input) {
    return '';
  }
  const value = input.replace(/,/g, '.');
  const decimals = value.split('.')[1]?.length || 0;
  const numberValue = Number(value);

  if (value === '.') return '0.';
  else if (isNaN(numberValue)) return null;
  else if (value.includes('e')) return null;
  else if (numberValue > Number.MAX_SAFE_INTEGER) return null;
  else if (decimals > token?.decimals) return null;
  else return value;
};

export const getPopularTokens = (allTokens: any, popularAccount: any, account: any) => {
  const popularTokens = allTokens.filter((token: any) => {
    if (!popularAccount) return false;
    if (
      token.tags.indexOf(popularAccount.tags[0]) === -1 ||
      token.tags.indexOf(account.tags[0]) === -1
    ) {
      return false;
    }
    return true;
  });

  return popularTokens;
};

export const getTokenBalances = (
  tokens: any,
  walletBalances: any,
  wallet: any,
  currency: Currency,
) => {
  return tokens?.map((token: any) => {
    const balance: any =
      walletBalances &&
      Object.values(walletBalances).find((t: any) => t.token_address === token.address);

    const priceBalance = formatMoney(balance?.balance_usd || 0, currency);
    const detail = balance?.detail.find((d: { wallet_id: string }) => d.wallet_id === wallet);
    const tokenBalance = detail ? formatCryptoBN(detail.balance, token) : '0';

    return { ...token, balance_usd: balance?.balance_usd || 0, priceBalance, tokenBalance };
  });
};

export const emptyToken: Token = {
  label: '',
  value: '',
  symbol: '',
  address: '',
  balance: '0',
  price_in_usd: 0,
  decimals: 0,
  ID: '',
  name: '',
  coingecko_id: '',
};

export const getTokenOptions = (allTokens: Token[], walletBalances: WalletBalances) => {
  const options = allTokens
    .map((t) => ({
      ...t,
      label: t.symbol,
      value: t.address,
      balance: getTokenBalance(walletBalances, t),
      disabled: false,
    }))
    .sort(sortByBalance);

  return { options };
};

export const isNativeToken = (token: Token, network: Network) => {
  return token?.symbol === network?.network_token;
};

export const getNativeToken = (tokens: Token[], network: Network) => {
  if (tokens?.length) {
    const token = tokens.find((t) => isNativeToken(t, network));
    return token;
  }
  return null;
};

import { classNames } from 'app/utils';
import React from 'react';
import Divider from '../Shared/Divider';
import LearnCard from './LearnCard';
import LearnCarousel from './LearnCarousel';
const LearnItems = [
  {
    title: 'What are network fees?',
    body: 'Network fees occur every time you send cryptocurrency from one address to another.',
    link: 'https://www.wonder.fi/resources/what-are-network-fees',
  },
  {
    title: 'What is a crypto wallet?',
    body: 'Crypto wallets are a software program or physical device like Ledger that are used for storing and accessing cryptocurrencies.',
    link: 'https://www.wonder.fi/resources/what-is-a-crypto-wallet',
  },
];

type Props = {
  className?: string;
  carousel?: boolean;
};
export default function Learn({ className, carousel = false }: Props) {
  return (
    <>
      {carousel ? (
        <div className={className}>
          <div className="shadow-light p-4 rounded-xl">
            <div className="title2">Resources</div>
            <Divider text="" className="my-5" borderStyle="border-grey70 border-2" />
            <LearnCarousel slides={LearnItems} />
          </div>
        </div>
      ) : (
        <div className={classNames('shadow-light p-4 rounded-xl', className ? className : '')}>
          <div className="title2">Resources</div>
          {LearnItems.map((item) => (
            <React.Fragment key={item.title}>
              <Divider text="" className="my-5" borderStyle="border-grey70 border-2" />
              <LearnCard key={item.title} learnItem={item} className="first:mt-0 mt-6" />
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
}

import { useState, useEffect } from 'react';
import { Order, Quote, AppState } from '../../../constants/types';
import {
  getAllowanceFee,
  getBestRoute,
  hasAllowanceFee,
  getNetworkFee,
  getCommissionFee,
  getGrossAmount,
  getTotalReceive,
} from 'app/common/Trade';

type Props = {
  allowance: string;
  quote: Quote;
  order: Order;
  state: AppState;
};

const useOrderDetails = ({ quote, allowance, order, state }: Props) => {
  const { user, network } = state;

  const [totalReceive, setTotalReceive] = useState(0);
  const [grossAmount, setGrossAmount] = useState(0);
  const [route, setRoute] = useState(null);
  const [commissionFee, setCommissionFee] = useState('0');
  const [networkFee, setNetworkFee] = useState('0');
  const [allowanceFee, setAllowanceFee] = useState('0');

  const { amount0, token0, token1 } = order;

  useEffect(() => {
    setGrossAmount(getGrossAmount(quote, token1));
    setTotalReceive(getTotalReceive(quote, token1));
  }, [quote, token1]);

  useEffect(() => {
    setRoute(getBestRoute(quote));
  }, [quote]);

  useEffect(() => {
    const timeoutFunction = async () => {
      const gasFee = await getAllowanceFee(user, network, token0);
      setAllowanceFee(gasFee);
    };

    let timeout: NodeJS.Timeout;
    if (hasAllowanceFee(token0, amount0, allowance, network)) {
      clearTimeout(timeout);
      timeout = setTimeout(timeoutFunction, 500);
    } else {
      setAllowanceFee('0');
    }

    return () => clearTimeout(timeout);
  }, [allowance, amount0, token0, network, user]);

  useEffect(() => {
    setNetworkFee(getNetworkFee(quote, allowanceFee, token0, network));
  }, [quote, token0, allowanceFee, network]);

  useEffect(() => {
    setCommissionFee(getCommissionFee(quote, token0, token1, network));
  }, [quote, token0, token1, network]);

  return {
    totalReceive,
    grossAmount,
    route,
    commissionFee,
    networkFee,
    allowanceFee,
  };
};

export default useOrderDetails;

import { useContext } from 'react';
import { classNames } from 'app/utils/';
import { UnitInputs, UnitInput } from 'app/constants/types';
import { store } from '../../context/Context';

type Props = {
  inputUnits: UnitInputs;
  onChange: (value: string) => void;
  value: string;
  className: string;
};

const SwapCurrencySwitcher = ({ value, onChange, inputUnits, className }: Props) => {
  const { state } = useContext(store);
  return (
    <div
      className={classNames(
        className ? className : '',
        'bg-blue80 pt-1 pb-1 pl-2 pr-2 grid grid-cols-2 gap-x-4 rounded-xl',
      )}
    >
      {Object.values(inputUnits).map((input: UnitInput) => (
        <span
          key={input.id}
          onClick={() => onChange(input.id)}
          className={classNames(
            value === input.id ? 'bg-white text-blue10' : 'text-grey40',
            'p-2 rounded-xl cursor-pointer small2 text-center',
          )}
        >
          {input.label(state.currency.symbol)}
        </span>
      ))}
    </div>
  );
};

export default SwapCurrencySwitcher;

import { ethers } from 'ethers';
import { Network, Transaction, User } from '../constants/types';

export const getWonderFiSigner = (user: User, network: Network) => {
  const provider = ethers.providers.getDefaultProvider(network?.web3_endpoint);
  const wallet = new ethers.Wallet(user.privateKey, provider);
  return wallet;
};

export const sendWonderFiTransaction = async (
  user: User,
  params: Transaction,
  network: Network,
) => {
  const wallet = getWonderFiSigner(user, network);
  return wallet.sendTransaction(params);
};

export const signWonderFiMessage = async (user: User, message: string, network: Network) => {
  const wallet = getWonderFiSigner(user, network);
  return wallet.signMessage(ethers.utils.arrayify(message));
};

import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from './Button';
import { classNames } from 'app/utils';

type Props = {
  children: React.ReactNode;
  open: boolean;
  onClose?: () => void;
  title?: string;
  padding?: string;
};

const emptyFn = () => {
  return;
};

export default function BasicModal({ open, onClose, children, title, padding }: Props) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose ? onClose : emptyFn}
      >
        <div className={`min-h-screen px-4 text-center`}>
          <Dialog.Overlay className="fixed inset-0 bg-grey90 opacity-20" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                'max-w-md inline-block w-full my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl relative',
                padding || 'p-8 lg:p-12',
              )}
            >
              <div
                className={classNames(
                  title ? 'justify-between mb-6' : 'justify-end',
                  'flex items-center',
                )}
              >
                {Boolean(title) && (
                  <Dialog.Title as="div">
                    <h2>{title}</h2>
                  </Dialog.Title>
                )}
                {Boolean(onClose) && (
                  <Button className="btn-ghost round p-0" onClick={onClose}>
                    <img src={'/images/xIcon.svg'} alt="xIcon" className="h-5 w-5" />
                  </Button>
                )}
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

import {
  getAccountBalances,
  getAccounts,
  getNetworks,
  getTokens,
  getUserProfile,
  getUserSettings,
  getUserWallet,
  getUserWallets,
  login,
  resetUserWallet,
  getTokenMetadata,
  getCurrencies,
  getUserAccount,
} from '../utils/api';
import {
  AppState,
  Action,
  Account,
  Token,
  User,
  Wallet,
  Network,
  Dispatch,
} from '../constants/types';
import { isNativeToken } from '../utils/tokens';

export const loadUser = async (getData: (key: string) => Promise<any>, dispatch: Dispatch) => {
  const user = await getData('user');
  if (user) {
    dispatch({
      type: 'update',
      key: 'user',
      value: user,
    });
  } else {
    dispatch({
      type: 'update',
      key: 'loaded',
      value: true,
    });
  }
};

export const fetchNetworks = async ({ network }: { network?: string }, dispatch: Dispatch) => {
  let networks = [];
  try {
    networks = await getNetworks();
  } catch (err) {
    console.error(err);
  }
  if (networks && networks.length) {
    dispatch({
      type: 'multiUpdate',
      state: {
        networks,
        network: network
          ? networks.find((n: Network) => n.id === parseInt(network, 10) || n.prefix === network) ||
            networks[0]
          : networks[0],
      },
    });
  }
};

export const fetchAllData = async (
  {
    user,
    wallet,
    walletIdOrAddress,
    network,
  }: {
    user?: User;
    wallet: Wallet;
    walletIdOrAddress: string;
    network: Network;
  },
  idVerificationEnabled: boolean,
  dispatch: Dispatch,
): Promise<void> => {
  const getAllTokens = async (data: Array<Account>) => {
    try {
      const tokens = await Promise.all(
        data
          ? data.map((account: Account) => {
              if (account.rank <= 1) {
                return getTokens(network, account.tags[0]);
              }
              return [];
            })
          : [],
      );
      return tokens;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  if (network) {
    let loggedUser;
    if (user) {
      await getUserWallet(user);
      loggedUser = await login(user);
    }
    const data = await Promise.all([
      user ? getUserWallets(user, network) : null,
      getAccounts(network),
      user ? getUserProfile(user, network) : null,
      user ? getUserSettings(user, network) : null,
    ]);
    const tokens: Token[] = await getAllTokens(data[1]);
    const allTokens: Token[] = tokens
      .flat()
      .sort((a: any, b: any) => b.volume_in_usd - a.volume_in_usd);
    const uniqueTokens = [...new Map(allTokens.map((item) => [item['ID'], item])).values()];
    const primaryAccounts = data[1] ? data[1].filter(({ rank }: Account) => rank <= 1) : [];

    const newState: AppState = {
      watchlist: data[0],
      accounts: data[1],
      primaryAccounts: primaryAccounts,
      profile: data[2],
      settings: data[3],
      allTokens: uniqueTokens,
      loaded: true,
      loggedUser,
      userAccount: idVerificationEnabled && user ? await getUserAccount(user, network) : null,
    };
    if (allTokens.length) {
      const eth: any = allTokens.find((t: any) => isNativeToken(t, network));
      if (eth && eth.price_in_usd) {
        newState.ethPrice = eth.price_in_usd;
      }
    }
    if (!wallet && data[0]?.length) {
      newState.wallet = data[0][0];
    }
    if (walletIdOrAddress) {
      const newWallet = data[0]?.find((w: Wallet) => w.id === walletIdOrAddress || w.address.toLocaleLowerCase() === walletIdOrAddress.toLocaleLowerCase());
      if (newWallet) {
        if (!wallet || (wallet.id !== walletIdOrAddress && wallet.address.toLocaleLowerCase() !== walletIdOrAddress.toLocaleLowerCase())) {
          newState.wallet = newWallet;
        }
      }
    }
    dispatch({
      type: 'multiUpdate',
      state: newState,
    });
  }
};

export const fetchWalletBalances = async (
  { user, wallet, network }: { user: User; wallet: Wallet; network: Network },
  dispatch: Dispatch,
): Promise<void> => {
  if (user && wallet && network) {
    getAccountBalances(user, network, wallet.id).then((res) => {
      dispatch({
        type: 'update',
        key: 'walletBalances',
        value: res.data,
      });
    });
  }
};

// eslint-disable-next-line
export const onNetworkChange = ({ network }: { network: Network }, dispatch: Dispatch) => {
  const newState: AppState = {
    allTokens: [],
    loaded: false,
  };
  dispatch({
    type: 'multiUpdate',
    state: newState,
  });
};

export const updateLocalStore = async (
  setData: (key: string, value: any) => Promise<void>,
  event: Action,
) => {
  if (event.type === 'logout') {
    await setData('user', '');
    await setData('network', '');
    await setData('wallets', '');
    resetUserWallet();
  } else {
    await setData(event.key, event.value);
  }
};

export const fetchTokenDetails = async (
  { network, symbol }: { network: Network; symbol: string },
  dispatch: Dispatch,
) => {
  try {
    const metadata = await getTokenMetadata(network, symbol);
    dispatch({
      type: 'update',
      key: 'metadata',
      value: metadata,
    });
  } catch (err) {
    console.error(err);
  }
};

export const fetchCurrencies = async (dispatch: Dispatch) => {
  const currencies = await getCurrencies();
  if (currencies && Object.keys(currencies).length) {
    dispatch({
      type: 'multiUpdate',
      state: {
        currencies,
        currency: currencies[0],
      },
    });
  }
};

import { AppState, Order, Quote, Token } from '../../../constants/types';
import useOrderDetails from './useOrderDetails';
import useTradeValidations from './useTradeValidations';
import useTradeBalances from './useTradeBalances';
import useTradeTokens from './useTradeTokens';
import useTradeInputs from './useTradeInputs';

type Props = {
  defaultToken0: string;
  defaultToken1: string;
  error: string;
  quoteError: string;
  quote: Quote;
  allowance: string;
  isWalletConnected: boolean;
  disableSwap: boolean;
  state: AppState;
  getAllowanceValue: (token: Token) => void;
  getQuoteValues: (order: Order, taker?: string) => void;
  onSubmit: (order: Order) => void;
  setError: (error: string) => void;
};

const useTradeForm = ({
  allowance,
  isWalletConnected,
  defaultToken0,
  defaultToken1,
  disableSwap,
  error,
  quote,
  quoteError,
  state,
  getQuoteValues,
  getAllowanceValue,
  onSubmit,
  setError,
}: Props) => {
  const {
    rate0,
    rate1,
    token0,
    token1,
    tokenOptions0,
    tokenOptions1,
    handleChangeToken0,
    handleChangeToken1,
    handleSwapTokenInput,
  } = useTradeTokens({ defaultToken0, defaultToken1, state });

  const { balance0, balance1, nativeBalance } = useTradeBalances({ token0, token1, state });

  const {
    amount0,
    amount1,
    input0,
    input1,
    handleChangeInput0,
    handleChangeInput1,
    handleMax,
    handleInputUnit,
    currentUnit,
  } = useTradeInputs({ token0, token1, rate0, rate1, balance0, quote, allowance, state });

  const { totalReceive, grossAmount, route, commissionFee, networkFee } = useOrderDetails({
    quote,
    allowance,
    order: { amount0, amount1, token0, token1 },
    state,
  });

  const { missingFields } = useTradeValidations({
    order: { amount0, amount1, token0, token1 },
    balance0,
    input0,
    input1,
    rate0,
    rate1,
    quoteError,
    nativeBalance,
    networkFee,
    isWalletConnected,
    state,
    getQuoteValues,
    getAllowanceValue,
    setValidation: setError,
  });

  const handleSubmit = () => {
    if (!error) {
      onSubmit({
        token0,
        token1,
        amount0,
        amount1,
      });
    }
  };

  const disableSubmit = disableSwap || Boolean(error) || missingFields;

  return {
    balance0,
    balance1,
    input0,
    input1,
    rate1,
    token0,
    token1,
    tokenOptions0,
    tokenOptions1,
    totalReceive,
    grossAmount,
    missingFields,
    route,
    commissionFee,
    networkFee,
    disableSubmit,
    handleSwapTokenInput,
    handleChangeInput0,
    handleChangeInput1,
    handleChangeToken0,
    handleChangeToken1,
    handleMax,
    handleSubmit,
    currentUnit,
    handleInputUnit,
  };
};

export default useTradeForm;

import { useMemo, useCallback, useState, useContext } from 'react';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { classNames } from '../../../app/utils';
import Link from 'next/link';
import WalletPicker from './WalletPicker';
import NetworkPicker from './NetworkPicker';
import CurrencySwitcher from './CurrencySwitcher';
import Button from '../Shared/Button';
import TradeContainer from '../Trade/TradeContainer';
import GasBanner from './Gas/GasBanner';
import config from '../../config';
import { SettingsLink, SettingsLinkMobile } from './SettingsLinks';
import RequireLogin from './RequireLogin';
import { store } from '../../context/Context';
import RequireLoginLink from './RequireLoginLink';

const Header = () => {
  const router = useRouter();
  const { state } = useContext(store);
  const { user } = state;
  const {
    asPath,
    query: { wallet, network },
  } = router;

  const [openTrade, setOpenTrade] = useState(false);

  const canVisitRoute = useCallback(
    (isReadOnly?: boolean) => {
      if (isReadOnly) return true;
      if (!user) return false;
      return true;
    },
    [user],
  );

  const navigation = useMemo(() => {
    if (!network) {
      return [];
    }
    const hideNFTRoute = config().disableNFTRoute;
    return [
      { name: 'Home', href: `/${wallet || 'explore'}/${network}/home`, canVisit: canVisitRoute() },
      { name: 'Fund', href: `/${wallet || 'explore'}/${network}/fund`, canVisit: canVisitRoute() },
      {
        name: 'Explore',
        href: `/${wallet || 'explore'}/${network}`,
        canVisit: canVisitRoute(true),
      },
      {
        name: 'NFT',
        href: `/${wallet || 'explore'}/${network}/nft`,
        isHidden: hideNFTRoute,
        canVisit: canVisitRoute(),
      },
      // {
      //   name: 'Reports',
      //   href: `/${wallet || 'explore'}/${network}/reports`,
      //   canVisit: canVisitRoute(),
      // },
    ].filter((item) => {
      if (!user) {
        return item?.name !== 'NFT';
      }
      return !item?.isHidden;
    });
  }, [wallet, network, user, canVisitRoute]);

  const settingsHref = useMemo(
    () => `/${state.wallet?.address || 'explore'}/${state.network.prefix}/settings`,
    [state.wallet, state.network],
  );

  const nestedSettingsRoutes = useMemo(
    () => [
      { name: 'Settings-Wallets', href: `/${wallet || 'explore'}/${network}/wallets` },
      { name: 'Settings-Security', href: `/${wallet || 'explore'}/${network}/security` },
      { name: 'Settings-Referral', href: `/${wallet || 'explore'}/${network}/referral` },
    ],
    [wallet, network],
  );

  const sortedDescNavigation = useMemo(() => {
    return [
      ...navigation,
      ...nestedSettingsRoutes,
      { name: 'Settings-General', href: settingsHref },
    ].sort((a, b) => b.href.localeCompare(a.href));
  }, [navigation, settingsHref, nestedSettingsRoutes]);

  const isCurrent = useCallback(
    (item) => {
      const { href } = item;
      if (href === asPath) {
        return true;
      }
      const exact = navigation.find((n) => n.href === asPath);
      if (exact) {
        return false;
      }

      //Find longest matching substring
      const match = sortedDescNavigation.find((n) => asPath.indexOf(n.href) !== -1);
      return match && href === match.href;
    },
    [asPath, navigation, sortedDescNavigation],
  );

  const handleCloseTrade = async (transactionSent?: boolean) => {
    setOpenTrade(false);
    if (transactionSent) {
      router.push(`/${wallet}/${network}/reports`);
    }
  };

  return (
    <>
      <Disclosure as="nav" className="bg-white shadow-light">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 xl:px-0 xl:py-1">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <Link href="/">
                      <a>
                        <img
                          className="block"
                          src="/images/wonderfi-icon-highres.png"
                          alt="WonderFi"
                          style={{ height: '2em' }}
                        />
                      </a>
                    </Link>
                  </div>
                  <div className="hidden xl:-my-px xl:ml-6 xl:flex xl:space-x-8 items-center">
                    {navigation.map((item) => (
                      <RequireLoginLink key={item.name} href={item.href} canVisit={item.canVisit}>
                        <a
                          className={classNames(
                            isCurrent(item)
                              ? 'text-primary'
                              : 'text-blue10 hover:text-primaryPressed no-animation',
                            'inline-flex items-center px-1 pt-1 border-b-2 btn',
                          )}
                          aria-current={isCurrent(item) ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      </RequireLoginLink>
                    ))}
                    {Boolean(navigation?.length) && (
                      <>
                        <RequireLogin includeLoginPopup={false}>
                          <Button
                            variant="link"
                            className="px-1 pt-1"
                            onClick={() => setOpenTrade(true)}
                          >
                            <div className="text-transparent bg-clip-text bg-gradient-to-r from-buttonGradient1 via-buttonGradient2 to-buttonGradient3 text-center">
                              Trade
                            </div>
                          </Button>
                        </RequireLogin>
                        <TradeContainer open={openTrade} onClose={handleCloseTrade} />
                      </>
                    )}
                  </div>
                </div>
                <div className="hidden xl:ml-6 xl:flex xl:items-center">
                  <CurrencySwitcher />
                  <NetworkPicker />
                  <WalletPicker />
                  <SettingsLink href={settingsHref} />
                </div>
                <div className="-mr-2 flex items-center xl:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-blue10 hover:text-primaryPressed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="xl:hidden absolute right-0 w-1/2 bg-white shadow-light">
              {({ close }) => (
                <>
                  <div className="p-[18px] space-y-[18px]">
                    <CurrencySwitcher onPress={close} />
                    <NetworkPicker onPress={close} />
                    <WalletPicker onPress={close} />
                  </div>
                  <div className="pl-6 mb-5">
                    {Boolean(navigation?.length) && (
                      <RequireLogin includeLoginPopup={false}>
                        <Button variant="link" className="p-0" onClick={() => setOpenTrade(true)}>
                          <div className="text-transparent bg-clip-text bg-gradient-to-r from-buttonGradient1 via-buttonGradient2 to-buttonGradient3 text-center">
                            Trade
                          </div>
                        </Button>
                      </RequireLogin>
                    )}
                    {navigation.map((item) => (
                      <div key={item.name} onClick={() => item.canVisit && close()}>
                        <RequireLoginLink href={item.href} canVisit={item.canVisit}>
                          <a
                            className={classNames(
                              isCurrent(item)
                                ? 'text-primary'
                                : 'text-blue10 hover:text-primaryPressed no-animation',
                              'block p-0 btn flex items-center justify-start',
                            )}
                            aria-current={isCurrent(item) ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        </RequireLoginLink>
                      </div>
                    ))}
                    <TradeContainer open={openTrade} onClose={handleCloseTrade} />
                    <SettingsLinkMobile href={settingsHref} />
                  </div>
                  <div className="basis-1/2 px-4 xl:px-0">
                    <GasBanner />
                  </div>
                </>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default Header;

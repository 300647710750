import { useContext } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { ChevronRightIcon, AdjustmentsIcon } from '@heroicons/react/solid';
import { store } from '../../../context/Context';
import BasicModal from '../../Shared/BasicModal';
import { Wallet } from '../../../constants/types';
import ConnectedWallet from '../../Wallets/ConnectedWallet';

type Props = {
  open?: boolean;
  onClose?: () => void;
  redirectToNFTs?: boolean;
};

const WalletPickerModal = ({ open, onClose, redirectToNFTs }: Props) => {
  const router = useRouter();
  const {
    state: { watchlist, wallet, network },
  } = useContext(store);

  const onWalletPress = (item: Wallet) => {
    onClose();
    if (redirectToNFTs) {
      router.push(`/${item.address}/${network.prefix}/nft`);
    } else {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          wallet: item.address,
        },
      });
    }
  };

  return (
    <BasicModal open={open} onClose={onClose}>
      <div className="h-4" />
      {watchlist?.map((item) => (
        <button
          key={item.address}
          className="w-full h-full normal-case no-animation text-left py-3 border-b border-grey70 hover:opacity-75"
          onClick={() => onWalletPress(item)}
        >
          <div className="flex items-center justify-between w-full">
            <ConnectedWallet wallet={item} />
            <ChevronRightIcon className="w-5 h-5 mx-2 text-grey40" aria-hidden="true" />
          </div>
        </button>
      ))}
      <Link href={`/${wallet?.address || 'explore'}/${network.prefix}/wallets`}>
        <a className="w-full h-full normal-case no-animation text-left py-3 flex items-center hover:opacity-75">
          <AdjustmentsIcon className="w-8 h-8 text-primary" aria-hidden="true" />
          <p className="ml-3 body2 text-blue10 truncate">Manage wallets</p>
        </a>
      </Link>
    </BasicModal>
  );
};

export default WalletPickerModal;

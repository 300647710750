import { Action, AppState, TokenState } from '../constants/types';

export const initialState: AppState = {
  loaded: false,
  user: null,
  wallet: null,
  watchlist: null,
  allTokens: null,
  primaryAccounts: null,
  accounts: null,
  ethPrice: null,
  profile: null,
  settings: null,
  walletBalances: null,
  networks: null,
  network: null,
  currencies: null,
  currency: null,
  allFeatureFlags: {
    mobileEnableTrading: false,
    mobileEnableCurrencySwapForm: false,
  },
};

export const initialTokenState: TokenState = {
  metadata: null,
  token: null,
};

export const reducer = (state: any, action: Action) => {
  if (action.type === 'logout') {
    return {
      ...initialState,
      loaded: true,
    };
  }

  switch (action.type) {
    case 'multiUpdate':
      return {
        ...state,
        ...action.state,
      };
    case 'update':
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      throw new Error();
  }
};

import Script from 'next/script';
import config from '../../config';
import { NextSeo } from 'next-seo';

const defaults = {
  desription: `Access the world's most technologically advanced financial markets with WonderFi's Decentralized Finance platform.`,
  image:
    'https://assets.website-files.com/60fa96c0712cff5a698551b5/611bad7a01914f4936affe28_logo.svg',
};
export type MetaProps = {
  title: string;
  description?: string;
  image?: string;
};

const Meta = ({ title, description = defaults.desription, image = defaults.image }: MetaProps) => {
  return (
    <>
      <NextSeo
        title={`${title} | WonderFi`}
        description={description}
        openGraph={{
          title: title,
          description: description,
          images: [
            {
              url: image,
            },
          ],
          site_name: 'WonderFi',
        }}
        twitter={{
          handle: '@WonderFi',
          site: '@WonderFi',
          cardType: 'summary_large_image',
        }}
      />
      {/* <!--
        Impact Universal Tracking Tag required to be installed on the page.
        Removal or modification of this code will disrupt marketing activities. This code is property of Impact, please do not remove or modify without first contacting Impact Technical Services.
        --> */}
      {config().trackingEnabled && (
        <Script
          id="impact-universal-tracking-tag"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};
            f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);
            })('https://utt.impactcdn.com/A3249837-4e23-41e6-8a41-a8f1d47d38381.js','script','ire',document,window); 
            `,
          }}
        />
      )}
      {config().trackingEnabled && (
        <Script
          id="facebook-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1088732325199947');
          `,
          }}
        />
      )}

      {config().trackingEnabled && (
        <>
          <Script
            id="io-cvs"
            strategy="afterInteractive"
            src="//intof.io/itag"
            dangerouslySetInnerHTML={{
              __html: `
            var ioCvInit = ioCVsInit('1811-0');
            `,
            }}
          />
          <noscript>
            <img
              height="1"
              width="1"
              alt=""
              src="//intof.io/noscript.php?goal=1811-0&event=conversion"
            />
          </noscript>
        </>
      )}
    </>
  );
};

export default Meta;

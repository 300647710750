import { createTradeEnableTransaction, handleTradeError } from 'app/common/Trade';
import { AppState, Order, Transaction, Wallet } from '../../../constants/types';
import useApprovalTrx from './useApprovalTrx';

type Props = {
  state: AppState;
  trackEvent: (key: string, user: any, _attributes: any) => void;
  onTransactionSent?: () => void;
  order?: Order;
  setOpenExternal?: (open: boolean) => void;
  sendTransaction: (wallet: Wallet, params: Transaction) => Promise<any>;
};

const useTradeApproval = ({
  order,
  onTransactionSent,
  setOpenExternal,
  state,
  trackEvent,
  sendTransaction,
}: Props) => {
  const { user, wallet, network } = state;

  const {
    allowanceValue,
    checkingAllowance,
    enablingToken,
    enableTrx,
    approvalError,
    openAllowDialog,
    getAllowanceValue,
    setApprovalError,
    setEnableTrx,
    setOpenAllowDialog,
    setEnablingToken,
  } = useApprovalTrx({
    token: order?.token0,
    onTransactionSent,
    setOpenExternal,
    state,
    trackEvent,
  });

  const createEnableTransaction = async () => {
    setOpenAllowDialog(false);
    setEnablingToken(true);
    const { trx, sendTrxError } = await createTradeEnableTransaction(
      order,
      wallet,
      user,
      network,
      sendTransaction,
    );

    if (sendTrxError) {
      setEnablingToken(false);
      setApprovalError(
        handleTradeError(
          sendTrxError,
          `We were unable to change the allowance of your ${order?.token0?.symbol || 'token'}`,
        ),
      );
      return;
    }

    if (trx && trx.hash) setEnableTrx(trx);
    else setEnableTrx({ hash: trx });
  };

  return {
    allowanceValue,
    checkingAllowance,
    enablingToken,
    enableTrx,
    approvalError,
    openAllowDialog,
    getAllowanceValue,
    setOpenAllowDialog,
    createEnableTransaction,
  };
};

export default useTradeApproval;

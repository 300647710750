export const BALANCE_THRESHOLD_USD = 0.01; // 1 cent

export const validationErrors = {
  VIEW_ONLY_WALLET: 'This is viewed only wallet',
  ENTER_VALID_AMOUNT: 'Please enter a valid amount',
  INSUFFICIENT_BALANCE: 'Insufficient balance',
  INSUFFICIENT_BALANCE_TO_COVER_FEE: 'Insufficient funds to cover fee',
  NO_CONVERSION_RATE: 'No conversion rate for selected tokens',
  RECIEVE_AMOUNT_SMALL: 'Receive amount is too small after commission',
  INVALID_WALLET_ADDRESS: 'Invalid wallet address',
  TRANSACTION_FAILED: 'Transaction has failed or been reverted',
  TRANSACTION_TRACK_FAILED: 'Transaction could not be tracked',
  APPROVAL_FAILED: 'Approval has failed or been reverted',
  NETWORK_CONNECTION_FAILED: 'Error connecting with network',
  FAILED_CREATE_TRANSACTION: 'We were unable to create this transaction',
  WALLET_NOT_CONNECTED: 'Please connect your selected wallet',
  INSUFFICIENT_ASSET_LIQUIDITY: 'One selected token has insufficient liquidity',
  FAILED_QUOTE: 'We were unable to get a quote',
  FAILED_ALLOWANCE: 'We were unable to check allowance',
  FAILED_GAS: 'We were unable to estimate gas',
  WALLET_NOT_SUPPORTED: 'This wallet is not supported',
};

export const transactionInfo = {
  exchangeRoute: {
    title: "What's exchange route?",
    content: 'WonderFi sources the best exchange rate using 0x’s smart router.',
  },
  networkFee: {
    title: "What's network fee?",
    content:
      'Blockchains require network fees to be submitted with every transaction to compensate the computers who are executing your transaction.',
  },
  commissionFee: {
    title: "What's WonderFi fee?",
    content:
      'WonderFi charges a flat 0.5% fee on all transactions where the network fee accounts for less than 25% of the transaction value.',
  },
  conversionRate: {
    title: 'Exchange rate',
    content:
      'This exchange rate is sourced from the Decentralized Exchange that will execute the swap',
  },
  grossAmount: {
    title: 'Gross purchase amount',
    content:
      'This line item shows the total amount of the token you will be purchasing, before accounting for fees and subsidies.',
  },
  subsidy: {
    title: 'WonderFi Rebate',
    content:
      'WonderFi will issue a rebate of up to $30 USD on all transactions where the network fee accounts for more than 25% of the transaction value.',
  },
};

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const PRIVACY_POLICY_URL = 'https://www.wonder.fi/privacy-policy';
export const APP_TERMS_URL = 'https://www.wonder.fi/app-terms-and-conditions';
export const SUPPORT_EMAIL = 'mailto:support@wonder.fi';

export const alertMessages = {
  pendingTrx: {
    action: 'View',
    message: () =>
      'You have an outstanding transaction. Please wait for the network to verify this transaction before submitting another.',
  },
  networkAlert: {
    action: 'Switch',
    message: (feature: string) =>
      `${feature || 'This feature'} is not supported in the current network.`,
  },
};

export const referralMessages = {
  GET_PROMOTION_ERROR: 'There was an error to get the promotions',
  CHECK_PROMOTION_ERROR: 'There was an error to check with the promotion',
  REFERRAL_NOT_VALID: 'The referral code is not valid, please try a different one',
  REFERRAL_SUCCESSFULLY_REDEEMED: 'The referral code was successfully redeemed',
  REFERRAL_CODE_ERROR: 'There was an error with the code you submitted',
  CAN_NOT_CLOSE_BANNER: `Can't close the promotion banner`,
  CLOSE_BANNER_ERROR: 'There was an error to close the promotion banner',
};

export const depositMessage = {
  UNSUPPORTED_TOKENS_WARNING:
    'Sending unsupported tokens or tokens from unsupported blockchains may result in permanent loss. The WonderFi app currently only supports the Ethereum and Polygon blockchains. Please refer to the list below to view all supported tokens.',
  PURCHASE_CRYPTO:
    'Because we support limited assets at the moment, you need to preselect purchasable tokens first.',
  UNDERSTAND_PERMANENT_LOSS:
    'I understand that transferring unsupported tokens may result in permanent loss.',
  CHECK_BOX_WARNING: 'Check the box below to continue.',
};

export const accessCode = 'DFXCRYPT021';

const _makeCopyRightText = () => {
  const currentYear = new Date().getFullYear();
  return `WonderFi Digital Inc. All Rights Reserved, ${currentYear}`;
};

export const wonderfiCopyRight = _makeCopyRightText();

export const ETHEREUM_NETWORK_ID = 1;

export const goBigDataInfo = {
  header: 'Go Big!',
  description:
    'Sun Machine&#39;s debut release, Go BIG! feat. Godzilla vs. Kong, is a casual action mobile game that builds on the hugely successful film Godzilla vs. Kong, the latest chapter of Legendary Entertainment&#39;s Monsterverse franchise.',
  links: { twitter: 'https://twitter.com/', facebook: 'https://facebook.com/' },
};

import { useEffect, useState } from 'react';
import { AppState, Token } from '../../../constants/types';
import { getMaxInput, getUpdatedAmount, inputUnits } from 'app/common/Trade';
import useOrderDetails from './useOrderDetails';

type Props = {
  token: Token;
  balance: string;
  trxParams: any;
  allowance: string;
  state: AppState;
};

const useWithdrawInputs = ({ token, balance, trxParams, allowance, state }: Props) => {
  const { network, currency } = state;

  const [amount, setAmount] = useState('0');
  const [input, setInput] = useState('');
  const [to, setTo] = useState('');
  const [isValidTo, setIsValidTo] = useState(false);
  const [currentUnit, setCurrentUnit] = useState(inputUnits.token.id);

  const { networkFee } = useOrderDetails({
    allowance,
    trxParams,
    amount,
    token,
    input,
    state,
    currentUnit,
  });

  // clear input on token change
  useEffect(() => {
    setInput('');
    setAmount('0');
  }, [token, currentUnit]);

  const handleChangeInput = (value: any) => {
    setInput(value);
    setAmount(getUpdatedAmount(value, token, currency, currentUnit));
  };

  const handleChangeTo = async ({ address, isValid }: { address: string; isValid: boolean }) => {
    setTo(address);
    setIsValidTo(isValid);
  };

  const handleMax = () => {
    handleChangeInput(getMaxInput(token, balance, networkFee, network, currency, currentUnit));
  };

  const handleInputUnit = (selectedUnit: string) => {
    setCurrentUnit(selectedUnit);
  };

  return {
    amount,
    input,
    to,
    isValidTo,
    handleChangeInput,
    handleChangeTo,
    handleMax,
    currentUnit,
    handleInputUnit,
  };
};

export default useWithdrawInputs;

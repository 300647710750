import { useState, useEffect } from 'react';
import { getTokenBalance } from 'app/utils';
import { AppState, Token } from '../../../constants/types';

type Props = {
  token: Token;
  state: AppState;
};

const useTokenBalance = ({ token, state }: Props) => {
  const { walletBalances } = state;

  const [balance, setBalance] = useState('0');

  useEffect(() => {
    walletBalances && token && setBalance(getTokenBalance(walletBalances, token));
  }, [walletBalances, token]);

  return balance;
};

export default useTokenBalance;

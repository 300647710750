import { Currency, Quote, Token } from '../../constants/types';
import { formatTokenRate, getExchangeConversionRate } from 'app/common/Trade';
import TransactionFeeInfo from './TransactionFeeInfo';
import { transactionInfo } from 'app/constants';
import { useMemo } from 'react';

type Props = {
  token0: Token;
  token1: Token;
  quote: Quote;
  flatRate: number;
  currency: Currency;
};

export default function ConversionRate({ token0, token1, quote, flatRate, currency }: Props) {
  const rate = useMemo(() => getExchangeConversionRate(quote, flatRate), [quote, flatRate]);

  if (!token0 || !token1 || !rate) {
    return null;
  }

  return (
    <div className="w-full bg-grey80 px-2.5 py-1.5 rounded-lg flex justify-center items-center mb-7">
      <span className="body2 text-orange80">{formatTokenRate(token0, token1, rate, currency)}</span>
      &nbsp;
      <span className="mt-2">
        <TransactionFeeInfo
          title={transactionInfo.conversionRate.title}
          content={transactionInfo.conversionRate.content}
        />
      </span>
    </div>
  );
}

import { useContext, useState } from 'react';
import {
  classNames,
  formatCryptoBN,
  formatMoneyBN,
  getTokenBalance,
  isZeroBN,
} from '../../../app/utils';
import { matchTokenSearch, validateTokenInput } from '../../../app/utils/tokens';
import { Token } from '../../constants/types';
import { store } from '../../context/Context';
import Button from '../Shared/Button';
import DropdownModal from '../Shared/Input/DropdownModal';
import SearchBar from '../Shared/Input/SearchBar';
import TokenLogoImage from '../Shared/TokenLogoImage';
import { formatSwapInput, inputUnits } from 'app/common/Trade';

type Props = {
  amount?: any;
  onChangeAmount?: any;
  token: any;
  onChangeToken: any;
  tokenOptions: any[];
  showMax?: boolean;
  handleMax?: any;
  disabledInput?: boolean;
  balance?: string;
  hasDisabledTokens?: boolean;
  unit?: string;
  showConversion?: boolean;
  hideAmount?: boolean;
};

const TokenInput = ({
  amount,
  onChangeAmount,
  token,
  tokenOptions,
  onChangeToken,
  showMax,
  handleMax,
  disabledInput,
  balance,
  hasDisabledTokens,
  unit,
  showConversion,
  hideAmount,
}: Props) => {
  const {
    state: { currency, walletBalances },
  } = useContext(store);
  const [openTokenOptions, setOpenTokenOptions] = useState(false);
  const [inputSearch, setInputSearch] = useState('');

  const handleChangeInput = (value: string) => {
    const validatedInput = validateTokenInput(value, token);
    if (validatedInput !== null) {
      onChangeAmount(validatedInput);
    }
  };

  const handleChangeToken = (t: Token) => {
    setOpenTokenOptions(false);
    setInputSearch('');
    onChangeToken(t);
  };

  const balanceToUse = balance || walletBalances ? walletBalances[token.address]?.balance : '0';

  return (
    <div>
      <DropdownModal
        open={openTokenOptions}
        setOpen={setOpenTokenOptions}
        renderValue={() => (
          <div className="flex items-center">
            <TokenLogoImage token={token} size={6} />
            <div className="ml-2 space-y-1 text-left">
              <div className="flex bodySmall2 font-bold text-blue10">{token?.symbol}</div>
              <div className="text-grey20 bodySmall2 flex flex-col md:flex-row">
                <span className="mb-0.5 md:mb-0">Available Balance:&nbsp;</span>
                <span>
                  {token.price_in_usd
                    ? `${formatCryptoBN(balanceToUse, token)} = ${formatMoneyBN(
                        balanceToUse,
                        token,
                        currency,
                        null,
                        true,
                      )}`
                    : formatCryptoBN(balanceToUse, token)}
                </span>
              </div>
            </div>
          </div>
        )}
        renderOptions={() => (
          <>
            <div className="my-6">
              <SearchBar
                value={inputSearch}
                onChangeText={setInputSearch}
                onPressClear={() => {
                  setInputSearch('');
                }}
                className="flex items-center relative"
              />
            </div>
            <div className="w-full text-left h-60 overflow-y-auto">
              {tokenOptions
                ?.filter((t) => matchTokenSearch(t, inputSearch))
                ?.map((t) => (
                  <button
                    key={t.address}
                    className="w-full flex items-center p-3 hover:bg-blue80 cursor-pointer rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={() => handleChangeToken(t)}
                    disabled={hasDisabledTokens && isZeroBN(getTokenBalance(walletBalances, t))}
                  >
                    <TokenLogoImage token={t} size={6} />
                    <div className="ml-2 text-left">
                      <div className="flex bodySmall2 font-bold text-blue10">{t?.name}</div>
                      <div className="text-grey20 bodySmall2 flex flex-col md:flex-row">
                        <span className="mb-0.5 md:mb-0">Available Balance:&nbsp;</span>
                        <span>{`${formatCryptoBN(getTokenBalance(walletBalances, t), t)}`}</span>
                      </div>
                    </div>
                  </button>
                ))}
            </div>
          </>
        )}
      />
      {!hideAmount && (
        <div className="w-full relative flex items-center overflow-hidden border-b border-t border-grey70 my-2">
          {unit === inputUnits.fiat.id && (
            <span className={classNames(!amount ? 'text-grey10' : 'text-blue10', 'subtitle2')}>
              {currency.unit}
            </span>
          )}
          <input
            type="text"
            placeholder="0.00"
            className={classNames(
              'input border-none subtitle2 text-blue10 w-full flex items-center px-0',
              disabledInput ? 'opacity-50' : '',
            )}
            value={amount}
            onChange={(e) => handleChangeInput(e.target.value)}
            disabled={disabledInput}
            lang="en"
            inputMode="decimal"
          />
          {showConversion && (
            <span className="text-grey20 body2 truncate w-full text-right ml-2">
              {formatSwapInput(amount, token, currency, unit)}
            </span>
          )}
          {showMax && (
            <Button className="btn-secondary ml-2" size="sm" onClick={handleMax}>
              MAX
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default TokenInput;

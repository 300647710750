import WalletConnectClient from '@walletconnect/client';
import QRCodeModal from '@walletconnect/qrcode-modal';

export default function WalletConnect() {
  const connector = new WalletConnectClient({
    bridge: 'https://bridge.walletconnect.org', // Required
    qrcodeModal: QRCodeModal,
  });

  connector.connect = function () {
    return new Promise((resolve, reject) => {
      const connector = WalletConnect();
      try {
        // Subscribe to connection events
        connector.on('connect', (error: any) => {
          if (error) {
            throw error;
          }

          resolve(connector.session);
        });

        if (!connector.connected) {
          connector.createSession();
        } else if (connector.accounts?.length) {
          resolve(connector.session);
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  return connector;
}

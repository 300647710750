import BasicModal from '../Shared/BasicModal';
import Button from '../Shared/Button';

type Props = {
  open: boolean;
  onAllow?: () => Promise<void>;
  onClose: () => void;
  tokenName: string;
};
export default function AllowDialog({ open, onAllow, onClose, tokenName }: Props) {
  return (
    <BasicModal open={open} onClose={onClose} title="Approve Spending">
      <div className="my-4">
        <p className="body1 text-grey10">
          Approve transacting {tokenName || ''} with WonderFi smart contract.
        </p>
      </div>
      <div className="mt-4">
        <Button full onClick={onAllow}>
          Approve
        </Button>
      </div>
    </BasicModal>
  );
}

type Props = {
  message: string;
};

const ValidationError = ({ message }: Props) => {
  if (!message) return null;

  return (
    <div className="bg-red80 flex items-center justify-center rounded-md p-1 mb-2">
      <span className="bodySmall2 text-error">{message}</span>
    </div>
  );
};

export default ValidationError;

import { useState, useEffect } from 'react';
import { getTokenBalance } from 'app/utils';
import { getNativeToken } from 'app/utils/tokens';
import { AppState } from '../../../constants/types';

type Props = {
  state: AppState;
};

const useNativeBalance = ({ state }: Props) => {
  const { allTokens, walletBalances, network } = state;

  const [nativeBalance, setNativeBalance] = useState('0');

  useEffect(() => {
    if (allTokens && walletBalances) {
      const eth = getNativeToken(allTokens, network);
      setNativeBalance(getTokenBalance(walletBalances, eth));
    }
  }, [allTokens, walletBalances, network]);

  return nativeBalance;
};

export default useNativeBalance;

import { ethers } from 'ethers';
import { validationErrors } from '../../constants';
import {
  Currency,
  Network,
  Order,
  Quote,
  Token,
  Transaction,
  User,
  Wallet,
} from '../../constants/types';
import {
  formatAmount,
  formatCrypto,
  formatCryptoBN,
  formatMoney,
  formatMoneyBN,
  getHexString,
  isValidAmount,
  isValidAmountBN,
  isZeroBN,
  parseAmount,
} from '../../utils';
import { getQuote, postPendingSwapTransaction } from '../../utils/api';
import {
  approveSpendTrx,
  estimateApproveGas,
  getAllowance,
  getProviderGasPrice,
} from '../../utils/eth';
import { isNativeToken } from '../../utils/tokens';
import { getWonderFiSigner } from '../../utils/torus';

export const formatTokenRate = (
  token0: { symbol: string; price_in_usd: number },
  token1: { symbol: string; price_in_usd: number },
  rate: number,
  currency: Currency,
) => {
  return `1 ${token1.symbol} = ${formatCrypto(rate, token0.symbol)} (= ${formatMoney(
    token1.price_in_usd,
    currency,
    null,
    true,
  )})`;
};

export const _getQuoteValues = async (network: Network, order: Order, taker: string) => {
  try {
    const quote = await getQuote(network, order.token1, order.token0, order.amount0, taker);
    return { quote };
  } catch (e: any) {
    return { quoteError: e?.message || validationErrors.FAILED_QUOTE };
  }
};

export const _getAllowanceValue = async (network: Network, token: Token, wallet: Wallet) => {
  try {
    const allowance = await getAllowance(wallet, token.address, network?.withdraw_address, network);

    return { allowance };
  } catch (e: any) {
    return { allowanceError: e, allowance: '0' };
  }
};

export const handleTradeError = (e: any, defaultMessage: string) => {
  let message;
  const errorMsg = e?.message || e?.msg;

  if (errorMsg?.toUpperCase && errorMsg.toUpperCase().includes('INSUFFICIENT FUNDS')) {
    message = validationErrors.INSUFFICIENT_BALANCE_TO_COVER_FEE;
  } else if (errorMsg?.toUpperCase && errorMsg.toUpperCase().includes('NETWORK_ERROR')) {
    message = validationErrors.NETWORK_CONNECTION_FAILED;
  } else if (errorMsg?.length < 100) {
    message = errorMsg;
  } else {
    message = defaultMessage;
  }

  console.error(e);
  return message;
};

export const sendEthTradeTransaction = async (
  quote: {
    gas: string;
    gasPrice: string;
    to: string;
    data: string;
    value: string;
  },
  wallet: Wallet,
  sendTransactionFn: (
    wallet: Wallet,
    transaction: Transaction,
  ) => Promise<ethers.providers.TransactionResponse>,
) => {
  try {
    const gas = ethers.BigNumber.from(quote.gas).mul(12).div(10); // Increase estimated gas by 20%

    const trx = await sendTransactionFn(wallet, {
      gasLimit: gas.toHexString(),
      // customizable by user during MetaMask confirmation.
      gasPrice: getHexString(quote.gasPrice),
      to: quote.to, // Required except during contract publications.
      from: wallet.address, // must match user's active address.
      data: quote.data,
      // Only required to send ether to the recipient from the initiating external account.
      value: getHexString(quote.value),
    });

    return { trx };
  } catch (e: any) {
    return {
      sendTrxError: e,
    };
  }
};

export const sendNonEthTradeTransaction = async (
  trx: {
    from: string;
    token0: Token;
    token1: Token;
    amount0: string;
    amount1: string;
    signedMessage: string;
  },
  quote: Quote,
  user: User,
  network: Network,
  wallet: Wallet,
) => {
  try {
    // Send transaction to the backend
    const res = await postPendingSwapTransaction(user, network, wallet.id, {
      ...trx,
      to: quote.to,
      data: quote.data,
      allowanceTarget: quote.allowanceTarget,
    });

    if (res?.msg) {
      throw new Error(res.msg);
    }

    return { response: res };
  } catch (e: any) {
    return { sendTrxError: e };
  }
};

export const createTradeEnableTransaction = async (
  order: Order,
  wallet: Wallet,
  user: User,
  network: Network,
  sendTransactionFn: (
    wallet: Wallet,
    transaction: Transaction,
  ) => Promise<ethers.providers.TransactionResponse>,
) => {
  try {
    const amount = ethers.constants.MaxUint256;

    let signer = undefined;
    if (wallet.source === 'torus') {
      signer = getWonderFiSigner(user, network);
    }
    const unsignedTrx = await approveSpendTrx(
      order.token0.address,
      network?.withdraw_address,
      amount,
      network,
      signer,
    );

    // get gas price
    const gasPrice = await getProviderGasPrice(network);

    const trx = await sendTransactionFn(wallet, {
      from: wallet.address,
      to: unsignedTrx.to,
      data: unsignedTrx.data,
      gasPrice: gasPrice.toHexString(),
      value: getHexString('0'),
    });

    return { trx };
  } catch (e: any) {
    return { sendTrxError: e };
  }
};

export const getPreselectedTokens = (
  options: Token[],
  defaultToken0: string,
  defaultToken1: string,
) => {
  let preselected0, preselected1;
  const default0 = defaultToken0 && options.find((t) => t.ID === defaultToken0);
  const default1 = defaultToken1 && options.find((t) => t.ID === defaultToken1);

  if (default0) {
    preselected0 = default0;
    preselected1 = options.find((t) => t.ID !== defaultToken0);
  } else if (default1) {
    preselected0 = options.find((t) => t.ID !== defaultToken1);
    preselected1 = default1;
  } else {
    if (options.length > 0) preselected0 = options[0];
    if (options.length > 1) preselected1 = options[1];
  }

  return { preselected0, preselected1 };
};

export const getGrossAmount = (quote: Quote, token1: Token) => {
  if (quote?.buyAmount && isValidAmountBN(quote.buyAmount) && token1?.decimals) {
    return formatAmount(quote.buyAmount, token1.decimals);
  } else {
    return 0;
  }
};

export const getTotalReceive = (quote: Quote, token1: Token) => {
  if (quote?.wonderfi_fee && token1?.decimals && isValidAmountBN(quote.wonderfi_fee)) {
    return getGrossAmount(quote, token1) - formatAmount(quote?.wonderfi_fee, token1.decimals);
  } else {
    return getGrossAmount(quote, token1);
  }
};

export const getBestRoute = (quote: Quote) => {
  if (quote?.sources?.length) {
    const bestSource = quote.sources.reduce((previous, current) => {
      if (Number(current.proportion) > Number(previous.proportion)) return current;
      return previous;
    }, quote.sources[0]);

    return bestSource;
  } else {
    return null;
  }
};

export const getNetworkFee = (
  quote: Quote,
  allowanceFee: string,
  token0: Token,
  network: Network,
) => {
  if (isNativeToken(token0, network) && quote?.gas && quote?.gasPrice) {
    const limit = ethers.BigNumber.from(quote.gas);
    const price = ethers.BigNumber.from(quote.gasPrice);
    const gasFee = limit.mul(price);
    const increasedGasFee = gasFee.mul(12).div(10).toString(); // Increase estimated gas by 20%
    return increasedGasFee;
  } else if (allowanceFee) {
    return allowanceFee;
  } else {
    return '0';
  }
};

export const getCommissionFee = (quote: Quote, token0: Token, token1: Token, network: Network) => {
  if (!isNativeToken(token0, network) && token1?.decimals && quote?.wonderfi_fee) {
    return quote.wonderfi_fee;
  } else {
    return '0';
  }
};

export const canUpdateQuote = (token0: Token, token1: Token, amount0: string) => {
  return isValidAmountBN(amount0) && token0?.address && token1?.address;
};

export const getQuoteTaker = (
  token0: Token,
  wallet: Wallet,
  network: Network,
  insufficientFunds: boolean,
  isWalletConnected: boolean,
) => {
  return isNativeToken(token0, network) && !insufficientFunds && isWalletConnected
    ? wallet.address
    : null;
};

export const getUpdatedInput = (input: any, rate: number, token: Token, unit: string) => {
  if (isValidAmount(input)) {
    if (unit === inputUnits.fiat.id) {
      return input;
    }

    return (input * rate).toLocaleString('fullwide', {
      useGrouping: false,
      maximumFractionDigits: token?.decimals || 18,
    });
  } else {
    return '';
  }
};

export const getUpdatedAmount = (input: any, token: Token, currency: Currency, unit: string) => {
  let tokenInput = input;
  if (unit === inputUnits.fiat.id) {
    tokenInput = input / (token.price_in_usd * currency.rate);
  }

  return parseAmount(tokenInput.toString(), token?.decimals);
};

export const tradeValidations = {
  isViewedOnlyWallet: (wallet: Wallet) => wallet.source === 'watchlist',

  hasMissingFields: (input0: string, input1: string, token0: Token, token1: Token) =>
    !input0 || !input1 || !token0 || !token1,

  hasInvalidAmounts: (input0: string, input1: string, amount0: string) =>
    !isValidAmount(input0) || !isValidAmount(input1) || !isValidAmountBN(amount0),

  hasInsufficientBalance: (balance0: string, amount0: string) =>
    ethers.BigNumber.from(balance0).lt(ethers.BigNumber.from(amount0)),

  hasNoConversionRate: (rate0: number, rate1: number) => !rate0 || !rate1,
};

export const getExchangeConversionRate = (quote: Quote, flatRate: number) => {
  return quote?.guaranteedPrice ? 1 / quote.guaranteedPrice : flatRate;
};

/* Shared functions with withdraw START */

export const getMaxInput = (
  token: Token,
  balance: string,
  networkFee: string,
  network: Network,
  currency: Currency,
  unit: string,
) => {
  const max = getMaxAmount(token, balance, networkFee, network);
  let maxInput: number = formatAmount(max.toString(), token?.decimals); // token units

  if (unit === inputUnits.fiat.id) {
    maxInput = maxInput * (token.price_in_usd * currency.rate); // fiat units
  }
  return maxInput;
};

export const canUpdateAllowance = (token0: Token, network: Network) => {
  return token0?.symbol && !isNativeToken(token0, network);
};

export const isValidAllowance = (allowance: string, amount: string) => {
  return ethers.BigNumber.from(allowance).gte(ethers.BigNumber.from(amount));
};

export const tradeActions = {
  CHECKING_ALLOWANCE: 'Checking allowance...',
  GETTING_QUOTE: 'Getting quote...',
  SENDING_TRANSACTION: 'Sending transaction...',
  SIGNING_MESSAGE: 'Signing message...',
  APPROVING: 'Approving',
  CONFIRM_AND_TRADE: 'Confirm and swap',
  CONFIRM_WITHDRAW: 'Confirm and withdraw',
  CONFIRM_NFT_TRANSFER: 'Confirm and transfer',
  BROADCASTING_TRANSACTION: 'Broadcasting transaction',
  SWITCH_WALLET: 'Switch wallet',
  CONNECT_WALLET: 'Connect wallet',
};

export const waitEthTransaction = async (trx: Transaction) => {
  let error = '';
  let success = false;
  try {
    const receipt = await trx.wait();

    if (receipt.status === 0) {
      error = validationErrors.TRANSACTION_FAILED;
    } else if (receipt.status === 1) {
      success = true;
    }
  } catch (e) {
    error = validationErrors.TRANSACTION_TRACK_FAILED;
    success = false;
    console.error(e);
  }

  return { success, error };
};

export const waitEnableTransaction = async (trx: Transaction) => {
  let error = '';
  let success = false;
  try {
    const receipt = await trx.wait();

    if (receipt.status === 0) {
      error = validationErrors.APPROVAL_FAILED;
    } else if (receipt.status === 1) {
      success = true;
    }
  } catch (e) {
    error = validationErrors.TRANSACTION_TRACK_FAILED;
    success = false;
    console.error(e);
  }

  return { success, error };
};

export const formatBalance = (balance: string, token: Token, currency: Currency) => {
  return `${formatCryptoBN(balance, token)} = ${formatMoneyBN(
    balance,
    token,
    currency,
    null,
    true,
  )}`;
};

export const formatExchangeRoute = (route: { proportion: string; name: string }) => {
  return route ? `${route.name} (${Number(route.proportion) * 100}%)` : '-';
};

export const formatGrossAmount = (grossAmount: number, tokenUnits: Token) => {
  return formatCrypto(grossAmount, tokenUnits.symbol);
};

export const formatGrossAmountCurrency = (
  grossAmount: number,
  tokenUnits: Token,
  currency: Currency,
) => {
  return `(${formatMoney(grossAmount * tokenUnits.price_in_usd, currency)})`;
};

export const formatNetworkFee = (networkFee: string, tokenUnits: Token, nativeToken: Token) => {
  return !isZeroBN(networkFee) &&
    tokenUnits?.decimals &&
    tokenUnits.price_in_usd &&
    nativeToken?.decimals &&
    nativeToken.price_in_usd
    ? `-${formatCryptoBN(networkFee, nativeToken)}`
    : `0 ${nativeToken?.symbol}`;
};

export const formatNetworkFeeCurrency = (
  networkFee: string,
  tokenUnits: Token,
  nativeToken: Token,
  currency: Currency,
) => {
  return !isZeroBN(networkFee) &&
    tokenUnits?.decimals &&
    tokenUnits.price_in_usd &&
    nativeToken?.decimals &&
    nativeToken.price_in_usd
    ? `(${formatMoneyBN(networkFee, nativeToken, currency, null, true)})`
    : `(0 ${currency.symbol})`;
};

export const formatWonderFiFee = (commissionFee: string, tokenUnits: Token) => {
  return !isZeroBN(commissionFee) && tokenUnits?.decimals && tokenUnits.price_in_usd
    ? `-${formatCryptoBN(commissionFee, tokenUnits)}`
    : `0 ${tokenUnits.symbol}`;
};

export const formatWonderFiFeeCurrency = (
  commissionFee: string,
  tokenUnits: Token,
  currency: Currency,
) => {
  return !isZeroBN(commissionFee) && tokenUnits?.decimals && tokenUnits.price_in_usd
    ? `(${formatMoneyBN(commissionFee, tokenUnits, currency, null, true)})`
    : `(0 ${currency.symbol})`;
};

export const formatWonderFiSubsidy = () => {
  return '0';
};

export const formatWonderFiSubsidyCurrency = () => {
  return '0';
};

export const formatTotalReceive = (amount: number, token: Token) => {
  return amount ? formatCrypto(amount, token.symbol) : `0 ${token.symbol}`;
};

export const formatTotalReceiveCurrency = (amount: number, token: Token, currency: Currency) => {
  return amount
    ? `(${formatMoney(token.price_in_usd * amount, currency, null, true)})`
    : `(0 ${currency.symbol})`;
};

export const checkInsufficientFunds = (
  token0: Token,
  amount0: string,
  nativeBalance: string,
  networkFee: string,
  network: Network,
) => {
  if (isNativeToken(token0, network)) {
    const total = ethers.BigNumber.from(amount0).add(ethers.BigNumber.from(networkFee));
    return ethers.BigNumber.from(nativeBalance).lt(total);
  } else {
    return false;
  }
};

export const getAllowanceFee = async (user: User, network: Network, token0: Token) => {
  try {
    const signer = getWonderFiSigner(user, network);
    const limit = await estimateApproveGas(signer, token0.address, network?.withdraw_address);
    const price = await getProviderGasPrice(network);
    const gasFee = limit.mul(price).toString();
    return gasFee;
  } catch (e) {
    console.error(e);
    return '0';
  }
};

export const getMaxAmount = (
  token0: Token,
  balance0: string,
  networkFee: string,
  network: Network,
) => {
  let max;
  if (isNativeToken(token0, network)) {
    const gasFee = ethers.BigNumber.from(networkFee).mul(12).div(10); // Increase estimated gas by 20%
    max = ethers.BigNumber.from(balance0).sub(gasFee);
    if (max.lt(ethers.BigNumber.from(0))) max = balance0;
  } else {
    max = balance0;
  }
  return max;
};

export const hasAllowanceFee = (
  token0: Token,
  amount0: string,
  allowance: string,
  network: Network,
) => {
  return (
    !isNativeToken(token0, network) &&
    isValidAmountBN(amount0) &&
    !isValidAllowance(allowance, amount0)
  );
};

export const inputUnits = {
  token: {
    id: 'token',
    label: () => 'Enter token value',
  },
  fiat: {
    id: 'fiat',
    label: (symbol: string) => `Enter ${symbol} value`,
  },
};

export const formatSwapInput = (amount: number, token: Token, currency: Currency, unit: string) => {
  if (unit === inputUnits.fiat.id) {
    return formatCrypto(amount / (token.price_in_usd * currency.rate), token.symbol);
  }
  return formatMoney(amount * token.price_in_usd, currency, null, true);
};

/* Shared functions with withdraw END */

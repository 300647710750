import { useContext } from 'react';
import { store } from '../../context/Context';
import TokenInput from './TokenInput';
import TokenInputLabel from './TokenInputLabel';
import Button from '../Shared/Button';
import { Order, Quote, Token } from '../../constants/types';
import TransactionFees from './TransactionFees';
import TotalReceive from './TotalReceive';
import ValidationError from './ValidationError';
import Spinner from '../Shared/Spinner';
import VerticalSwap from '../../public/images/verticalSwap.svg';
import ConversionRate from './ConversionRate';
import useTradeForm from 'app/common/Trade/hooks/useTradeForm';
import SwapCurrencySwitcher from './SwapCurrencySwitcher';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { inputUnits } from 'app/common/Trade/';

type Props = {
  allowance: string;
  isWalletConnected: boolean;
  connectText: string;
  defaultToken0?: string;
  defaultToken1?: string;
  disableSwap: boolean;
  error?: string;
  loading: boolean;
  quote: Quote;
  quoteError: string;
  swapText: string;
  getAllowanceValue: (token: Token) => void;
  getQuoteValues: (order: Order, taker?: string) => void;
  handleConnectExternal: () => void;
  onSubmit: (order: Order) => Promise<void>;
  setError?: (error?: string) => void;
};

const TradeForm = ({
  allowance,
  isWalletConnected,
  connectText,
  defaultToken0,
  defaultToken1,
  disableSwap,
  error,
  loading,
  quote,
  quoteError,
  swapText,
  getAllowanceValue,
  getQuoteValues,
  handleConnectExternal,
  onSubmit,
  setError,
}: Props) => {
  const { state } = useContext(store);

  const { currency } = state;
  const { webEnableCurrencySwapForm } = useFlags();

  const {
    balance0,
    balance1,
    input0,
    input1,
    rate1,
    token0,
    token1,
    tokenOptions0,
    tokenOptions1,
    totalReceive,
    grossAmount,
    route,
    commissionFee,
    networkFee,
    disableSubmit,
    handleSwapTokenInput,
    handleChangeInput0,
    handleChangeInput1,
    handleChangeToken0,
    handleChangeToken1,
    handleMax,
    handleSubmit,
    handleInputUnit,
    currentUnit,
  } = useTradeForm({
    defaultToken0,
    defaultToken1,
    error,
    quoteError,
    quote,
    allowance,
    isWalletConnected,
    disableSwap,
    state,
    setError,
    getQuoteValues,
    getAllowanceValue,
    onSubmit,
  });

  return (
    <div className="form-control mt-1 mb-5 grid grid-cols-1 md:grid-cols-2 md:gap-16">
      {webEnableCurrencySwapForm && (
        <SwapCurrencySwitcher
          className="mb-4 sm:mb-0 -mt-0.5 sm:absolute -top-16 left-20"
          inputUnits={inputUnits}
          onChange={handleInputUnit}
          value={currentUnit}
        />
      )}
      <div className="flex flex-col justify-between">
        <ConversionRate
          token0={token0}
          token1={token1}
          quote={quote}
          flatRate={rate1}
          currency={currency}
        />

        <TokenInputLabel label="From:" />
        <TokenInput
          amount={input0}
          onChangeAmount={handleChangeInput0}
          token={token0}
          tokenOptions={tokenOptions0}
          onChangeToken={handleChangeToken0}
          balance={balance0}
          showMax
          handleMax={handleMax}
          hasDisabledTokens
          unit={currentUnit}
          showConversion
        />
        <div className="flex w-full justify-center my-4">
          <Button className="btn-circle" onClick={handleSwapTokenInput}>
            <VerticalSwap alt="vertical-swap" />
          </Button>
        </div>
        <TokenInputLabel label="To:" />
        <TokenInput
          amount={input1}
          onChangeAmount={handleChangeInput1}
          token={token1}
          tokenOptions={tokenOptions1}
          onChangeToken={handleChangeToken1}
          balance={balance1}
          unit={currentUnit}
          showConversion
        />
      </div>
      <div className="flex flex-col">
        <TransactionFees
          route={route}
          networkFee={networkFee}
          commissionFee={commissionFee}
          tokenUnits={token1}
          validation={error}
          grossAmount={grossAmount}
        />
        <TotalReceive amount={totalReceive} token={token1} />
        <div className="flex flex-col grow place-content-end mb-2">
          <ValidationError message={error} />
          {isWalletConnected ? (
            <Button full onClick={handleSubmit} disabled={disableSubmit}>
              {loading ? <Spinner /> : ''}
              &nbsp;
              {swapText}
            </Button>
          ) : (
            <Button full onClick={handleConnectExternal}>
              {connectText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TradeForm;

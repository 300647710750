import { useState } from 'react';

import CardDisclosure from '../Shared/CardDisclosure';
import WithdrawModal from './WithdrawModal';

const WithdrawContainer = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <CardDisclosure
        title="Withdraw"
        subTitle="To a wallet"
        iconSrc="/images/wallet-out.png"
        onChange={() => setOpen(true)}
      />
      <WithdrawModal open={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default WithdrawContainer;

import colors from 'app/constants/colors';
import React from 'react';

type Props = {
  label: string;
  style?: any;
};

const StatusChip = ({ label, style = {} }: Props) => {
  return (
    <div
      style={{
        backgroundColor: colors.blue80,
        borderRadius: 8.5,
        color: colors.grey20,
        letterSpacing: '0.53224px',
        paddingLeft: 4,
        paddingRight: 4,
        ...style,
      }}
    >
      <div className="smallUpper p-1 px-2">{label}</div>
    </div>
  );
};

export default StatusChip;

import { useContext } from 'react';
import {
  formatExchangeRoute,
  formatGrossAmount,
  formatGrossAmountCurrency,
  formatNetworkFee,
  formatNetworkFeeCurrency,
  formatWonderFiFee,
  formatWonderFiFeeCurrency,
} from '../../../app/common/Trade';
import { validationErrors, transactionInfo } from '../../../app/constants';
import { classNames } from '../../../app/utils';
import useNativeToken from '../../../app/common/Trade/hooks/useNativeToken';
import { store } from '../../context/Context';
import TransactionFeeInfo from './TransactionFeeInfo';

type Props = {
  route?: { name: string; proportion: string };
  networkFee?: string;
  commissionFee?: string;
  tokenUnits?: any;
  validation?: string;
  grossAmount?: number;
  title?: string;
};

const validationTextColor = (validation: string) =>
  validation === validationErrors.INSUFFICIENT_BALANCE_TO_COVER_FEE ? 'text-error' : 'text-grey40';

const TransactionFees = ({
  route,
  networkFee,
  commissionFee,
  tokenUnits,
  validation,
  grossAmount,
  title = 'Order Details',
}: Props) => {
  const {
    state: { allTokens, network, currency },
  } = useContext(store);

  const nativeToken = useNativeToken({ allTokens, network });

  return (
    <div className="space-y-2 text-grey40 bodySmall2">
      <p className="body2 text-blue10 font-bold mb-4">{title}</p>
      {Boolean(route) && (
        <div className="flex justify-between items-start">
          <div className="flex items-start">
            <span className="text-grey20">Exchange route</span>
            <TransactionFeeInfo
              title={transactionInfo.exchangeRoute.title}
              content={transactionInfo.exchangeRoute.content}
            />
          </div>
          <span className="text-grey20">{formatExchangeRoute(route)}</span>
        </div>
      )}
      {Boolean(grossAmount) && (
        <div className="flex justify-between items-start">
          <div className="flex items-start">
            <span className="">Gross purchase amount</span>
            <TransactionFeeInfo
              title={transactionInfo.grossAmount.title}
              content={transactionInfo.grossAmount.content}
            />
          </div>
          <span className="text-right">
            {formatGrossAmount(grossAmount, tokenUnits)}
            <br />
            {formatGrossAmountCurrency(grossAmount, tokenUnits, currency)}
          </span>
        </div>
      )}
      <div>
        <div className="flex items-start justify-between">
          <div className="flex items-start">
            <span className={classNames(`${validationTextColor(validation)}`)}>Network fees</span>
            <TransactionFeeInfo
              title={transactionInfo.networkFee.title}
              content={transactionInfo.networkFee.content}
            />
          </div>
          <span className={classNames('text-right', `${validationTextColor(validation)}`)}>
            {formatNetworkFee(networkFee, tokenUnits, nativeToken)}
            <br />
            {formatNetworkFeeCurrency(networkFee, tokenUnits, nativeToken, currency)}
          </span>
        </div>
        <div className="flex justify-between items-start">
          <div className="flex"></div>
          <span className="text-right">
            {formatWonderFiFee(commissionFee, tokenUnits)}
            <br />
            {formatWonderFiFeeCurrency(commissionFee, tokenUnits, currency)}
          </span>
        </div>
      </div>
      <div className="flex justify-between items-start">
        <div className="flex items-start">
          <span className="">WonderFi fees</span>
          <TransactionFeeInfo
            title={transactionInfo.commissionFee.title}
            content={transactionInfo.commissionFee.content}
          />
        </div>
        <span className="text-right">
          {formatWonderFiFee('0', tokenUnits)}
          <br />
          {formatWonderFiFeeCurrency('0', tokenUnits, currency)}
        </span>
      </div>
      {/* TODO Add WonderFi Rebate (subsidy) - use formatWonderFiSubsidy and transactionInfo.subsidy */}
    </div>
  );
};

export default TransactionFees;

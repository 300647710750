import CryptoJS from 'crypto-js';

// todo: move this to server
const key = '7ad38542-8902-4d64-82a8-61036dbc40d1';

export const encrypt = (json: any) => {
  return CryptoJS.AES.encrypt(JSON.stringify(json), key).toString();
};

export const decrypt = (text: string) => {
  const bytes = CryptoJS.AES.decrypt(text, key);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  try {
    return JSON.parse(originalText);
  } catch (err) {
    //eslit-disable-line
    return null;
  }
};

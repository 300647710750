import React, { useEffect, useState } from 'react';
import ReferralBanner from './ReferralBanner';
import SuccessAlert from '../../Shared/Alert/SuccessAlert';
import { referralMessages } from 'app/constants';

export default function Referral() {
  const [succeededMessage, setSucceededMessage] = useState(
    referralMessages.REFERRAL_SUCCESSFULLY_REDEEMED,
  );
  const [succeed, setSucceed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSucceed(false);
      setSucceededMessage('');
    }, 3000);
    return () => clearTimeout(timer);
  }, [succeededMessage]);

  return (
    <>
      {succeed && <SuccessAlert message={succeededMessage} />}
      <ReferralBanner setSucceed={setSucceed} />
    </>
  );
}

import { useEffect, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { trackEvent, keys } from '../../utils/tracking';
import { faqLinks } from 'app/constants/faq';

type Props = {
  region: string;
  country: string;
};

function Unavailable({ region, country }: Props) {
  const modalRef = useRef(null);

  useEffect(() => {
    trackEvent(keys.REGION_NOT_AVAILABLE, null, { region });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const keepModalVisible = () => {
      // Prevent changing display in browser
      if (modalRef && modalRef.current && modalRef.current.style.display) {
        window.location.reload();
      }
    };
    const interval = setInterval(keepModalVisible, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Transition appear show as={Fragment}>
      <Dialog
        ref={modalRef}
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => null}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-grey90 opacity-20" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl relative">
              <Dialog.Title as="h3" className="h2 mb-5">
                WonderFi is not yet available in your region
              </Dialog.Title>

              <p className="body1 text-grey20 mb-2">
                The WonderFi App is not available in {region ? region : 'your region'} at this time.
              </p>
              <p className="body1 text-grey20 mb-2">
                Keep an eye out for updates on our{' '}
                <a
                  className="underline font-bold focus:outline-none"
                  rel="noopener noreferrer"
                  href={faqLinks.restrictions}
                  target="_blank"
                >
                  FAQ
                </a>
                .
              </p>
              {country !== 'United States' && (
                <>
                  <p className="body1 text-grey20 mb-2">
                    In the meantime, get your crypto journey started with{' '}
                    <a
                      className="underline font-bold focus:outline-none"
                      rel="noopener noreferrer"
                      href="https://go.bitbuy.tech/aff_c?offer_id=12&aff_id=1397"
                      target="_blank"
                    >
                      <img
                        className="h-5 inline relative -top-0.5"
                        src="/images/bitbuy.png"
                        alt="bitbuy logo"
                      />
                    </a>
                    .
                  </p>
                  <p className="mt-3 bodySmall2 text-blue10">
                    <a
                      rel="noopener noreferrer"
                      href="https://go.bitbuy.tech/aff_c?offer_id=12&aff_id=1397"
                      className="btn w-full rounded-full btn-primary mt-4"
                      target="_blank"
                    >
                      Open BitBuy
                    </a>
                  </p>
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
export default Unavailable;

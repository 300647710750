export const GeneralIcon = (fill: any) => {
  return (
    <svg
      style={{ marginLeft: -2 }}
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="29" height="29" rx="2" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9231 3.21795C12.0166 2.75052 12.427 2.41406 12.9037 2.41406H16.0974C16.5741 2.41406 16.9845 2.75052 17.078 3.21795L17.4201 4.92826C19.0697 5.1877 20.5519 5.95127 21.704 7.05629L22.98 6.62464C23.4315 6.47189 23.9281 6.65909 24.1665 7.07191L25.7633 9.83776C26.0017 10.2506 25.9155 10.7742 25.5574 11.0889L24.1515 12.3245C24.1619 12.4919 24.1672 12.6607 24.1672 12.8307V16.1641C24.1672 16.3335 24.162 16.5017 24.1516 16.6686L25.5574 17.9041C25.9155 18.2188 26.0017 18.7424 25.7633 19.1552L24.1665 21.9211C23.9281 22.3339 23.4315 22.5211 22.98 22.3684L21.7054 21.9372C20.553 23.0429 19.0703 23.807 17.4201 24.0665L17.078 25.7768C16.9845 26.2443 16.5741 26.5807 16.0974 26.5807H12.9037C12.427 26.5807 12.0166 26.2443 11.9231 25.7768L11.581 24.0665C9.93076 23.807 8.44801 23.0429 7.29565 21.9371L6.02087 22.3684C5.56933 22.5211 5.07274 22.3339 4.8344 21.9211L3.23753 19.1552C2.99919 18.7424 3.08537 18.2188 3.44343 17.9041L4.84953 16.6684C4.83915 16.5016 4.83389 16.3334 4.83389 16.1641V12.8307C4.83389 12.6608 4.83919 12.492 4.84963 12.3247L3.44343 11.0889C3.08537 10.7742 2.99919 10.2506 3.23753 9.83776L4.8344 7.07191C5.07274 6.65909 5.56933 6.47189 6.02087 6.62464L7.29703 7.05635C8.4492 5.9513 9.93142 5.18771 11.581 4.92826L11.9231 3.21795ZM13.6673 9.66536C11.4582 9.66536 9.6673 11.4562 9.6673 13.6654V15.332C9.6673 17.5412 11.4582 19.332 13.6673 19.332H15.334C17.5431 19.332 19.334 17.5412 19.334 15.332V13.6654C19.334 11.4562 17.5431 9.66536 15.334 9.66536H13.6673Z"
        fill={fill.fill}
      />
    </svg>
  );
};

export const ManageWalletIcon = (fill: any) => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19141 6.18994H20.3398C20.2482 2.75736 17.1161 0 13.2656 0C9.4152 0 6.28305 2.75736 6.19141 6.18994Z"
        fill={fill.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7194 4.54698H20.8903C20.7005 3.87429 20.4209 3.23833 20.0652 2.65234H21.1799C22.4011 2.65234 23.4327 3.4516 23.764 4.5476C23.7492 4.54719 23.7343 4.54698 23.7194 4.54698ZM2.69562 2.65234H6.50141C6.14568 3.23833 5.86614 3.87429 5.67626 4.54698H4.65477C3.80406 4.54698 3.11442 5.22567 3.11442 6.06288C3.11442 6.90009 3.80406 7.57879 4.65478 7.57879H23.7194C23.7721 7.57879 23.8242 7.57618 23.8755 7.5711V18.5693C23.8755 20.0345 22.6686 21.2222 21.1799 21.2222H2.69562C1.20687 21.2222 0 20.0345 0 18.5693V5.30518C0 3.84006 1.20687 2.65234 2.69562 2.65234ZM20.0246 12.5057C19.1739 12.5057 18.4842 13.1844 18.4842 14.0216C18.4842 14.8588 19.1739 15.5375 20.0246 15.5375C20.8753 15.5375 21.565 14.8588 21.565 14.0216C21.565 13.1844 20.8753 12.5057 20.0246 12.5057Z"
        fill={fill.fill}
      />
    </svg>
  );
};

export const SecurityIcon = (fill: any) => {
  return (
    <svg
      style={{ marginLeft: 2 }}
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9394 6.40244V9.7561H6.06061V6.40244C6.06061 4.21351 7.82433 2.43902 10 2.43902C12.1757 2.43902 13.9394 4.21351 13.9394 6.40244ZM2.42424 25H10H17.5758C18.9146 25 20 23.908 20 22.561V12.1951C20 10.8481 18.9146 9.7561 17.5758 9.7561H16.3636V6.40244C16.3636 2.86647 13.5145 0 10 0C6.48546 0 3.63636 2.86647 3.63636 6.40244V9.7561H2.42424C1.08537 9.7561 0 10.8481 0 12.1951V22.561C0 23.908 1.08537 25 2.42424 25ZM8.43722 15.802C8.43722 14.8202 9.22826 14.0244 10.204 14.0244C11.1798 14.0244 11.9709 14.8202 11.9709 15.802C11.9709 16.3729 11.7034 16.8809 11.2876 17.2061L12.4698 19.7077C12.6611 20.1123 12.3678 20.5793 11.9225 20.5793H8.48559C8.04029 20.5793 7.74703 20.1123 7.93825 19.7077L9.12045 17.2061C8.7047 16.8809 8.43722 16.3729 8.43722 15.802Z"
        fill={fill.fill}
      />
    </svg>
  );
};

export const HelpIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0ZM11.7715 5.23828C10.0488 5.23828 8.60156 5.90332 7.42969 7.2334L8.7832 8.6748C9.67969 7.72559 10.6582 7.25098 11.7188 7.25098C12.3574 7.25098 12.8613 7.40918 13.2305 7.72559C13.5996 8.03613 13.7842 8.44922 13.7842 8.96484C13.7842 10.2422 12.6738 10.9248 10.4531 11.0127L10.3652 11.1094L10.7256 14.0186H12.1846L12.3604 12.5684C12.8818 12.4805 13.3535 12.3486 13.7754 12.1729C14.1973 11.9912 14.5752 11.7598 14.9092 11.4785C15.249 11.1914 15.5127 10.834 15.7002 10.4062C15.8877 9.97266 15.9814 9.48047 15.9814 8.92969C15.9814 7.79297 15.5977 6.89355 14.8301 6.23145C14.0625 5.56934 13.043 5.23828 11.7715 5.23828ZM10.207 15.5303V18H12.6152V15.5303H10.207Z"
        fill="#132950"
      />
    </svg>
  );
};

export const LogoutIcon = () => {
  return (
    <svg
      style={{ marginLeft: 2 }}
      width="22"
      height="30"
      viewBox="0 0 22 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.027 2.9703H2.97297L16.6486 7.42574V25.5446H19.027V2.9703ZM19.027 28.5149H16.6486V30L12.0901 28.5149H2.97297C1.33104 28.5149 0 27.185 0 25.5446V2.9703C0 1.32985 1.33105 0 2.97297 0H19.027C20.669 0 22 1.32985 22 2.9703V25.5446C22 27.185 20.669 28.5149 19.027 28.5149ZM11.4757 15.4455C10.7204 15.4455 10.1081 16.0573 10.1081 16.8119C10.1081 17.5665 10.7204 18.1782 11.4757 18.1782C12.231 18.1782 12.8432 17.5665 12.8432 16.8119C12.8432 16.0573 12.231 15.4455 11.4757 15.4455Z"
        fill="#132950"
      />
    </svg>
  );
};

export const ReferralIcon = (fill: any) => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0ZM13.4072 8.61418L11.9896 4.34787C11.8355 3.88404 11.1645 3.88404 11.0104 4.34787L9.5928 8.61418C9.52387 8.82161 9.3262 8.96205 9.10316 8.96205H4.51582C4.01709 8.96205 3.80973 9.58613 4.21321 9.8728L7.92444 12.5095C8.10489 12.6377 8.18039 12.865 8.11147 13.0724L6.6939 17.3387C6.53979 17.8025 7.08267 18.1882 7.48616 17.9016L11.1974 15.2648C11.3778 15.1366 11.6222 15.1366 11.8026 15.2648L15.5138 17.9016C15.9173 18.1882 16.4602 17.8025 16.3061 17.3387L14.8885 13.0724C14.8196 12.865 14.8951 12.6377 15.0756 12.5095L18.7868 9.8728C19.1903 9.58613 18.9829 8.96205 18.4842 8.96205H13.8968C13.6738 8.96205 13.4761 8.82161 13.4072 8.61418Z"
        fill={fill.fill}
      />
    </svg>
  );
};

type FAQ = {
  title: string;
  body: string;
};

//Maps account api_id to faq link
export const faqLinks: { [key: string]: string } = {
  savings: 'https://www.wonder.fi/faq#Earn-FAQ',
  indexes: 'https://www.wonder.fi/faq#Indexes-FAQ',
  faq: 'https://www.wonder.fi/faq',
  platform: 'https://www.wonder.fi/faq#Platform-FAQ',
  restrictions: 'https://www.wonder.fi/faq#Location-Restrictions',
  funding: 'https://www.wonder.fi/faq#Funding-FAQ',
};

export const exploreFaqs: { [key: string]: FAQ[] } = {
  savings: [
    {
      title: 'How can I earn interest?',
      body: 'You can earn interest by buying and holding tokens listed in the Earn section of the WonderFi app. Interest is earned in the underlying token (i.e. CUNI interest will be earned in UNI) and will automatically accrue and compound with every new block mined on the network, which happens every 15 seconds.',
    },
    {
      title: 'How are APYs Calculated?',
      body: 'The APY (Annual Percentage Yield) shown next to each interest-bearing token shows the current yield offered by the token based on supply and demand mechanics in that market. APYs are variable and may change during your holding period, meaning you may earn a higher or lower yield than the one you see when you purchase the token.',
    },
    {
      title: 'How is accumulated interest claimed?',
      body: 'Interest is automatically claimed whenever you sell your Earn tokens. We will also be adding a mechanism in the future to be able to claim additional rewards for the Earn tokens that offer it; for example all cTokens earn an additional rewards yield paid in COMP.',
    },
  ],
  indexes: [
    {
      title: 'What are Indexes?',
      body: 'Indexes are the DeFi equivalent of an ETF. These tokens allow holders to gain broad exposure to a basket of assets with a single token.',
    },
    {
      title: 'What do Indexes track?',
      body: 'Indexes track the value of the underlying tokens that make up the index, and are often designed to provide exposure to a broad theme or sector in the crypto investment universe. For example, the Metaverse Index is a market-capitalization weighted index that tracks the value of a group of major Metaverse tokens, such as Decentraland, Sandbox, Axie Infinity, and more.',
    },
    {
      title: 'Who manages the Indexes?',
      body: 'Indexes are managed by two parties : the decentralized issuance protocol, and the index manager. The issuance protocol is responsible for creating the index token and ensuring it is always fully collateralized. The index manager is responsible for creating and maintaining the index’s strategy by adjusting the weights of individual tokens within the index.',
    },
  ],
};

export const fundFaqs: FAQ[] = [
  {
    title: 'How do I transfer crypto to my WonderFi wallet?',
    //TODO: add link to supported assets page here when available
    body: 'Users can deposit supported crypto assets directly to their WonderFi wallet from an existing wallet or exchange. To deposit assets to WonderFi, select the withdraw function on your wallet or exchange of choice and input your WonderFi wallet address (accessible from the “Transfer” tab above) as the destination address. Please note that sending any non-supported assets may result in the permanent loss of your funds, view our supported asset list here.',
  },
  {
    title: 'How do I connect a wallet to WonderFi?',
    body: 'To connect an external wallet to the WonderFi app, click on the “Select Wallet Provider” button above and follow the WalletConnect instructions. Please note that you will only be able to connect one mobile wallet and one browser extension wallet at a time.',
  },
  {
    title: 'Can I fund my wallet with a credit card or from my bank?',
    body: 'WonderFi currently supports purchasing crypto directly from a credit card or bank account with our onramp partner, SendWyre. Please note that some banks and credit card providers do not accept cryptocurrency transactions. We are actively working on bringing more onramp solutions so that you can fund your wallet directly with fiat.',
  },
];

export const homeFaqs: FAQ[] = [
  {
    title: 'Is WonderFi non-custodial?',
    body: 'WonderFi is a non-custodial application, meaning that you have full ownership and control over all tokens held in your WonderFi account.',
  },
  {
    title: 'Do I have to pay fees?',
    body: 'Users will be required to pay network fees as demanded by the blockchain network being used. To estimate how much fees will cost you, view the Network Fee widget on the left hand side of the app prior to submitting a transaction.',
  },
  {
    title: 'Is there a minimum funding requirement?',
    body: 'There is no minimum funding requirement, however you should be aware of network fees when deciding on how much to fund your wallet. You can view network fees and expected swap / transfer fees in the Network Fee widget on the left hand side of the app.',
  },
];

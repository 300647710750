import { Disclosure } from '@headlessui/react';
import { CogIcon, UserCircleIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import { useState } from 'react';
import RequireLogin from './RequireLogin';

type Props = {
  href: string;
};
export const SettingsLink = ({ href }: Props) => {
  const [openLogin, setOpenLogin] = useState(false);
  return (
    <RequireLogin
      fallback={
        <button className="btn btn-circle btn-lg btn-ghost" onClick={() => setOpenLogin(true)}>
          <span className="sr-only">Log in</span>
          <UserCircleIcon className="h-8 w-8" aria-hidden="true" />
        </button>
      }
      open={openLogin}
      onClose={() => setOpenLogin(false)}
    >
      <Link href={href}>
        <a className="btn btn-circle btn-lg btn-ghost">
          <span className="sr-only">Settings</span>
          <CogIcon className="h-6 w-6" aria-hidden="true" />
        </a>
      </Link>
    </RequireLogin>
  );
};

export const SettingsLinkMobile = ({ href }: Props) => {
  const [openLogin, setOpenLogin] = useState(false);
  return (
    <RequireLogin
      fallback={
        <button
          onClick={() => setOpenLogin(true)}
          className="block btn btn-sm text-blue10 hover:text-primaryPressed flex items-center justify-start p-0 py-2"
        >
          Log in
        </button>
      }
      open={openLogin}
      onClose={() => setOpenLogin(false)}
    >
      <Link href={href}>
        <a>
          <Disclosure.Button
            as="div"
            className="block btn btn-sm text-blue10 hover:text-primaryPressed flex items-center justify-start p-0 py-2"
          >
            Settings
          </Disclosure.Button>
        </a>
      </Link>
    </RequireLogin>
  );
};

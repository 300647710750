import { Interval } from '../constants/types';
import moment from 'moment';

export const formatDate = (date: moment.MomentInput) => {
  return moment(date).format('MMM D, YYYY');
};

export const formatDateTime = (date: moment.MomentInput) => {
  return moment(date).format('MMM D, YYYY, hh:mm A');
};

export const unixTimestampToDate = (timestamp: number) => {
  return moment.unix(timestamp);
};

export const formatDateTick = (t: number, selectedInterval: Interval) => {
  const { days } = selectedInterval;
  const date = new Date(t);
  const month = date.toLocaleString('en-us', {
    month: 'short',
  });

  const day = date.toLocaleString('en-us', {
    day: 'numeric',
  });

  if (days === 1) {
    return `${formatTime(date)}`;
  }

  if (days === 365) {
    return `${month}`.toUpperCase();
  } else {
    return `${month} ${day}`.toUpperCase();
  }
};

export const formatTime = (date: moment.MomentInput) => {
  return moment(date).format('h:mm A');
};

export const formatDateSince = (date: moment.MomentInput) => {
  return moment(date).fromNow();
};

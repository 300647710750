import { useEffect, useState } from 'react';
import { tradeActions } from 'app/common/Trade';
import { isNativeToken } from 'app/utils/tokens';
import { keys } from '../../../constants/tracking';
import { AppState, Transaction, Transfer, Wallet } from '../../../constants/types';
import useWithdrawEth from './useWithdrawEth';
import useWithdrawNonEth from './useWithdrawNonEth';
import useTransferParams from './useTransferParams';

type Props = {
  state: AppState;
  onFinish: () => void;
  trackEvent: (key: string, user: any, _attributes: any) => void;
  signMessage: (wallet: Wallet, message: string) => Promise<string>;
  sendTransaction: (wallet: Wallet, params: Transaction) => Promise<any>;
  setOpenExternal: (value: boolean) => void;
};

const useWithdraw = ({
  onFinish,
  trackEvent,
  state,
  setOpenExternal,
  signMessage,
  sendTransaction,
}: Props) => {
  const { user, network } = state;

  const [hasPendingTrx, setHasPendingTrx] = useState(false);
  const [withdrawError, setWithdrawError] = useState('');
  const [transfer, setTransfer] = useState(null);

  const onTransactionSent = () => {
    // Track withdraw event
    trackEvent(keys.WITHDRAW_TOKEN, user, {
      token: transfer?.token?.symbol,
      destination: transfer?.to,
      amount: transfer?.amount,
    });

    onFinish();
  };

  const { loadingParams, trxParams, paramsError, getTrxParams } = useTransferParams({ state });

  const { createEthTransaction, sendingEthTrx, ethTrxError, ethTrx, handleCancelEthTrx } =
    useWithdrawEth({
      state,
      onTransactionSent,
      setOpenExternal,
      getTrxParams,
      sendTransaction,
    });

  const {
    allowanceValue,
    approvalError,
    checkingAllowance,
    enablingToken,
    enableTrx,
    nonEthTrxError,
    openAllowDialog,
    sendingNonEthTrx,
    signingMessage,
    createEnableTransaction,
    createNonEthTransaction,
    getAllowanceValue,
    setOpenAllowDialog,
    handleCancelNonEthTrx,
  } = useWithdrawNonEth({
    state,
    transfer,
    onTransactionSent,
    setOpenExternal,
    sendTransaction,
    signMessage,
    trackEvent,
  });

  useEffect(() => {
    if (ethTrxError || nonEthTrxError || approvalError || paramsError) {
      setWithdrawError(ethTrxError || nonEthTrxError || approvalError || paramsError);
    }
  }, [ethTrxError, nonEthTrxError, approvalError, paramsError]);

  const handleCancel = () => {
    handleCancelEthTrx();
    handleCancelNonEthTrx();
  };

  const submitTransfer = async (transfer: Transfer) => {
    setTransfer(transfer);

    if (isNativeToken(transfer.token, network)) {
      createEthTransaction(transfer);
    } else {
      createNonEthTransaction(transfer);
    }
  };

  const buttonText = () => {
    if (checkingAllowance) return tradeActions.CHECKING_ALLOWANCE;
    if (sendingEthTrx || sendingNonEthTrx) return tradeActions.SENDING_TRANSACTION;
    if (signingMessage) return tradeActions.SIGNING_MESSAGE;
    if (enablingToken) return `${tradeActions.APPROVING} ${transfer?.token?.symbol}...`;
    return tradeActions.CONFIRM_WITHDRAW;
  };

  const broadcastingText = () => {
    if (enablingToken) return `${tradeActions.APPROVING} ${transfer?.token?.symbol}...`;
    return tradeActions.BROADCASTING_TRANSACTION;
  };
  const loading =
    sendingEthTrx ||
    sendingNonEthTrx ||
    signingMessage ||
    loadingParams ||
    checkingAllowance ||
    enablingToken;

  return {
    allowanceValue,
    enableTrx,
    withdrawError,
    ethTrx,
    hasPendingTrx,
    loading,
    openAllowDialog,
    transfer,
    trxParams,
    createEnableTransaction,
    getAllowanceValue,
    getTrxParams,
    handleCancel,
    setWithdrawError,
    setHasPendingTrx,
    setOpenAllowDialog,
    submitTransfer,
    buttonText: buttonText(),
    broadcastingText: broadcastingText(),
  };
};

export default useWithdraw;

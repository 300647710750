import { classNames } from '../../../../app/utils';

type Props = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  value: string | JSX.Element;
  active: boolean;
  isSelected?: boolean;
  icon?: JSX.Element;
  showIcon?: boolean;
  buttonClassName?: string;
};

export default function DropdownItem({
  onClick,
  value,
  active,
  isSelected = false,
  showIcon = true,
  icon,
  buttonClassName = '',
}: Props) {
  return (
    <button
      disabled={isSelected}
      className={classNames(
        'focus:outline-none rounded-lg box-border h-[45px] w-full normal-case no-animation text-left py-2 px-2 hover:bg-blue40',
        active ? 'bg-blue40' : '',
        isSelected ? 'bg-blue80 cursor-not-allowed' : '',
        buttonClassName,
      )}
      onClick={onClick}
    >
      <div className="flex items-center row w-full">
        {showIcon && (
          <div className="mr-[10px]">
            {icon ? icon : <div className="bg-grey20 rounded-full h-6 w-6"></div>}
          </div>
        )}
        <span
          className={classNames(
            'text-blue10 truncate font-black bodySmall1 w-full',
            isSelected ? 'text-primary' : '',
            active ? 'text-black' : '',
          )}
        >
          {value}
        </span>
      </div>
    </button>
  );
}

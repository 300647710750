import { useState } from 'react';
import TextInput from './TextInput';
import Spinner from '../Spinner';
import { checkAddress } from 'app/common/ConnectWallet';

type Props = {
  onChange: ({ address, isValid }: { address: string; isValid: boolean }) => void;
};

export default function AddressInput({ onChange }: Props) {
  const [address, setAddress] = useState('');
  const [checkingAddress, setCheckingAddress] = useState(false);

  const handleChangeText = async (value: any) => {
    setAddress(value);
    setCheckingAddress(true);
    const { isValid } = await checkAddress(value);
    setCheckingAddress(false);
    onChange({ address: value, isValid });
  };

  return (
    <div className="relative w-full">
      <TextInput
        value={address}
        onChange={(e: any) => handleChangeText(e.target.value)}
        placeholder="ETH Address"
      />
      <div className="text-grey20 absolute inset-y-0 right-0 ml-5 mr-4 mt-3.5">
        {checkingAddress && <Spinner />}
      </div>
    </div>
  );
}

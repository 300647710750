import { faqLinks } from 'app/constants/faq';
import Button from '../../components/Shared/Button';
import Meta from './Meta';
import { classNames } from 'app/utils';

type Props = { className?: string };

const Error404 = ({ className }: Props) => {
  return (
    <div
      className={classNames(
        className ? className : 'mt-0 sm:mt-20',
        'flex flex-col text-center content-center p-10 sm:p-0',
      )}
    >
      <Meta title="Page Not Found" />
      <img
        className="block shrink-0 h-[359px] w-[494px] mx-auto"
        src="/images/Error404.svg"
        alt="Error404"
      />
      <p className="h2 mt-8 mb-3.5">Lost in DeFi ?</p>
      <p className="subtitle1">
        It looks like that page doesn’t exist — please check the URL and try again!
      </p>
      <div className="mt-8 mb-16">
        <a target="_blank" href={faqLinks.faq} rel="noopener noreferrer">
          <Button className="px-20">Go to FAQ</Button>
        </a>
      </div>
    </div>
  );
};
export default Error404;

import { useEffect } from 'react';
import '../styles/global.css';
import { getNetworks, getTokens } from '../utils/api';
import { StateProvider } from '../context/Context';
import { Auth0Provider } from '@auth0/auth0-react';
import { trackEvent, keys } from '../utils/tracking';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import { useRouter } from 'next/router';

// Custom css for carousel
import '../components/Shared/Carousel/Carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Provider as AlertProvider } from 'react-alert';
import { AlertTemplate, alertOptions } from '../hooks/useAlert';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import config from '../config';
import { setHost } from '../../app/utils/api';
import { MetaProps } from '../components/Shared/Meta';
import { AppContextType } from 'next/dist/shared/lib/utils';
import { Network, Token } from '../constants/types';

function MyApp({ Component, pageProps, backendURL, meta }: any) {
  setHost(() => backendURL);

  const router = useRouter();
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_REACT_APP_CONFIG == 'production') {
      TagManager.initialize({ gtmId: 'GTM-PSCCSL7' });
      ReactGA.initialize('UA-194179493-2');
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/') {
      trackEvent(keys.SCREEN_LOADED, null, { screen: router.pathname, path: router.asPath });
    }
  }, [router.asPath, router.pathname]);

  if (['login', 'redirect'].some((path) => router.asPath.indexOf(path) !== -1)) {
    return <Component {...pageProps} />;
  }

  return (
    <Auth0Provider
      useRefreshTokens
      domain="auth.wonder.fi"
      clientId="dRYnkcNge3vFPZW3o7LzGsFjqtRFBIZs"
    >
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <StateProvider meta={meta}>
          <Component {...pageProps} />
        </StateProvider>
      </AlertProvider>
    </Auth0Provider>
  );
}

MyApp.getInitialProps = async (context: AppContextType) => {
  const { query } = context.router;
  let meta: MetaProps = {
    title: 'WonderFi | The compliant crypto ecosystem for investors',
    description:
      'Access the world’s most technologically advanced financial markets with WonderFi’s Decentralized Finance platform.',
    image: '/images/wonderfi.png',
  };

  const { backendURL } = config();
  setHost(() => backendURL);
  let networks = [];
  try {
    networks = await getNetworks();
  } catch (err) {
    console.error(err);
  }
  const network: Network = networks.find(
    (n: Network) =>
      n?.prefix?.toLocaleLowerCase() ===
      (query?.network?.toString() || 'ethereum').toLocaleLowerCase(),
  );

  if (network) {
    meta = {
      title: `Explore ${network.name} at WonderFi`,
      description: `Access the world’s most technologically advanced financial markets with WonderFi’s Decentralized Finance platform.`,
      image: `/images/${network.prefix}.png`,
    };
    if (query?.token) {
      let tokens = [];
      try {
        tokens = await getTokens(network);
      } catch (err) {
        console.error(err);
      }
      const token: Token = tokens.find(
        (t: Token) => t?.symbol?.toLocaleLowerCase() === query.token.toString().toLocaleLowerCase(),
      );
      if (token) {
        meta = {
          title: `Buy ${token.name} (${token.symbol}) at WonderFi`,
          description: `WonderFi is the easiest and safest way to buy, sell, and hold ${token.name} (${token.symbol}). Buy & sell ${token.name} in a matter of minutes on your phone or computer. ${token.medium_description}`,
          image: `/images/token.png`,
        };
      }
    }
  }
  return {
    backendURL,
    meta,
  };
};

export default withLDProvider({
  clientSideID: config().darkLaunchClientId,
  user: {
    key: 'anonymous',
    anonymous: true,
  },
})(MyApp);

import { useState, useCallback } from 'react';
import { _getQuoteValues, canUpdateQuote } from 'app/common/Trade';
import { capitalize } from 'app/utils';
import { AppState, Order } from '../../../constants/types';

type Props = {
  state: AppState;
};

const useTradeQuote = ({ state }: Props) => {
  const { network } = state;

  const [loadingQuote, setLoadingQuote] = useState(false);
  const [quoteValues, setQuoteValues] = useState(null);
  const [quoteError, setQuoteError] = useState('');

  const getQuoteValues = useCallback(
    async (order: Order, taker?: string) => {
      if (!canUpdateQuote(order.token0, order.token1, order.amount0)) {
        setQuoteValues(null);
        return;
      }

      setLoadingQuote(true);
      setQuoteError('');
      const { quote, quoteError } = await _getQuoteValues(network, order, taker);

      if (quoteError) {
        setQuoteValues(null);
        setQuoteError(capitalize(quoteError));
        setLoadingQuote(false);
        return;
      }

      setLoadingQuote(false);
      setQuoteValues(quote);
      return quote;
    },
    [network],
  );

  return {
    loadingQuote,
    quoteValues,
    quoteError,
    getQuoteValues,
  };
};

export default useTradeQuote;

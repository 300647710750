module.exports = {
  appName: 'wonderfi',
  version: '1.0.0',
  environment: 'production',
  infuraProjectId: 'e3ad6bfbc1454e34927871eb4f0451da',
  webURL: 'https://app.wonder.fi',
  backendURL: 'https://api.wonder.fi',
  trackingEnabled: true,
  showSecurity: false,
  darkLaunchClientId: '61fd8c758684e416c6d1357d',
  idVerificationEnabled: true,
  disableNFTRoute: true,
};

import React from 'react';
import Card from '../../Shared/Card';
import GoBigDescription from './GoBigDescription';
import GoBigLogo from './GoBigLogo';

type Props = {
  className?: string;
};

export default function GoBigCard({ className }: Props) {
  return (
    <div className={`relative ${className}`}>
      <Card shadow>
        <div className="absolute lg:-top-8 -top-6 left-[5%]">
          <GoBigLogo />
        </div>
        <img
          className="rounded-t-xl w-full lg:max-h-[120px] max-h-[75px] object-cover"
          src="/images/gobig-banner.svg"
          alt="GoBig banner"
        ></img>
        <GoBigDescription isSidebar />
      </Card>
    </div>
  );
}

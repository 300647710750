import { wonderfiCopyRight } from 'app/constants';
import React from 'react';
import { faqLinks } from 'app/constants/faq';

type Props = {
  className?: string;
};

export default function Footer({ className = '' }: Props) {
  return (
    <footer
      className={`flex flex-col xl:flex-row items-center w-full max-w-7xl mx-auto border-t-2 border-grey70 px-8 py-8 ${className}`}
    >
      <div className="w-full max-w-[400px] xl:w-auto xl:basis-3/12 flex flex-row flex-row items-center justify-between order-1">
        <a
          className="body2 font-black text-grey20 text-center"
          target="_blank"
          href={faqLinks.faq}
          rel="noopener noreferrer"
        >
          FAQs
        </a>
        <a
          className="body2 font-black text-grey20 text-center"
          target="_blank"
          href="https://www.wonder.fi/resources"
          rel="noopener noreferrer"
        >
          Resources
        </a>
        <a
          className="body2 font-black text-grey20 text-center"
          target="_blank"
          href="mailto:support@wonder.fi"
          rel="noopener noreferrer"
        >
          Contact support
        </a>
      </div>
      <div className="xl:basis-6/12 text-center bodySmall1 order-3 xl:order-2 mt-7 xl:mt-0 flex content-center justify-around">
        {wonderfiCopyRight}
      </div>
      <div className="w-full max-w-[400px] flex xl:w-auto xl:basis-3/12 flex-row justify-between items-center order-2 xl:order-3 mt-6 xl:mt-0">
        <div className="underline bodySmall2">Mobile app coming soon</div>
        <a target="_blank" href="https://twitter.com/wonderfi" rel="noopener noreferrer">
          <img className="h-5" src="/icons/twitter.svg" alt="twitter logo"></img>
        </a>
        <a target="_blank" href="https://www.instagram.com/wndrfi/" rel="noopener noreferrer">
          <img
            className="h-6 fill-primary stroke-primary"
            src="/icons/instagram.svg"
            alt="instagram icon"
          ></img>
        </a>
        <a
          target="_blank"
          href="https://ca.linkedin.com/company/wonderfi"
          rel="noopener noreferrer"
        >
          <img className="h-5" src="/icons/linkedin.svg" alt="linkedin logo"></img>
        </a>
      </div>
      <div className="visible xl:hidden flex items-center order-4 mt-7 mb-0.5">
        <img
          className="block"
          src="/images/wonderfi-icon-highres.png"
          alt="WonderFi"
          style={{ height: '2em' }}
        />
      </div>
    </footer>
  );
}

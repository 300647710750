import React from 'react';
import Button from '../Button';
import { classNames } from 'app/utils';

type Props = {
  message: string;
  onClose?: () => any;
  className?: string;
};

export default function ErrorAlert({ message, onClose, className }: Props) {
  if (!message) return null;
  return (
    <div className="bg-white pointer-events-auto">
      <div
        className={classNames(
          className ? className : '',
          'p-3 bodySmall2 alert alert-error rounded-lg',
        )}
      >
        <div className="flex items-center">
          <div className="self-start ml-1 shrink-0">
            <img src="/icons/ErrorAlertIcon.svg" />
          </div>
          <label className="mx-3 text-ellipsis overflow-hidden">{message}</label>
        </div>
        {Boolean(onClose) && (
          <div className="flex-none justify-center">
            <Button className="btn-ghost mr-2 text-error" onClick={onClose} size="xs">
              Ok
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

module.exports = {
  appName: 'wonderfi',
  version: '1.0.0',
  environment: 'development',
  infuraProjectId: '7394ae12b2484a0e9ec5d9ba7d5a2c5d',
  webURL: 'https://app.wonder.fi',
  backendURL: 'https://api.wonder.fi',
  trackingEnabled: false,
  showSecurity: true,
  darkLaunchClientId: '61fd8c758684e416c6d1357c',
  idVerificationEnabled: true,
  disableNFTRoute: false,
};

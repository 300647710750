import { useCallback, useState } from 'react';
import { useAlert, positions, AlertTemplateProps } from 'react-alert';
import SuccessAlert from '../components/Shared/Alert/SuccessAlert';
import ErrorAlert from '../components/Shared/Alert/ErrorAlert';

export const alertOptions = {
  timeout: 5000,
  offset: '4px',
  position: positions.TOP_CENTER,
  containerStyle: {
    marginTop: 110,
  },
};

export const AlertTemplate = ({ style, options, message, close }: AlertTemplateProps) => {
  return (
    <div className="bg-white rounded-2xl" style={style}>
      {options.type === 'success' && (
        <SuccessAlert className="capitalize" message={message.toString()} />
      )}
      {options.type === 'error' && <ErrorAlert message={message.toString()} onClose={close} />}
    </div>
  );
};

export default function useAlerts() {
  const alert = useAlert();
  const [error, setError] = useState(null);

  const handleSuccess = useCallback(
    (successMessage: string) => {
      alert.success(successMessage);
    },
    [alert],
  );

  const handleError = useCallback(
    (e?: Error) => {
      setError(e);
      alert.error(e?.message || 'Something went wrong, please try again later');
    },
    [alert],
  );

  return { handleSuccess, error, handleError };
}

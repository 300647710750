export const keys = {
  SCREEN_LOADED: 'SCREEN_LOADED',
  CATEGORY_PRESSED: 'CATEGORY_PRESSED',
  DASHBOARD_TOKEN_PRESSED: 'DASHBOARD_TOKEN_PRESSED',
  EXPLORE_TOKEN_PRESSED: 'EXPLORE_TOKEN_PRESSED',
  EXPLORE_CATEGORY_PRESSED: 'EXPLORE_CATEGORY_PRESSED',
  GRAPH_INTERVAL_PRESSED: 'GRAPH_INTERVAL_PRESSED',
  GRAPH_SWIPE: 'GRAPH_SWIPE', // not available in web app
  SWAP_TOKENS: 'SWAP_TOKENS',
  WITHDRAW_TOKEN: 'WITHDRAW_TOKEN',
  APPROVE_TOKEN: 'APPROVE_TOKEN',
  TRANSFER_NFT: 'TRANSFER_NFT',
  PURCHASE_CRYPTO_PRESSED: 'PURCHASE_CRYPTO_PRESSED',
  DEPOSIT_CRYPTO_PRESSED: 'DEPOSIT_CRYPTO_PRESSED',
  LOGIN_PRESSED: 'LOGIN_PRESSED', // not available in web app
  SIGNUP_PRESSED: 'SIGNUP_PRESSED', // not available in web app
  GET_STARTED_SWIPE: 'GET_STARTED_SWIPE', // not available in web app
  PURCHASE_CRYPTO_SUCCESSFULLY: 'PURCHASE_CRYPTO_SUCCESSFULLY',
  WALLET_ADDED_SUCCESSFULLY: 'WALLET_ADDED_SUCCESSFULLY',
  WALLET_DELETED: 'WALLET_DELETED',
  SWAP_IN_PRESSED: 'SWAP_IN_PRESSED',
  SWAP_OUT_PRESSED: 'SWAP_OUT_PRESSED',
  PROFILE_SAVED: 'PROFILE_SAVED',
  PUSH_NOTIFICATIONS_DISABLED: 'PUSH_NOTIFICATIONS_DISABLED', // not available in web app
  PUSH_NOTIFICATIONS_ENABLED: 'PUSH_NOTIFICATIONS_ENABLED', // not available in web app
  CONNECT_BANK_ACCOUNT_PRESSED: 'CONNECT_BANK_ACCOUNT_PRESSED', // not available in web app
  BANK_ACCOUNT_CONNECTED: 'BANK_ACCOUNT_CONNECTED', // not available in web app
  POPULAR_TOKEN_PRESSED: 'POPULAR_TOKEN_PRESSED',
  EXPORT_REPORT_CLICKED: 'EXPORT_REPORT_CLICKED',
  REPORT_EXPORTED: 'REPORT_EXPORTED',
  USER_SIGNUP: 'USER_SIGNUP', // web app only for now
  EMAIL_VERIFIED: 'EMAIL_VERIFIED', // web app only for now
  CONNECT_WALLET_PRESSED: 'CONNECT_WALLET_PRESSED', // web app only for now
  REGION_NOT_AVAILABLE: 'REGION_NOT_AVAILABLE', // web app only for now
  NUMBER_OF_WALLETS: 'NUMBER_OF_WALLETS',
  NUMBER_OF_WALLETS_WITH_BALANCE: 'NUMBER_OF_WALLETS_WITH_BALANCE',
};

import React from 'react';
import Withdraw from '.';

import FormModal from '../Shared/FormModal';
type Props = {
  open: boolean;
  onClose: () => void;
};
export default function WithdrawModal({ open, onClose }: Props) {
  return (
    <FormModal open={open} onClose={onClose} title="Withdraw">
      <Withdraw />
    </FormModal>
  );
}

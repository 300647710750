import { useRouter } from 'next/router';
import { useCallback, useContext } from 'react';
import SideSettings from '../../Settings/SideSettings';
import Fund from '../../Fund';
import Referral from '../Welcome/Referral';
import Gas from '../Gas';
import Learn from '../../Learn';
import { classNames } from 'app/utils';
import GoBigCard from '../../NFT/GoBig/GoBigCard';
import NFTSidebar from '../../NFT/Sidebar/NFTSidebar';
import { store } from '../../../context/Context';
import RequireLogin from '../RequireLogin';

const isSettings = (asPath: string) =>
  ['setting', 'wallets', 'security', 'referral', 'verification'].some(
    (path) => asPath.indexOf(path) !== -1,
  );

//TODO update this conditional once individual NFT routes are setup
const isNFTDetailsRoute = (asPath: string) => {
  const regex = /nft(\/.{1,}){2}/; //matches nft/[contract]/[id]
  return regex.test(asPath);
};

const shouldHideSideBar = (asPath: string) =>
  asPath.indexOf('reports') !== -1 || asPath.indexOf('authorize') !== -1;

const shouldShowGoBig = (asPath: string) => asPath.indexOf('nft') !== -1;

const SidebarWrapper = ({ children, showOnMobile }: any) => {
  return (
    <div
      className={classNames(
        showOnMobile ? 'block mt-4 sm:mt-6 xl:mt-0 xl:block lg:mb-16' : 'hidden xl:block',
        'xl:flex-shrink-0 xl:w-72 pb-6 pr-0 sm:pr-6 lg:pb-0 mx-6 sm:mx-5 xl:mx-0',
      )}
    >
      <div className="space-y-6">{children}</div>
    </div>
  );
};

const Sidebar = () => {
  const router = useRouter();
  const {
    state: { user },
  } = useContext(store);

  const getSideBarComponent = useCallback(() => {
    if (shouldHideSideBar(router.asPath) && user) return null;

    if (isSettings(router.asPath) && user)
      return (
        <SidebarWrapper showOnMobile>
          <SideSettings />
        </SidebarWrapper>
      );

    if (isNFTDetailsRoute(router.asPath) && user)
      return (
        <SidebarWrapper>
          <NFTSidebar />
        </SidebarWrapper>
      );

    return (
      <SidebarWrapper>
        <div className="flex flex-col sm:flex-row xl:block">
          {user && shouldShowGoBig(router.asPath) && (
            <div className="px-4 xl:px-0 xl:pb-5">
              <GoBigCard />
            </div>
          )}
          <div className="basis-1/2 px-4 xl:px-0">
            <Gas />
          </div>
          <div className="mt-4 sm:mt-0 xl:mt-6 basis-1/2 px-4 xl:px-0">
            <Learn />
          </div>
        </div>
        <div className="px-4 xl:px-0">
          <Fund />
        </div>
        <RequireLogin includeLoginPopup={false}>
          <div className="px-4 xl:px-0">
            <Referral />
          </div>
        </RequireLogin>
      </SidebarWrapper>
    );
  }, [router.asPath, user]);

  return <>{getSideBarComponent()}</>;
};

export default Sidebar;

import { ethers } from 'ethers';
import {
  HistoricalTransaction,
  Movement,
  PendingSwapTransaction,
  PendingWithdrawTransaction,
  Token,
  MonthlyTransactions,
  Wallet,
  User,
  Network,
  TransactionGroups,
} from '../constants/types';
import { getPendingTransactions } from './api';
import { etherscanUrl } from './eth';

export const msInSecond = 1000;

export const TRANSACTION_TYPES = {
  in: 'Deposit',
  out: 'Withdraw',
  trade: 'Swap',
  token: 'Approve spending',
  gas: 'Gas',
  unknown: 'Transaction',
};

export const TRANSACTION_STATUS = {
  pending: 'pending',
  success: 'success',
};

export const getTrxStatus = (status: string) => {
  if (status === TRANSACTION_STATUS.success) {
    return 'Completed';
  } else if (status === TRANSACTION_STATUS.pending) {
    return 'Pending';
  }
  return 'Failed';
};

export const getOutputToken = (trx: HistoricalTransaction[]) => {
  return trx?.find((t) => t.movement.type === TRANSACTION_TYPES.in);
};

export const getInputToken = (trx: HistoricalTransaction[]) => {
  return trx?.find((t) => t.movement.type === TRANSACTION_TYPES.out);
};

export const addPendingSwapMovement = (transaction: PendingSwapTransaction, allTokens: Token[]) => {
  const movement: Movement = { type: TRANSACTION_TYPES.unknown, amount: '0', token: null };
  const token = allTokens.find((t) => t.address === transaction.source_token);

  movement.amount = transaction.source_amount.toString();
  movement.type = TRANSACTION_TYPES.trade;
  movement.token = token;

  return { ...transaction, movement };
};

export const addPendingWithdrawMovement = (
  transaction: PendingWithdrawTransaction,
  allTokens: Token[],
) => {
  const movement: Movement = { type: TRANSACTION_TYPES.unknown, amount: '0', token: null };
  const token = allTokens.find((t) => t.address === transaction.token_address);

  movement.amount = transaction.amount.toString();
  movement.type = TRANSACTION_TYPES.out;
  movement.token = token;

  return { ...transaction, movement };
};

export const addHistoryTransactionMovement = (
  transaction: HistoricalTransaction,
  allTokens: Token[],
  wallet: Wallet,
  numTransactions: number,
) => {
  const movement: Movement = { type: TRANSACTION_TYPES.unknown, amount: '0', token: null };
  const token = allTokens.find((t) => t.address === transaction.token_address);

  if (transaction.from_address === wallet.address) {
    movement.amount = transaction.total_value.toString();
    movement.type = TRANSACTION_TYPES.out;
  } else if (transaction.to_address === wallet.address) {
    movement.amount = transaction.txn_value.toString();
    movement.type = TRANSACTION_TYPES.in;
  }

  // Check token interactions
  const tokenContract = allTokens.find(
    (t) => t.address.toLowerCase() === transaction.to_address.toLowerCase(),
  );

  if (tokenContract && transaction.txn_value === '0') {
    movement.type = numTransactions === 1 ? TRANSACTION_TYPES.token : TRANSACTION_TYPES.gas;
    movement.tokenContract = tokenContract;
  }

  movement.token = token;

  return { ...transaction, movement };
};

export const addHistoryMultipleTransactionMovement = (
  transactions: HistoricalTransaction[],
  allTokens: Token[],
  wallet: Wallet,
) => {
  const movement: Movement = { type: TRANSACTION_TYPES.unknown, amount: '0', token: null };
  const transactionsWithMovement = transactions
    .map((t) => addHistoryTransactionMovement(t, allTokens, wallet, transactions.length))
    .filter((t) => !!t.movement?.token);

  const transactionOut = transactionsWithMovement.filter(
    (t) => t.movement.type === TRANSACTION_TYPES.out,
  );
  const transactionIn = transactionsWithMovement.filter(
    (t) => t.movement.type === TRANSACTION_TYPES.in,
  );
  const transactionGas = transactionsWithMovement.filter(
    (t) => t.movement.type === TRANSACTION_TYPES.gas,
  );

  if (transactionIn.length === 1 && transactionOut.length === 1) {
    // trade
    movement.amount = transactionIn[0].total_value.toString();
    movement.token = transactionIn[0].movement?.token;
    movement.type = TRANSACTION_TYPES.trade;
    movement.internalTransactions = transactionsWithMovement;

    return { ...transactionOut[0], movement };
  }
  if (transactionIn.length === 1) {
    // deposit
    movement.amount = ethers.BigNumber.from(transactionIn[0].total_value).toString();
    movement.token = transactionIn[0].movement?.token;
    movement.type = TRANSACTION_TYPES.in;
    movement.internalTransactions = transactionsWithMovement;

    return { ...transactionIn[0], movement };
  }
  if (transactionOut.length === 1) {
    // withdraw
    movement.amount = ethers.BigNumber.from(transactionOut[0].total_value).toString();
    movement.token = transactionOut[0].movement?.token;
    movement.type = TRANSACTION_TYPES.out;
    movement.internalTransactions = transactionsWithMovement;
    return { ...transactionOut[0], movement };
  }

  if (transactionGas.length === 1) {
    // withdraw
    movement.amount = ethers.BigNumber.from(transactionGas[0].total_value).toString();
    movement.token = transactionGas[0].movement?.token;
    movement.type = TRANSACTION_TYPES.out;
    movement.internalTransactions = transactionsWithMovement;
    return { ...transactionGas[0], movement };
  }

  return null;
};

/**
 * Groups transactions by timestamp/created_at date, to produce an array of MonthTransactions
 */
export function groupByMonth(
  data: Array<HistoricalTransaction | PendingSwapTransaction | PendingWithdrawTransaction>,
  type: string,
): Array<MonthlyTransactions> {
  // Sort so highest (newest) first
  data.sort(function (x: any, y: any) {
    return type === 'verified' ? y.timestamp - x.timestamp : y.created_at - x.created_at;
  });

  //Group into separate arrays based on month and day
  const res: Array<MonthlyTransactions> = [];
  let monthData: MonthlyTransactions = {
    month: undefined,
    year: undefined,
    transactions: [],
    day: undefined,
    hours: undefined,
    minutes: undefined,
  };

  data.forEach((value: any, index: number) => {
    const date = new Date(type === 'verified' ? value.timestamp * 1000 : value.created_at);
    const month: string = Intl.DateTimeFormat('en', { month: 'long' }).format(date);
    const year = date.getFullYear();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (index === 0) {
      monthData = {
        month: month,
        year: year,
        day: day,
        hours: hours,
        minutes: minutes,
        transactions: [value],
      };
    } else if (monthData.month == month && monthData.year == year && monthData.day == day) {
      monthData.transactions.push(value);
    } else {
      res.push(monthData);
      monthData = {
        month: month,
        year: year,
        day: day,
        hours: hours,
        minutes: minutes,
        transactions: [value],
      };
    }
  });

  if (monthData.month) {
    res.push(monthData); //Push last month data
  }

  return res;
}

export const _getPendingTransactionsCount = async (
  user: User,
  network: Network,
  wallet: Wallet,
) => {
  try {
    const pendingTrx = await getPendingTransactions(user, network, wallet?.id);
    const countPendingSwap = pendingTrx?.swap_orders?.length || 0;
    const countPendingWithdraw = pendingTrx?.withdraw_orders?.length || 0;
    return countPendingSwap + countPendingWithdraw;
  } catch (e) {
    console.error(e);
    return 0;
  }
};

export const groupHistoricalTransactions = (historicalTransactions: HistoricalTransaction[]) => {
  const groupedTransactions = historicalTransactions.reduce((groups: TransactionGroups, trx) => {
    const hash = trx.txn_hash.split('_')[0];
    if (!groups[hash]) {
      groups[hash] = [trx];
    } else {
      groups[hash].push(trx);
    }
    return groups;
  }, {});
  const multipleTransactions = Object.values(groupedTransactions).filter(
    (trxGroup) => trxGroup.length > 1,
  );

  const singleTransactions = Object.values(groupedTransactions)
    .filter((trxGroup) => trxGroup.length === 1)
    .flat();

  return { singleTransactions, multipleTransactions };
};

export const getTransactionAddressLink = (
  transaction: { movement?: Movement; [key: string]: any },
  network: Network,
) => {
  if (!transaction || !transaction.movement) return null;
  if (transaction.status === TRANSACTION_STATUS.pending) return null;

  switch (transaction.movement.type) {
    case TRANSACTION_TYPES.in:
      return {
        label: 'Deposited from',
        url: `${etherscanUrl(network)}/address/${transaction.from_address}`,
        hash: transaction.from_address,
      };
    case TRANSACTION_TYPES.out:
      return {
        label: 'Sent to',
        url: `${etherscanUrl(network)}/address/${transaction.to_address}`,
        hash: transaction.to_address,
      };
    default:
      return null;
  }
};

export const getTransactionHashLink = (
  transaction: { movement?: Movement; [key: string]: any },
  network: Network,
) => {
  const hash = transaction?.txn_hash ? transaction.txn_hash.split('_')[0] : null;

  if (!hash) return null;

  return {
    label: 'View it on Etherscan',
    url: `${etherscanUrl(network)}/tx/${hash}`,
    hash,
  };
};

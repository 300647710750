import { ReactNode } from 'react';
import BasicModal from '../BasicModal';
import Button from '../Button';

type Props = {
  renderOptions: () => ReactNode;
  renderValue?: () => ReactNode;
  value?: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  className?: string;
};

export default function DropdownModal({ value, renderOptions, renderValue, open, setOpen }: Props) {
  return (
    <div className="relative text-right w-full">
      <Button
        variant="ghost"
        className="btn-ghost items-center justify-between w-full px-0 py-4 text-button font-medium text-blue10 normal-case no-animation"
        onClick={() => setOpen(true)}
      >
        {renderValue ? renderValue() : value}
        <img src="/images/DropdownTriangle.png" alt="dropdownTriangle" />
      </Button>

      <BasicModal open={open} onClose={() => setOpen(false)}>
        {renderOptions()}
      </BasicModal>
    </div>
  );
}

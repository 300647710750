import { useContext } from 'react';
import { useRouter } from 'next/router';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { store } from '../../../context/Context';
import BasicModal from '../../Shared/BasicModal';
import { Network } from '../../../constants/types';
import { capitalize } from '../../../../app/utils';

type Props = {
  open?: boolean;
  onClose?: () => void;
};

const NetworkPickerModal = ({ open, onClose }: Props) => {
  const router = useRouter();
  const {
    query: { token },
  } = router;
  const {
    state: { networks, wallet },
  } = useContext(store);

  const onNetworkPress = (item: Network) => {
    onClose();
    if (token) {
      router.push(`/${wallet?.address}/${item.id}`);
    } else {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          network: item.id,
        },
      });
    }
  };

  return (
    <BasicModal open={open} onClose={onClose}>
      {networks?.map((item) => (
        <button
          key={item.id}
          className="w-full h-full normal-case no-animation text-left py-4 border-b border-grey70"
          onClick={() => onNetworkPress(item)}
        >
          <div className="flex items-center justify-between w-full">
            <span className="subtitle2">{capitalize(item.name)}</span>
            <ChevronRightIcon className="w-5 h-5 mx-2 text-grey40" aria-hidden="true" />
          </div>
        </button>
      ))}
    </BasicModal>
  );
};

export default NetworkPickerModal;

import { useCallback, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { alertMessages } from '../../../../app/constants';
import { _getPendingTransactionsCount } from '../../../../app/utils/transactions';
import { store } from '../../../context/Context';
import SuccessAlert from './SuccessAlert';

type Props = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  feature?: string;
  className?: string;
};

const PendingTrxAlert = ({ visible, setVisible, className }: Props) => {
  const router = useRouter();
  const {
    state: { user, wallet, network },
  } = useContext(store);

  const getPendingTransactionsCount = useCallback(async () => {
    if (user) {
      const count = await _getPendingTransactionsCount(user, network, wallet);
      setVisible(!!count);
    }
  }, [user, wallet, network]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      await getPendingTransactionsCount();
    })();
  }, [getPendingTransactionsCount]);

  if (!visible) return null;

  return (
    <SuccessAlert
      message={alertMessages.pendingTrx.message()}
      onClick={() => router.push(`/${wallet.address}/${network.prefix}/reports`)}
      action={alertMessages.pendingTrx.action}
      className={className}
    />
  );
};

export default PendingTrxAlert;

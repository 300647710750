import { useEffect } from 'react';
import { waitEthTransaction } from 'app/common/Trade';
import { AppState, Transaction } from '../../../constants/types';
import { getTransaction } from '../../../utils/eth';

type Props = {
  ethTrx: Transaction;
  setEthTrx: (ethTrx: Transaction) => void;
  setEthTrxError: (ethTrxError: string) => void;
  setSendingEthTrx: (sendingEthTrx: boolean) => void;
  handleCancel: () => void;
  onTransactionSent: () => void;
  state: AppState;
};

const useSendEthTrx = ({
  state,
  ethTrx,
  setEthTrx,
  setEthTrxError,
  setSendingEthTrx,
  handleCancel,
  onTransactionSent,
}: Props) => {
  const { network } = state;

  useEffect(() => {
    if (ethTrx && ethTrx.wait) {
      waitEthTransaction(ethTrx).then(({ success, error }) => {
        setSendingEthTrx(false);
        setEthTrx(null);

        if (success) {
          onTransactionSent();
        } else if (error) {
          setEthTrxError(error);
          handleCancel();
        }
      });
    } else if (ethTrx && ethTrx.hash) {
      getTransaction(ethTrx.hash, network)
        .then((receipt) => {
          // @ts-ignore
          if (receipt && receipt.wait) setEthTrx(receipt);
        })
        .catch(console.error);
    }
  }, [ethTrx, network]); // eslint-disable-line

  return {
    handleCancelEthTrx: handleCancel,
  };
};

export default useSendEthTrx;

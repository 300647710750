import { AppState, Token } from '../../../constants/types';
import useTokenBalance from '../../Trade/hooks/useTokenBalance';
import useNativeBalance from '../../Trade/hooks/useNativeBalance';

type Props = {
  token: Token;
  state: AppState;
};

const useWithdrawBalances = ({ token, state }: Props) => {
  const balance = useTokenBalance({ token, state });
  const nativeBalance = useNativeBalance({ state });

  return {
    balance,
    nativeBalance,
  };
};

export default useWithdrawBalances;

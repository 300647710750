import { useState } from 'react';
import { handleTradeError, isValidAllowance, sendNonEthTradeTransaction } from 'app/common/Trade';
import { validationErrors } from 'app/constants';
import { AppState, Order, Quote, Token, Transaction, Wallet } from '../../../constants/types';
import { getSwapMessage } from '../../../utils/eth';
import useTradeApproval from './useTradeApproval';

type Props = {
  order: Order;
  onTransactionSent: () => void;
  setOpenExternal: (value: boolean) => void;
  getQuoteValues: (order: Order) => Promise<any>;
  state: AppState;
  trackEvent: (key: string, user: any, _attributes: any) => void;
  signMessage: (wallet: Wallet, message: string) => Promise<string>;
  sendTransaction: (wallet: Wallet, params: Transaction) => Promise<any>;
};

const useNonEthTrx = ({
  order,
  onTransactionSent,
  setOpenExternal,
  getQuoteValues,
  state,
  trackEvent,
  signMessage,
  sendTransaction,
}: Props) => {
  const { user, wallet, network } = state;

  const {
    checkingAllowance,
    enablingToken,
    openAllowDialog,
    allowanceValue,
    enableTrx,
    approvalError,
    createEnableTransaction,
    getAllowanceValue,
    setOpenAllowDialog,
  } = useTradeApproval({
    order,
    onTransactionSent: () => createNonEthTransaction(order),
    state,
    trackEvent,
    sendTransaction,
  });

  const [signingMessage, setSigningMessage] = useState(false);
  const [sendingNonEthTrx, setSendingNonEthTrx] = useState(false);
  const [nonEthTrxError, setNonEthTrxError] = useState('');

  const handleCancel = () => {
    setSigningMessage(false);
    setSendingNonEthTrx(false);
    setOpenAllowDialog(false);
    setOpenExternal(false);
  };

  const sendNonEthTransaction = async (
    trx: {
      from: string;
      token0: Token;
      token1: Token;
      amount0: string;
      amount1: string;
      signedMessage: string;
    },
    quote: Quote,
  ) => {
    setSendingNonEthTrx(true);

    // Send transaction to the backend
    const { sendTrxError } = await sendNonEthTradeTransaction(trx, quote, user, network, wallet);

    if (sendTrxError) {
      setNonEthTrxError(handleTradeError(sendTrxError, validationErrors.FAILED_CREATE_TRANSACTION));
      handleCancel();
      return;
    }

    onTransactionSent();
  };

  const createNonEthTransaction = async (order: Order) => {
    try {
      // Check allowance
      const allowance = await getAllowanceValue(order.token0);

      if (!isValidAllowance(allowance, order.amount0)) {
        setOpenAllowDialog(true);
        return;
      }

      // Get quote
      const quote = await getQuoteValues(order);

      // Sign message
      setSigningMessage(true);
      const { message } = await getSwapMessage(order, quote, wallet, network);
      const signedMessage = await signMessage(wallet, message);

      const trx = {
        hash: '',
        from: wallet.address,
        token0: order.token0,
        token1: order.token1,
        amount0: order.amount0,
        amount1: order.amount1,
        signedMessage,
      };
      setSigningMessage(false);

      // Send trx
      await sendNonEthTransaction(trx, quote);
    } catch (e: any) {
      setNonEthTrxError(handleTradeError(e, validationErrors.FAILED_CREATE_TRANSACTION));
      handleCancel();
    }
  };

  return {
    checkingAllowance,
    enablingToken,
    openAllowDialog,
    allowanceValue,
    enableTrx,
    approvalError,
    signingMessage,
    sendingNonEthTrx,
    nonEthTrxError,
    createEnableTransaction,
    getAllowanceValue,
    setOpenAllowDialog,
    createNonEthTransaction,
    handleCancelNonEthTrx: handleCancel,
  };
};

export default useNonEthTrx;

import React from 'react';
import Carousel from '../Shared/Carousel/Carousel';
import LearnCard from './LearnCard';

type Props = {
  slides: { title: string; body: string; link: string }[];
};

export default function LearnCarousel({ slides }: Props) {
  return (
    <Carousel>
      {slides.map((item) => (
        <div className="focus:outline-none my-2" key={item.title}>
          <LearnCard key={item.title} learnItem={item} />
        </div>
      ))}
    </Carousel>
  );
}

import React from 'react';

type LearnCardProps = {
  learnItem: {
    title: string;
    body: string;
    link: string;
  };
  className?: string;
};
export default function LearnCard({ learnItem, className }: LearnCardProps) {
  const { title, body, link } = learnItem;
  return (
    <div className={`${className}`}>
      <div className="body2">{title}</div>
      <div className="bodySmall1 my-4">{body}</div>
      <a
        target="_blank"
        href={link}
        rel="noopener noreferrer"
        className="text-primary labelUltraBold uppercase"
      >
        Read More
      </a>
    </div>
  );
}

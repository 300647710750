import useOrderDetails from './useOrderDetails';
import useWithdrawTokens from './useWithdrawTokens';
import useWithdrawBalances from './useWithdrawBalances';
import useWithdrawInputs from './useWithdrawInputs';
import useWithdrawValidations from './useWithdrawValidations';
import { AppState } from '../../../constants/types';

type Props = {
  state: AppState;
  allowance: string;
  defaultToken?: string;
  disable: boolean;
  error: string;
  trxParams: any;
  validation?: string;
  getAllowanceValue: (token: any) => any;
  getTrxParams: (transfer: any) => void;
  onSubmit: (values: any) => void;
  setError?: (validation?: string) => void;
};

const useWithdrawForm = ({
  state,
  allowance,
  defaultToken,
  disable,
  error,
  trxParams,
  getAllowanceValue,
  getTrxParams,
  onSubmit,
  setError,
}: Props) => {
  const { token, tokenOptions, handleChangeToken } = useWithdrawTokens({ defaultToken, state });

  const { balance, nativeBalance } = useWithdrawBalances({ token, state });

  const {
    amount,
    input,
    to,
    isValidTo,
    handleChangeInput,
    handleChangeTo,
    handleMax,
    currentUnit,
    handleInputUnit,
  } = useWithdrawInputs({
    token,
    balance,
    trxParams,
    allowance,
    state,
  });

  const { totalReceive, commissionFee, networkFee } = useOrderDetails({
    trxParams,
    allowance,
    amount,
    token,
    input,
    state,
    currentUnit,
  });

  const { missingFields } = useWithdrawValidations({
    state,
    transfer: { amount, token, to },
    balance,
    input,
    isValidTo,
    nativeBalance,
    networkFee,
    totalReceive,
    getTrxParams,
    getAllowanceValue,
    setValidation: setError,
  });

  const handleSubmit = () => {
    if (!error) {
      onSubmit({
        token,
        amount,
        to,
      });
    }
  };

  const disableSubmit = disable || Boolean(error) || missingFields;

  return {
    input,
    handleChangeInput,
    token,
    tokenOptions,
    handleChangeToken,
    balance,
    handleMax,
    handleChangeTo,
    commissionFee,
    networkFee,
    totalReceive,
    handleSubmit,
    disableSubmit,
    currentUnit,
    handleInputUnit,
  };
};

export default useWithdrawForm;

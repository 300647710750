import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';

type Props = {
  message: string;
  onClick?: (value: any) => void;
  action?: string;
};

export default function InfoAlert({ message, action, onClick }: Props) {
  if (!message) return null;

  return (
    <div className="alert alert-info text-orange80 bg-info py-3 px-4 rounded-lg">
      <div className="flex-1 items-center">
        <div className="self-start">
          <InformationCircleIcon className="h-10 w-10 lg:h-5 lg:w-5 mr-2" />
        </div>
        <p className="bodySmall2">
          {message}
          &nbsp;
          {action && (
            <span
              className="bodySmall2 underline cursor-pointer hover:opacity-75"
              onClick={onClick}
            >
              {action}
            </span>
          )}
        </p>
      </div>
    </div>
  );
}

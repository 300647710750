import { ethers } from 'ethers';
import { infuraEndpoint } from './eth';
import { encrypt, decrypt } from '../../app/utils/encryption';
import CustomAuth from '@toruslabs/customauth';

let torus = null;
export const getTorus = async () => {
  if (torus) return torus;

  torus = new CustomAuth({
    baseUrl: window.location.origin,
    network: 'mainnet',
    uxMode: 'redirect',
  });
  await torus.init();
  return torus;
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const idTokenToPrivateKey = async (idToken) => {
  if (!idToken) {
    return '';
  }

  try {
    const jwt = parseJwt(idToken);
    if (!jwt.email) {
      return '';
    }
    const _torus = await getTorus();
    const resp = await _torus.getTorusKey(
      'wonderfi-mainnet',
      jwt.email,
      { verifier_id: jwt.email },
      idToken,
    );
    return resp.privateKey;
  } catch (err) {
    console.error(err);
    return '';
  }
};

function queryStringToJSON(str) {
  const pairs = str.split('&');

  const result = {};
  pairs.forEach((pair) => {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });

  return JSON.parse(JSON.stringify(result));
}

export const getUser = async (signup) => {
  let hash = window.location.hash;
  if (hash) {
    hash = hash.substring(1);
    const json = queryStringToJSON(hash);
    if (json && json.error) {
      throw json;
    }
  }
  let existing = decrypt(localStorage.getItem('user'));
  if (existing) {
    try {
      if (existing && existing.privateKey) {
        return existing;
      }
    } catch (err) {} // eslint-disable-line
  }

  const _torus = await getTorus();
  await _torus.init({ skipSw: true });
  let result = null;
  try {
    result = await _torus.getRedirectResult();
    if (result && result.result) {
      result = result.result;
    }
  } catch (err) {} // eslint-disable-line
  if (result) {
    alert(1);
    localStorage.setItem('user', encrypt(result));
    return result;
  }
  const jwtParams = {
    domain: 'https://dev-nwbbdcb8.us.auth0.com',
  };
  if (signup) {
    jwtParams.screen_hint = 'signup';
    jwtParams.display = 'popup';
  }
  const user = await _torus.triggerLogin({
    skipSw: true,
    uxMode: 'redirect',
    typeOfLogin: 'email_password',
    verifier: 'auth0-testnet',
    clientId: 'AaDyewMyYbjrgX5B6dtLcyLif4KozlR3',
    display: 'popup',
    jwtParams,
  });
  alert(2);
  localStorage.setItem('user', encrypt(user));
  return user;
};

let userInfo = null;
export const getUserInfo = async () => {
  if (userInfo) return userInfo;
  try {
    const user = await getUser();
    const wallet = new ethers.Wallet(user.privateKey);
    const info = await user.userInfo;
    const signature = await wallet.signMessage(info.idToken);
    userInfo = {
      wallet,
      signature,
      address: wallet.address,
      info,
    };
    return userInfo;
  } catch (err) {
    return null;
  }
};

export const signingWonderFiProvider = async () => {
  const user = await getUser();
  const provider = ethers.providers.getDefaultProvider(infuraEndpoint());
  return new ethers.Wallet(user.privateKey, provider);
};

export const logout = () => {
  localStorage.setItem('user', null);
  window.location.reload();
};

import { useContext } from 'react';
import { formatTotalReceive, formatTotalReceiveCurrency } from 'app/common/Trade';
import { Token } from '../../constants/types';
import { store } from '../../context/Context';

type Props = {
  amount?: number;
  token?: Token;
  title?: string;
};

const TotalReceive = ({ title = 'Total Receive', amount, token }: Props) => {
  const {
    state: { currency },
  } = useContext(store);
  return (
    <div className="flex justify-between items-start mt-9">
      <div className="">
        <span className="label uppercase text-grey20">{title}</span>
      </div>
      <div className="flex flex-col items-end">
        <span className="subtitle2">{formatTotalReceive(amount, token)}</span>
        <span className="bodySmall2 text-grey40">
          {formatTotalReceiveCurrency(amount, token, currency)}
        </span>
      </div>
    </div>
  );
};

export default TotalReceive;

import { ExternalLinkIcon } from '@heroicons/react/solid';
import BasicModal from '../Shared/BasicModal';
import Button from '../Shared/Button';
import { useContext } from 'react';
import { store } from '../../context/Context';
import { etherscanUrl } from '../../utils/eth';
import Spinner from '../Shared/Spinner';

type Props = {
  trx: any;
  title: string;
};

export default function TransactionLoading({ trx, title }: Props) {
  const {
    state: { network },
  } = useContext(store);
  if (!trx || !trx.hash) return <></>;

  return (
    <BasicModal open={Boolean(trx)} title={title}>
      <div className="my-4 w-full flex items-center justify-center">
        <Spinner className="text-primary" />
      </div>
      <div className="mt-4">
        <a
          target="_blank"
          href={`${etherscanUrl(network)}/tx/${trx.hash}`}
          rel="noopener noreferrer"
          className="focus:outline-none"
        >
          <Button full>
            <ExternalLinkIcon className="w-5 h-5 mr-1" aria-hidden="true" />
            See on explorer
          </Button>
        </a>
      </div>
    </BasicModal>
  );
}

import { postWonderFiWallet, getUserWallets } from '../../utils/api';
import { Network, User, Dispatch } from '../../constants/types';

export const addWonderFiWallet = async (user: User, network: Network, dispatch: Dispatch) => {
  try {
    await postWonderFiWallet(user, network);
    const newWatchlist = await getUserWallets(user, network);

    if (newWatchlist && newWatchlist.length) {
      dispatch({
        type: 'update',
        key: 'watchlist',
        value: [...newWatchlist],
      });
      dispatch({
        type: 'update',
        key: 'wallet',
        value: newWatchlist[0],
      });
    }
  } catch (err) {
    console.error(err);
  }
};

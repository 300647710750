import config from '../../config';
import { Token } from '../../constants/types';

type Props = {
  token: Token;
  size?: number | string;
  className?: string;
};

const TokenLogoImage = ({ token, size, className = '' }: Props) => {
  const tokenURL = new URL(token?.image, config().webURL).toString();

  return (
    <img
      alt={token?.symbol}
      src={tokenURL}
      className={`${size ? `w-${size}` : 'w-8'} ${className}`}
    />
  );
};

export default TokenLogoImage;

import getConfig from 'next/config';

export default function config() {
  const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
  const env =
    serverRuntimeConfig?.ENV ||
    publicRuntimeConfig?.ENV ||
    process.env.NEXT_PUBLIC_REACT_APP_CONFIG;
  if (env == 'staging') {
    return require('./staging');
  }
  if (env == 'production') {
    return require('./production');
  }
  if (env == 'development') {
    return require('./development');
  }

  console.warn('env unset');
  return require('./production');
}

import { ethers } from 'ethers';
import { BALANCE_THRESHOLD_USD } from '../constants';
import { Currency, Token, Wallet, WalletBalances } from '../constants/types';

// Format number value as currency with a fixed amount of decimals => $100.00
export const formatMoney = (
  value: number,
  currency: Currency | string,
  historicRate?: number,
  showSymbol = false,
  decimals = 2,
): string => {
  const currencyValue = value * (typeof currency === 'string' ? 1 : currency?.rate || 1);

  // Crypto
  if (currency && typeof currency !== 'string' && currency.type === 'crypto') {
    return formatCrypto(historicRate ? historicRate * currencyValue : 0, currency.symbol, decimals);
  }

  // Fiat
  const unit = typeof currency === 'string' ? '' : currency?.unit || '';
  const symbol =
    showSymbol && typeof currency !== 'string' && currency?.symbol ? currency?.symbol : '';

  const amount = new Intl.NumberFormat('en', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(currencyValue);

  return `${unit}${amount} ${symbol}`.trim();
};

// Format number value in crypto units with a max amount of decimals => 0.01 ETH
export const formatCrypto = (value: number, symbol: string, decimals = 8): string =>
  `${new Intl.NumberFormat('en', {
    maximumFractionDigits: decimals,
  }).format(value)} ${symbol}`;

// Convert and format a Big Number value into crypto units with a max amount of decimals => 0.01 ETH
export const formatCryptoBN = (value: string, token: Token, decimals = 8): string =>
  formatCrypto(formatAmount(value, token.decimals), token.symbol, decimals);

// Convert and format a Big Number value into currency with a fixed amount of decimals => $100.00
export const formatMoneyBN = (
  value: string,
  token: Token,
  currency: Currency,
  historicRate?: number,
  showSymbol = false,
  decimals = 2,
): string =>
  formatMoney(
    formatAmount(value, token.decimals) * token.price_in_usd,
    currency,
    historicRate,
    showSymbol,
    decimals,
  );

// Format number value to a fixed amount of decimals => 50.00%
export const formatPercentage = (value: any, decimals = 2): string =>
  `${new Intl.NumberFormat('en', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(value)}%`;

export const shortAddress = (address: string) =>
  `${address.substring(0, 6)}...${address.slice(-4)}`;

export const shortName = (name: string) =>
  name.length > 16 ? `${name.substring(0, 6)}...${name.slice(-4)}` : name;

export const shortNameorAddress = (wallet: Wallet) =>
  wallet ? (wallet.name ? shortName(wallet.name) : shortAddress(wallet.address)) : '';

export const shortNameLimit = (name: string, length = 6) =>
  name.length > length ? `${name.substring(0, length)}...` : name;

export const tokenPairRate = (price0: number, price1: number) => {
  if (price1 > 0) return price0 / price1;
  return 0;
};

export const truncate = (value: string, places: number) => {
  const splitted = value.split('.');
  const decimals = splitted[1] ? splitted[1].length : 0;
  if (decimals > places) {
    return `${splitted[0]}.${splitted[1].substring(0, places)}`;
  }
  return value;
};

export const sortByBalance = (t1: { balance: string }, t2: { balance: string }) =>
  ethers.BigNumber.from(t2.balance).gt(ethers.BigNumber.from(t1.balance)) ? 1 : -1;

export const timeoutPromise = (time: number) =>
  new Promise((resolve) => {
    const wait = setTimeout(() => {
      clearTimeout(wait);
      resolve('OK');
    }, time);
  });

export const getToken = (allTokens: any, account: any) => {
  return allTokens && allTokens.find((t: any) => t.address === account?.banner?.token.address);
};

export const capitalize = (value: string) => {
  if (!value || !value.length) return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const nonZeroBalance = (balanceUsd: number) => {
  if (!balanceUsd) return false;

  if (balanceUsd > BALANCE_THRESHOLD_USD) return true;
  return false;
};

export const getTokenBalance = (balances: WalletBalances, token: any) => {
  if (balances && token) {
    const tokenBalance = balances[token.address];
    return nonZeroBalance(tokenBalance?.balance_usd) ? tokenBalance?.balance : '0';
  }
  return '0';
};

export const isValidAmount = (amount: any) => {
  const num = Number(amount);
  if (!num || isNaN(num) || num <= 0) {
    return false;
  }
  return true;
};

export const isValidAmountBN = (amount: string) => {
  try {
    if (!amount) return false;
    const num = ethers.BigNumber.from(amount);
    if (!num || num.lte(ethers.BigNumber.from(0))) {
      return false;
    }
    return true;
  } catch {
    return false;
  }
};

export const parseAmount = (amount: string, decimals: number) =>
  isValidAmount(amount) && decimals
    ? ethers.utils.parseUnits(truncate(amount, decimals), decimals).toString()
    : '0';

export const formatAmount = (amount: string, decimals: number) =>
  isValidAmountBN(amount) && decimals
    ? Number(ethers.utils.formatUnits(ethers.BigNumber.from(amount), decimals))
    : 0;

export const weiToEth = (wei: string) => ethers.utils.formatUnits(wei);

export const ethToWei = (eth: number) => ethers.utils.parseEther(eth.toString());

export const getHexString = (value: any) => ethers.BigNumber.from(value).toHexString();

export const isZeroBN = (value: string) => !value || ethers.BigNumber.from(value).isZero();

export const classNames = (...classes: any[]) => {
  return classes.filter(Boolean).join(' ');
};

// Abbreviates number to compact format, defaults to 1 decimal
// eg. 5920123.883915135 -> 5.9M
export const abbreviateNumber = (num: number, numExtraDecimals = 0) => {
  if (num === null) {
    return null;
  }
  if (num === 0) {
    return '0';
  }

  if (!num) {
    return '-';
  }

  const abbreviations = ['', 'K', 'M', 'B', 'T'];
  numExtraDecimals = !numExtraDecimals || numExtraDecimals < 0 ? 0 : numExtraDecimals;
  const power = num.toPrecision(2).split('e');
  const abbrIndex =
    power.length === 1 ? 0 : Math.floor(Math.min(Number(power[1].slice(1)), 14) / 3); // floor at decimals, ceiling at trillions
  let value =
    abbrIndex < 1
      ? num.toFixed(0 + numExtraDecimals)
      : (num / Math.pow(10, abbrIndex * 3)).toFixed(1 + numExtraDecimals); // divide by power
  value = abbrIndex < 0 ? value : String(Math.abs(Number(value))); // force -0 to 0

  return `${value}${abbreviations[abbrIndex]}`;
};

export const getAbsoluteImageUri = (src: string, webUrl: string) => {
  return src.startsWith('http') ? src : `${webUrl}${src}`;
};

import { useState, useEffect } from 'react';
import { AppState, Order, Token } from '../../../constants/types';
import { validationErrors } from 'app/constants';
import { tradeValidations, checkInsufficientFunds, getQuoteTaker } from 'app/common/Trade';

type Props = {
  order: Order;
  balance0: string;
  input0: string;
  input1: string;
  rate0: number;
  rate1: number;
  quoteError?: string;
  nativeBalance: string;
  networkFee: string;
  isWalletConnected: boolean;
  state: AppState;
  setValidation: (error: string) => void;
  getQuoteValues: (order: Order, taker?: string) => void;
  getAllowanceValue: (token: Token) => void;
};

const useTradeValidations = ({
  order,
  balance0,
  input0,
  input1,
  rate0,
  rate1,
  quoteError,
  nativeBalance,
  networkFee,
  isWalletConnected,
  state,
  setValidation,
  getQuoteValues,
  getAllowanceValue,
}: Props) => {
  const { wallet, network } = state;

  const [missingFields, setMissingFields] = useState(true);
  const [insufficientFunds, setInsufficientFunds] = useState(false);

  const { token0, token1, amount0 } = order;

  useEffect(() => {
    setValidation('');
    setMissingFields(false);

    if (tradeValidations.isViewedOnlyWallet(wallet)) {
      setValidation(validationErrors.VIEW_ONLY_WALLET);
      return;
    }

    if (tradeValidations.hasMissingFields(input0, input1, token0, token1)) {
      setMissingFields(true);
      return;
    }
    if (tradeValidations.hasInvalidAmounts(input0, input1, amount0)) {
      setValidation(validationErrors.ENTER_VALID_AMOUNT);
      return;
    }
    if (tradeValidations.hasInsufficientBalance(balance0, amount0)) {
      setValidation(validationErrors.INSUFFICIENT_BALANCE);
      return;
    }
    if (quoteError) {
      setValidation(quoteError);
      return;
    }
    if (insufficientFunds) {
      setValidation(validationErrors.INSUFFICIENT_BALANCE_TO_COVER_FEE);
      return;
    }
    if (tradeValidations.hasNoConversionRate(rate0, rate1)) {
      setValidation(validationErrors.NO_CONVERSION_RATE);
      return;
    }
    // All validations passed
    setValidation('');
    setMissingFields(false);
  }, [
    input0,
    input1,
    rate0,
    rate1,
    balance0,
    quoteError,
    amount0,
    token0,
    token1,
    wallet,
    insufficientFunds,
    setValidation,
  ]);

  useEffect(() => {
    setInsufficientFunds(
      checkInsufficientFunds(token0, amount0, nativeBalance, networkFee, network),
    );
  }, [nativeBalance, networkFee, amount0, token0, network]);

  useEffect(() => {
    const timeoutFunction = () =>
      getQuoteValues(
        {
          token0,
          token1,
          amount0,
        },
        getQuoteTaker(token0, wallet, network, insufficientFunds, isWalletConnected),
      );

    let timeout: NodeJS.Timeout;
    {
      clearTimeout(timeout);
      timeout = setTimeout(timeoutFunction, 700);
    }

    return () => clearTimeout(timeout);
  }, [
    token0,
    token1,
    amount0,
    insufficientFunds,
    isWalletConnected,
    wallet,
    network,
    getQuoteValues,
  ]);

  useEffect(() => {
    getAllowanceValue(token0);
  }, [token0, getAllowanceValue]);

  return {
    missingFields,
    insufficientFunds,
  };
};

export default useTradeValidations;

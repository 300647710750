import {
  Token,
  User,
  LoggedUser,
  UserWallet,
  Network,
  ReferralCode,
  Client,
  Code,
  Settings,
  Wallet,
  FiatPurchaseInputs,
  FiatQuoteResponse,
  FiatOrder,
  NFT,
} from '../constants/types';
import { getWallet } from './eth';

let userWallet: UserWallet = null;
let idToken: string = null;

export const resetUserWallet = () => {
  userWallet = null;
};

export const getUserWallet = async (user?: User): Promise<UserWallet | null> => {
  if (!user) {
    return null;
  }
  const info = user.userInfo;
  // if idToken has changed, we want to refresh
  if (userWallet && info.idToken === idToken) {
    return userWallet;
  }
  idToken = info.idToken;
  const wallet = getWallet(user.privateKey);
  const signature = await wallet.signMessage(info.idToken);
  userWallet = {
    wallet,
    signature,
    address: wallet.address,
    info,
  };
  return userWallet;
};

let getHost = () => '';
export const setHost = (getHostFn: () => string) => {
  getHost = getHostFn;
};

const get = async (path: string, user?: User, network?: Network): Promise<any> => {
  try {
    const url = new URL(path, getHost());
    if (network?.id) {
      url.searchParams.append('network', network.id.toString());
    }

    const userWallet = await getUserWallet(user);
    const response = await fetch(url.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        signature: userWallet?.signature,
        idToken: userWallet?.info.idToken,
        address: userWallet?.address,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      const error = await response.json();
      return Promise.reject(new Error(error.msg));
    }
  } catch (e) {
    return Promise.reject(new Error('Failed to get data'));
  }
};

const save =
  (method: string) =>
  async (
    path: string,
    body: Record<string, unknown>,
    user?: User,
    network?: Network,
  ): Promise<any> => {
    try {
      const url = new URL(path, getHost());
      if (network?.id) {
        url.searchParams.append('network', network.id.toString());
      }
      const userWallet = await getUserWallet(user);
      const response = await fetch(url.href, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          signature: userWallet.signature,
          idToken: userWallet.info.idToken,
          address: userWallet.address,
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        return response.json();
      } else {
        const error = await response.json();
        return Promise.reject(new Error(error.msg));
      }
    } catch (e) {
      console.error(e);
      return Promise.reject('Failed to save data');
    }
  };

const post = save('POST');
const put = save('PUT');

const deleteRequest = async (path: string, user?: User, network?: Network): Promise<any> => {
  try {
    const url = new URL(path, getHost());
    if (network?.id) {
      url.searchParams.append('network', network.id.toString());
    }
    const userWallet = await getUserWallet(user);
    const response = await fetch(url.href, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        signature: userWallet.signature,
        idToken: userWallet.info.idToken,
        address: userWallet.address,
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      const error = await response.json();
      return Promise.reject(new Error(error.msg));
    }
  } catch (e) {
    console.error(e);
    return Promise.reject('Failed to delete data');
  }
};

export const login = async (user: User): Promise<LoggedUser> => get(`/api/user/me`, user);

export const getUserWallets = async (user: User, network: Network): Promise<Wallet[]> =>
  get(`/api/user/me/wallets`, user, network);

export const getAccountBalances = async (
  user: User,
  network: Network,
  walletId: string,
  tag?: string,
): Promise<any> =>
  get(`/api/wallets/balances?walletId=${walletId}${tag ? `&tag=${tag}` : ''}`, user, network);

export const updateWalletBalance = async (
  user: User,
  network: Network,
  walletId: string,
  tag?: string,
): Promise<any> =>
  put(`/api/wallets/balances?walletId=${walletId}${tag ? `&tag=${tag}` : ''}`, {}, user, network);

export const getAccounts = async (network: Network): Promise<any> => {
  const accounts = await get(`/api-open/accounts`, undefined, network);
  return accounts.sort((a: any, b: any) => b.rank - a.rank);
};

export const getInterestEarned = async (
  user: User,
  network: Network,
  walletId: string,
  token: string,
): Promise<any> =>
  get(`/api/user/me/wallets/${walletId}/interest-earned?token=${token}`, user, network);

export const getEarnings = async (
  user: User,
  network: Network,
  walletId: string,
  token: string,
): Promise<any> => get(`/api/user/me/wallets/${walletId}/earnings?token=${token}`, user, network);

export const getPnL = async (user: User, network: Network, walletId: string): Promise<any> =>
  get(`/api/user/me/wallets/${walletId}/pnl`, user, network);

export const getWalletPerformanceHistory = async (
  user: User,
  network: Network,
  walletId: string,
  interval = 'daily',
): Promise<any> =>
  get(`/api/user/me/wallets/${walletId}/historical-balances?interval=${interval}`, user, network);

export const getTokens = async (network: Network, tag: string = undefined): Promise<any> =>
  get(`/api-open/tokens${tag ? `?tag=${tag}` : ''}`, undefined, network);

export const getUserProfile = async (user: User, network: Network): Promise<any> =>
  get(`/api/user/me`, user, network);

export const updateUserProfile = (user: User, network: Network, profile: { name: string }) =>
  put(`/api/user/me`, profile, user, network);

export const getUserSettings = async (user: User, network: Network): Promise<any> =>
  get(`/api/user/me/settings`, user, network);

export const updateUserSettings = (user: User, network: Network, settings: Settings) =>
  post(`/api/user/me/settings`, settings, user, network);

export const addUserWallet = (
  user: User,
  network: Network,
  walletInfo: { address: string; name: string; source: string },
) => post(`/api/user/me/wallets`, walletInfo, user, network);

export const deleteUserWallet = (user: User, network: Network, walletId: string) =>
  deleteRequest(`/api/user/me/wallets/${walletId}`, user, network);

export const updateUserWallet = (
  user: User,
  network: Network,
  walletId: string,
  walletInfo: { address: string; name: string; source: string },
) => post(`/api/user/me/wallets/${walletId}`, walletInfo, user);

export const getQuote = async (
  network: Network,
  buy: Token,
  sell: Token,
  amount: string,
  taker?: string,
) => {
  return get(
    `/api-open/tokens/quotes?buyToken=${buy.address}&sellToken=${
      sell.address
    }&sellAmount=${amount}${taker ? `&takerAddress=${taker}` : ''}`,
    undefined,
    network,
  );
};

export const postPendingVerifiedTransaction = (
  user: User,
  network: Network,
  walletId: string,
  {
    hash,
    from,
    to,
    value,
    timestamp,
    token,
  }: { hash: string; from: string; to: string; value: string; timestamp: number; token: string },
) => {
  const body = {
    txn_hash: hash,
    from_address: from,
    to_address: to,
    txn_value: Number(value),
    timestamp,
    token_address: token,
  };

  return post(
    `/api/user/me/wallets/${walletId}/transactions/pending/verified`,
    body,
    user,
    network,
  );
};

export const postPendingSwapTransaction = (
  user: User,
  network: Network,
  walletId: string,
  {
    from,
    token0,
    token1,
    amount0,
    amount1,
    signedMessage,
    to,
    data,
    allowanceTarget,
  }: {
    from: string;
    token0: Token;
    token1: Token;
    amount0: string;
    amount1: string;
    signedMessage: string;
    to: string;
    data: string;
    allowanceTarget: string;
  },
) => {
  const body = {
    source_address: from,
    source_token: token0.address,
    destination_token: token1.address,
    source_amount: amount0,
    destination_amount: amount1,
    signed_message: signedMessage,
    to,
    data,
    allowance_target: allowanceTarget,
  };

  return post(`/api/user/me/wallets/${walletId}/transactions/pending/swap`, body, user, network);
};

export const postPendingWithdrawTransaction = (
  user: User,
  network: Network,
  walletId: string,
  {
    from,
    to,
    token,
    amount,
    signedMessage,
  }: {
    from: string;
    to: string;
    token: Token;
    amount: string;
    signedMessage: string;
  },
) => {
  const body = {
    from_address: from,
    to_address: to,
    token_address: token.address,
    amount: amount,
    signed_message: signedMessage,
  };

  return post(
    `/api/user/me/wallets/${walletId}/transactions/pending/withdraw`,
    body,
    user,
    network,
  );
};

export const postPendingTransferTransaction = (
  user: User,
  network: Network,
  walletId: string,
  {
    contract,
    from,
    networkId,
    quantity,
    signature,
    standard,
    to,
    token_id,
  }: {
    contract: string;
    from: string;
    networkId: number;
    quantity: string;
    signature: string;
    standard: string;
    to: string;
    token_id: string;
  },
) => {
  const body = {
    contract: contract,
    from: from,
    network_id: networkId,
    quantity: quantity,
    signature: signature,
    standard: standard,
    to: to,
    token_id: token_id,
  };

  return post(
    `/api/user/me/wallets/${walletId}/transactions/pending/transfer`,
    body,
    user,
    network,
  );
};

export const getPendingTransactions = async (
  user: User,
  network: Network,
  walletId: string,
  txnType?: string,
) => {
  return get(
    `/api/user/me/wallets/${walletId}/transactions/pending${txnType ? `?txnType=${txnType}` : ''}`,
    user,
    network,
  );
};

export const getPaymentUrl = async (
  user: User,
  network: Network,
  walletId: string,
  token = '',
  paymentMethod = '',
) => {
  return post(
    `/api/user/me/wallets/${walletId}/reserve?token=${token}&paymentMethod=${paymentMethod}`,
    {},
    user,
    network,
  );
};

export const exportAccountTransactions = async (user: User, network: Network, walletId: string) => {
  return get(`/api/user/me/wallets/${walletId}/transactions/export`, user, network);
};

export const getAccountTransactions = async (
  user: User,
  network: Network,
  walletId: string,
  limit = 100,
  offset = 0,
  accountType = '',
) => {
  return get(
    `/api/user/me/wallets/${walletId}/transactions?limit=${limit}&offset=${offset}${
      accountType ? `&tag=${accountType}` : ''
    }`,
    user,
    network,
  );
};

export const updateAccountTransactions = (user: User, network: Network, walletId: string) => {
  return put(`/api/user/me/wallets/${walletId}/transactions`, {}, user, network);
};

export const postWonderFiWallet = (user: User, network: Network) => {
  return post(`/api/user/me/wallets/wonderfi`, {}, user, network);
};

export const getTokenMetadata = (network: Network, symbol: string) =>
  get(`/api-open/tokens/metadata?symbol=${symbol}`, undefined, network);

export const getTokenMetadataAll = (network: Network) =>
  get(`/api-open/tokens/metadata-all`, undefined, network);

export const getTokenMarkets = (network: Network, symbol: string, interval = 'daily') =>
  get(`/api-open/tokens/markets?symbol=${symbol}&interval=${interval}`, undefined, network);

export const getConfiguration = (network: Network) => get(`/api-open/config`, undefined, network);

export const updateNotificationToken = (
  user: User,
  network: Network,
  token_type: string,
  token: string,
) => {
  return post(`/api/user/me/notification`, { token_type, token }, user, network);
};

export const getPlaidLink = (user: User, network: Network, country: string) => {
  return get(`/api/user/me/plaid-link?country=${country}`, user, network);
};

export const getUserAccount = (user: User, network: Network) => {
  return get(`/api/user/me/account`, user, network);
};

export const getFiatPurchaseInputs = (
  user: User,
  network: Network,
  walletId: string,
): Promise<FiatPurchaseInputs> => {
  return get(`/api/user/me/wallets/${walletId}/fiat-purchase-inputs`, user, network);
};

export const getFiatQuote = (
  user: User,
  network: Network,
  walletId: string,
  source: string,
  target: string,
  amount: number,
): Promise<FiatQuoteResponse> => {
  return get(
    `/api/user/me/wallets/${walletId}/quote?source=${source}&target=${target}&amount=${amount}`,
    user,
    network,
  );
};

export const createOrder = (
  user: User,
  network: Network,
  walletId: string,
  source: string,
  target: string,
  amount: string,
  paymentMethod: number,
  redirectUri: string,
): Promise<FiatOrder> => {
  return get(
    `/api/user/me/wallets/${walletId}/create-order?source=${source}&target=${target}&amount=${amount}&payment_method=${paymentMethod}&redirect_uri=${redirectUri}`,
    user,
    network,
  );
};

export const getNetworks = () => get(`/api-open/networks`);

export const getAllPromotions = (user: User) => {
  return get(`/api/user/me/promotions`, user);
};

export const postUserPromotions = (user: User, promotionInfo: ReferralCode) => {
  return post(`/api/user/me/promotions`, promotionInfo, user);
};

export const getUserPromotionRejectionStatus = (user: User, promotionInfo: ReferralCode) => {
  return get(
    `/api/user/me/promotions/rejection-status/${promotionInfo.unique_campaign_identifier}`,
    user,
  );
};

export const postUserPromotionRejectionStatus = (user: User, promotionInfo: ReferralCode) => {
  return post(
    `/api/user/me/promotions/rejection-status/${promotionInfo.unique_campaign_identifier}`,
    promotionInfo,
    user,
  );
};

export const postAuth0PasswordReset = async (email: string) => {
  const body = {
    client_id: 'dRYnkcNge3vFPZW3o7LzGsFjqtRFBIZs',
    email,
    connection: 'Username-Password-Authentication',
  };
  try {
    const response = await fetch(`https://auth.wonder.fi/dbconnections/change_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const data = await response.text;

    if (response.status === 500) {
      return Promise.reject(data);
    }
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const getCurrencies = async () => {
  const supportedCurrencies = [
    'USD',
    'CAD',
    'EUR',
    'GBP',
    'AUD',
    'JPY',
    'RUB',
    'INR',
    'MXN',
    'BRL',
    'CHF',
    'SEK',
    'NOK',
    'KRW',
    'HKD',
    'SGD',
    'TRY',
  ];

  const exchangeRatesResponse = await get('/api-open/exchange-rates');
  const exchangeRatesData = exchangeRatesResponse?.rates;
  let modifiedData;

  if (exchangeRatesData && Object.keys(exchangeRatesData).length) {
    const usdRate = exchangeRatesData['usd']?.value;
    modifiedData = supportedCurrencies
      .map((c) => {
        if (exchangeRatesData[c.toLocaleLowerCase()])
          return {
            symbol: c,
            rate: exchangeRatesData[c.toLocaleLowerCase()].value / usdRate,
            unit: exchangeRatesData[c.toLocaleLowerCase()]?.unit || '',
            type: exchangeRatesData[c.toLocaleLowerCase()]?.type || '',
          };
      })
      .filter((x) => x !== undefined);
  } else {
    modifiedData = [
      {
        symbol: 'USD',
        rate: 1,
        unit: '$',
        type: 'fiat',
      },
    ];
  }

  return modifiedData;
};

export const getMarketData = async (id: string, currency: string, days: number) => {
  return get(
    `/api-open/tokens/market-chart?id=${id}&currency=${currency.toLowerCase()}&days=${days}`,
  );
};

export const getMarketPortfolioData = async (currency: string, days: number = 365) => {
  return get(
    `/api-open/tokens/market-chart?id=tether&currency=${currency.toLowerCase()}&days=${days}`,
  );
};

export const getGasQuote = (network: Network) => {
  return get(`api-open/tokens/gas`, undefined, network);
};

export const triggerVerifyEmail = (email: string) => {
  const url = new URL('api-open/user/trigger-verify-email', getHost());
  url.searchParams.append('email', email);
  return fetch(url.href).then((resp) => resp.json());
};

export const pingStatus = async () => {
  const url = new URL('status/ping', getHost());
  try {
    const data = await fetch(url.href);
    if (data.ok) {
      return data.json();
    }
    const body = JSON.parse(await data.text());
    return body;
  } catch (err) {
    return {};
  }
};

export const getClient = async (user: User, clientId: string): Promise<Client> => {
  return get(`/api/oauth/client?clientId=${clientId}`, user);
};
export const getCode = async (user: User, clientId: string): Promise<Code> => {
  return get(`/api/oauth/login?clientId=${clientId}`, user);
};

export const getNFTs = async (user: User, wallet: Wallet, network: Network) => {
  return get(`/api/data/me/tokens?type=ERC1155,ERC721&walletId=${wallet.id}`, user, network);
};

export const getNFT = async (
  tokenId: string,
  contract: string,
  wallet: Wallet,
  network: Network,
) => {
  return get(
    `/api-open/data/token?token=${tokenId}&contract=${contract}&walletId=${wallet.id}`,
    null,
    network,
  );
};

export const getNFTTransactions = async (nft: NFT, wallet: Wallet, network: Network) => {
  return get(
    `/api-open/data/token/transactions?token=${nft.id}&contract=${nft.address}&walletId=${wallet.id}`,
    null,
    network,
  );
};

export const getLinkedAccounts = async (user: User) => {
  return get(`/api/user/me/connections`, user);
};

export const deleteLinkedAccount = async (user: User, clientId: string) => {
  return deleteRequest(`/api/user/me/connections?clientId=${clientId}`, user);
};

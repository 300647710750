import { classNames } from 'app/utils';
import React from 'react';
import { goBigDataInfo } from '../../../../app/constants';

type Props = {
  isSidebar?: boolean;
};

export default function GoBigDescription({ isSidebar }: Props) {
  return (
    <div className={classNames(isSidebar ? 'px-4 py-6' : 'md:px-4 py-6')}>
      <div className={classNames(isSidebar ? 'title2' : 'subtitle1', 'mb-3 text-blue10')}>
        {goBigDataInfo.header}
      </div>
      <div className={classNames(isSidebar ? 'bodySmall1' : 'body1', 'text-grey20')}>
        {goBigDataInfo.description}
      </div>
    </div>
  );
}

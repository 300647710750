import { useState } from 'react';
import { isValidAllowance } from 'app/common/Trade';
import { validationErrors } from 'app/constants';
import { AppState, Token, Transaction, Transfer, Wallet } from '../../../constants/types';
import { getTransferMessage } from '../../../utils/eth';
import useWithdrawApproval from './useWithdrawApproval';
import { postPendingWithdrawTransaction } from '../../../utils/api';
import { handleWithdrawError } from 'app/common/Withdraw';

type Props = {
  state: AppState;
  transfer: Transfer;
  onTransactionSent: (transfer: Transfer) => void;
  setOpenExternal: (open: boolean) => void;
  sendTransaction: (wallet: Wallet, params: Transaction) => Promise<any>;
  signMessage: (wallet: Wallet, message: string) => Promise<string>;
  trackEvent: (key: string, user: any, _attributes: any) => void;
};

const useNonEthTrx = ({
  state,
  transfer,
  onTransactionSent,
  setOpenExternal,
  sendTransaction,
  signMessage,
  trackEvent,
}: Props) => {
  const { user, wallet, network } = state;

  const {
    checkingAllowance,
    enablingToken,
    openAllowDialog,
    allowanceValue,
    enableTrx,
    approvalError,
    createEnableTransaction,
    getAllowanceValue,
    setOpenAllowDialog,
  } = useWithdrawApproval({
    state,
    transfer,
    onTransactionSent: () => createNonEthTransaction(transfer),
    setOpenExternal,
    sendTransaction,
    trackEvent,
  });

  const [signingMessage, setSigningMessage] = useState(false);
  const [sendingNonEthTrx, setSendingNonEthTrx] = useState(false);
  const [nonEthTrxError, setNonEthTrxError] = useState('');

  const handleCancel = () => {
    setSigningMessage(false);
    setSendingNonEthTrx(false);
    setOpenExternal(false);
    setOpenAllowDialog(false);
  };

  const sendNonEthTransaction = async (trx: {
    from: string;
    to: string;
    token: Token;
    amount: string;
    signedMessage: string;
  }) => {
    try {
      setSendingNonEthTrx(true);

      // Send transaction to the backend
      const res = await postPendingWithdrawTransaction(user, network, wallet.id, {
        ...trx,
      });

      if (res?.msg) {
        throw new Error(res.msg);
      }

      onTransactionSent(trx);
    } catch (e: any) {
      setNonEthTrxError(handleWithdrawError(e, validationErrors.FAILED_CREATE_TRANSACTION));
      handleCancel();
    }
  };

  const createNonEthTransaction = async (transfer: Transfer) => {
    try {
      // Check allowance
      const allowance = await getAllowanceValue(transfer.token);

      if (!isValidAllowance(allowance, transfer.amount)) {
        setOpenAllowDialog(true);
        return;
      }

      // Sign message
      setSigningMessage(true);
      const { message } = await getTransferMessage(transfer, wallet, network);
      const signedMessage = await signMessage(wallet, message);

      const trx = {
        hash: '',
        from: wallet.address,
        token: transfer.token,
        amount: transfer.amount,
        signedMessage,
        to: transfer.to,
      };
      setSigningMessage(false);

      // Send trx
      await sendNonEthTransaction(trx);
    } catch (e: any) {
      setNonEthTrxError(handleWithdrawError(e, validationErrors.FAILED_CREATE_TRANSACTION));
      handleCancel();
    }
  };

  return {
    allowanceValue,
    approvalError,
    checkingAllowance,
    enablingToken,
    enableTrx,
    nonEthTrxError,
    openAllowDialog,
    sendingNonEthTrx,
    signingMessage,
    createEnableTransaction,
    createNonEthTransaction,
    getAllowanceValue,
    setOpenAllowDialog,
    handleCancelNonEthTrx: handleCancel,
  };
};

export default useNonEthTrx;

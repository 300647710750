import { useState, useCallback } from 'react';
import { handleWithdrawError, _getTrxParams } from 'app/common/Withdraw';
import { validationErrors } from 'app/constants';
import { AppState, Transfer } from '../../../constants/types';

type Props = {
  state: AppState;
};

const useTransferParams = ({ state }: Props) => {
  const { user, network, wallet } = state;

  const [loadingParams, setLoadingParams] = useState(false);
  const [trxParams, setTrxParams] = useState(null);
  const [paramsError, setParamsError] = useState('');

  const getTrxParams = useCallback(
    async (transfer: Transfer) => {
      setLoadingParams(true);
      const params = await _getTrxParams(user, network, transfer, wallet);
      if (!params) {
        setLoadingParams(false);
        setParamsError(handleWithdrawError(null, validationErrors.FAILED_GAS));
        return;
      }
      setLoadingParams(false);
      setTrxParams(params);
      return params;
    },
    [network, user, wallet],
  );

  return {
    loadingParams,
    trxParams,
    paramsError,
    getTrxParams,
  };
};

export default useTransferParams;

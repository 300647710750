import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import WalletConnectProvider from '@walletconnect/web3-provider';
import WalletLink from 'walletlink';
import TransportWebUSB from '@ledgerhq/hw-transport-webusb';
import AppEth from '@ledgerhq/hw-app-eth';
import config from '../config';

export * from '../../app/utils/eth';

const infuraProjectId = () => config().infuraProjectId;
export const infuraEndpoint = () => `https://mainnet.infura.io/v3/${infuraProjectId()}`;

export const getCurrentAddress = () => (window.ethereum ? window.ethereum.selectedAddress : null);

export const isConnected = () => (window.ethereum ? window.ethereum.isConnected() : false);

export const getWindowProvider = () => {
  if (!window.ethereum || !window.ethereum.request) {
    return null;
  }
  return window.ethereum;
};

export const connectWindowProvider = async () => {
  const windowProvider = getWindowProvider();
  if (!windowProvider) {
    return [];
  }
  const accounts = await windowProvider.request({ method: 'eth_requestAccounts' });
  if (!accounts || !accounts.length) {
    return new Promise((resolve, reject) => {
      try {
        windowProvider.on('accountsChanged', (accounts) => {
          if (accounts && accounts.length) {
            resolve(accounts);
            return;
          }
          resolve([]);
        });
      } catch (e) {
        reject(e);
      }
    });
  }
  return accounts;
};

export const isWindowProviderConnected = (address) => {
  const windowProvider = getWindowProvider();
  if (!windowProvider) {
    // Browser extension not installed
    return false;
  }

  if (!windowProvider.selectedAddress) {
    // No accounts selected
    return false;
  }

  if (windowProvider.selectedAddress.toLowerCase() !== address.toLowerCase()) {
    // Address not selected in the extension
    return false;
  }
  // Connected!
  return true;
};

export const sendWindowTransaction = (params) => {
  const windowProvider = getWindowProvider();
  return windowProvider.request({
    method: 'eth_sendTransaction',
    params: [params],
  });
};

export const signWindowMessage = (walletAddress, message) => {
  const windowProvider = getWindowProvider();
  return windowProvider.request({
    method: 'personal_sign',
    params: [message, walletAddress],
  });
};

export const isAddress = (address) => ethers.utils.isAddress(address);

export const toETH = (balance) => {
  if (!balance) {
    return '';
  }
  return ethers.utils.formatEther(balance);
};

export const formatETH = (balance) => {
  if (!balance) {
    return '';
  }
  return `${toETH(balance)} ETH`;
};

export const getBalance = (address) =>
  window.ethereum.request({
    method: 'eth_getBalance',
    params: [address, 'latest'],
  });

let web3Provider = null;
export const getWeb3Provider = async () => {
  if (web3Provider) {
    return web3Provider;
  }
  const provider = await detectEthereumProvider();
  if (!provider) {
    return null;
  }
  web3Provider = new ethers.providers.Web3Provider(provider);
  return web3Provider;
};

let walletConnectProvider = null;
export const getWalletConnectProvider = async () => {
  try {
    if (!walletConnectProvider) {
      walletConnectProvider = new WalletConnectProvider({
        infuraId: infuraProjectId(),
      });
    }
    await walletConnectProvider.enable();
    return walletConnectProvider;
  } catch (e) {
    walletConnectProvider = null;
    throw e;
  }
};

let walletLinkProvider = null;
export const getWalletLinkProvider = () => {
  if (!walletLinkProvider) {
    const walletLink = new WalletLink({
      appName: 'WonderFi',
      appLogoUrl: '/images/wonderfi-logo.png',
      darkMode: false,
    });

    walletLinkProvider = walletLink.makeWeb3Provider(infuraEndpoint(), 1);
  }
  return walletLinkProvider;
};

export const connectWalletLinkProvider = () => {
  const wlProvider = getWalletLinkProvider();
  return wlProvider.enable();
};

let torusInstance = null;
const torusProvider = null;
export const getTorusInstance = async () => {
  if (torusInstance) return torusInstance;

  const Torus = (await import('@toruslabs/torus-embed')).default;
  torusInstance = new Torus({});
  if (!torusInstance.isLoggedIn) {
    try {
      await torusInstance.init({
        enableLogging: false,
        whiteLabel: {
          theme: {
            isDark: false,
            colors: {
              torusBrand1: '#282c34',
            },
          },
          logoDark: '/images/wonderfi-logo.png', // Dark logo for light background
          logoLight: '/images/wonderfi-logo.png', // Light logo for dark background
          topupHide: false,
          featuredBillboardHide: true,
          disclaimerHide: true,
          defaultLanguage: 'en',
        },
      });
      await torusInstance.login();
    } catch (e) {
      return null;
    }
  }

  return torusInstance;
};

export const getTorusProvider = async () => {
  if (torusProvider) return torusProvider;

  const _torusInstance = await getTorusInstance();

  return _torusInstance.provider;
};

export const connectTorusProvider = async () => {
  const _torusProvider = await getTorusProvider();
  return _torusProvider.enable();
};

export const cleanTorusInstance = async () => {
  const _torusInstance = await getTorusInstance();
  await _torusInstance.cleanUp();
  torusInstance = null;
};

export const getTorusUserInfo = async () => {
  const userInfo = await torusInstance.getUserInfo();
  return userInfo;
};

// HD derivation paths
export const ledgerLivePath = (accountIndex) => `44'/60'/${accountIndex}'/0/0`;
export const ethLegacyPath = (accountIndex) => `44'/60'/0'/${accountIndex}`;

export const getLedgerAddress = async (transport, path) => {
  const eth = new AppEth(transport);
  const account = await eth.getAddress(path, false);
  return account.address;
};

export const signLedgerTrx = async (transport, path, params) => {
  const trx = ethers.utils
    .serializeTransaction({
      to: params.to,
      nonce: params.nonce,
      data: params.data,
      gasLimit: params.gas,
      gasPrice: params.gasPrice,
    })
    .slice(2);

  const eth = new AppEth(transport);
  const signed = await eth.signTransaction(path, trx);

  const signature = ethers.utils.joinSignature({
    r: `0x${signed.r}`,
    s: `0x${signed.s}`,
    v: signed.v,
  });

  const signedTrx = ethers.utils.serializeTransaction(
    {
      to: params.to,
      nonce: params.nonce,
      data: params.data,
      gasLimit: ethers.BigNumber.from(params.gas).toHexString(),
      gasPrice: ethers.BigNumber.from(params.gasPrice).toHexString(),
      value: params.value,
    },
    signature,
  );

  const provider = new ethers.providers.JsonRpcProvider(infuraEndpoint(), 'homestead');

  return provider.sendTransaction(signedTrx);
};

export const connectLedger = () =>
  new Promise((resolve, reject) => {
    if (!navigator) reject(new Error('Unsupported browser'));
    else resolve();
  }).then(() =>
    navigator.usb.getDevices().then((devices) => {
      if (devices.length) {
        return TransportWebUSB.open(devices[0]);
      }
      return TransportWebUSB.create();
    }),
  );

export const formatGas = (gasPrice, gas) => {
  const bn1 = ethers.BigNumber.from(gasPrice);
  const bn2 = ethers.BigNumber.from(gas);
  return ethers.utils.formatUnits(bn1.mul(bn2).toString(), 18);
};

import Button from '../Shared/Button';
import Carousel from '../Shared/Carousel/Carousel';

import { useAuth0 } from '@auth0/auth0-react';
import DashboardLoader from './DashboardLoader';
import { useCallback, useMemo } from 'react';
import Meta, { MetaProps } from '../Shared/Meta';

const carouselSlides = [
  {
    title: 'Welcome to WonderFi',
    body: 'Your destination for securely accessing the best opportunities in DeFi with confidence and ease.',
    imageSrc: '/images/landing-01.png',
  },
  {
    title: 'What is DeFi',
    body: 'Decentralized finance is an open financial system that enables people to control their assets, invest, trade, and earn without a middleman.',
    imageSrc: '/images/landing-02.png',
  },
];

type Props = {
  meta: MetaProps;
  redirectURL?: string;
};

const LandingPage = ({ meta, redirectURL }: Props) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const target = useMemo(() => redirectURL || window.location.href, [redirectURL]);

  const authenticate = useCallback(
    (isSignup: boolean) => {
      loginWithRedirect({
        scope: 'openid profile email offline_access',
        redirectUri: window.location.origin,
        screen_hint: isSignup ? 'signup' : undefined,
        appState: {
          target: isSignup ? window.location.origin : target,
        },
      });
    },
    [loginWithRedirect, target],
  );

  if (isAuthenticated) {
    //Display loader during time after successful authentication but state.user not finished yet
    return <DashboardLoader meta={meta} />;
  }

  return (
    <>
      <div className="bg-white flex justify-center items-center relative pt-0 sm:pt-20 sm:pb-5">
        <Meta title={'Welcome'} />
        <div className="relative bg-white w-full h-[660px] sm:max-h-[580px] flex flex-col-reverse items-center rounded-[12px] py-[50px] md:py-[50px] my-6 sm:my-0">
          <img
            src="/images/wonderfi-icon.png"
            alt="wonderFiIcon"
            className="absolute top-0 mx-auto sm:-mt-14 hidden sm:block"
          />
          <div className="w-full flex justify-center">
            <div className="w-full">
              <Carousel>
                {carouselSlides.map((slide) => (
                  <div className="focus:outline-none" key={slide.title}>
                    <img
                      className="mx-auto mb-14 sm:mb-2 w-[198px] lg:w-[198px]"
                      src={slide.imageSrc}
                      alt=""
                    />
                    <div className="mt-10 sm:mt-0 h2 text-center text-[20px]">{slide.title}</div>
                    <div className="max-w-[350px] mx-auto mt-2 bodySmall1 text-grey20 text-center">
                      {slide.body}
                    </div>
                  </div>
                ))}
              </Carousel>
              <Button className="lg:mb:0 mb-4 mt-10" full onClick={() => authenticate(true)}>
                <div>Sign up with Wonderfi to continue</div>
              </Button>
              <Button
                variant="ghost"
                className="text-primary"
                full
                onClick={() => authenticate(false)}
              >
                Already have an account? Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LandingPage;

import { useState, useEffect, useCallback } from 'react';
import { tokenPairRate } from 'app/utils';
import { emptyToken, getTokenOptions } from 'app/utils/tokens';
import { getPreselectedTokens } from 'app/common/Trade';
import { AppState } from '../../../constants/types';

type Props = {
  defaultToken0: string;
  defaultToken1: string;
  state: AppState;
};

const useTradeTokens = ({ defaultToken0, defaultToken1, state }: Props) => {
  const { allTokens, walletBalances } = state;

  const [allOptions, setAllOptions] = useState([]);
  const [tokenOptions0, setTokenOptions0] = useState([]);
  const [tokenOptions1, setTokenOptions1] = useState([]);
  const [token0, setToken0] = useState(emptyToken);
  const [token1, setToken1] = useState(emptyToken);
  const [rate0, setRate0] = useState(0);
  const [rate1, setRate1] = useState(0);

  useEffect(() => {
    if (allTokens && walletBalances) {
      const { options } = getTokenOptions(allTokens, walletBalances);
      setAllOptions(options);

      const { preselected0, preselected1 } = getPreselectedTokens(
        options,
        defaultToken0,
        defaultToken1,
      );

      if (!token0?.address) setToken0(preselected0);
      if (!token1?.address) setToken1(preselected1);
    }
  }, [allTokens, walletBalances, defaultToken0, defaultToken1, token0, token1]);

  const handleChangeToken0 = (value: any) => {
    if (token1 && token1.address === value.address) setToken1(token0);
    setToken0(value);
  };

  const handleChangeToken1 = (value: any) => {
    if (token0 && token0.address === value.address) setToken0(token0);
    setToken1(value);
  };

  const updateOptions = useCallback(() => {
    const tokenOptions = allOptions.filter(
      (option) => option?.address !== token1?.address && option?.address !== token0?.address,
    );
    setTokenOptions0(tokenOptions);
    setTokenOptions1(tokenOptions);
    if (token1?.address === token0?.address) {
      tokenOptions?.length && setToken1(tokenOptions[0]);
    }
  }, [allOptions, token0, token1]);

  useEffect(() => {
    // Update rates
    const r0 = tokenPairRate(token0.price_in_usd, token1.price_in_usd);
    const r1 = tokenPairRate(token1.price_in_usd, token0.price_in_usd);

    setRate0(r0);
    setRate1(r1);

    updateOptions();
  }, [token0, token1, updateOptions]);

  // handle swap button
  const handleSwapTokenInput = () => {
    const tempToken0 = { ...token0 };
    const tempToken1 = { ...token1 };
    setToken1(tempToken0);
    setToken0(tempToken1);
    updateOptions();
  };

  return {
    rate0,
    rate1,
    token0,
    token1,
    tokenOptions0,
    tokenOptions1,
    handleChangeToken0,
    handleChangeToken1,
    handleSwapTokenInput,
  };
};

export default useTradeTokens;

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

// Only enable Sentry for non prod environments. Also done in  next.config.js
if (process.env.NEXT_PUBLIC_REACT_APP_CONFIG !== 'development') {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://1d0be33508044af0a980911ba217c011@o1115959.ingest.sentry.io/6149058',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: process.env.NEXT_PUBLIC_REACT_APP_CONFIG,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}

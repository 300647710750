import { AppState, Token } from '../../../constants/types';
import useTokenBalance from './useTokenBalance';
import useNativeBalance from './useNativeBalance';

type Props = {
  token0: Token;
  token1: Token;
  state: AppState;
};

const useTradeBalances = ({ token0, token1, state }: Props) => {
  const balance0 = useTokenBalance({ token: token0, state });
  const balance1 = useTokenBalance({ token: token1, state });
  const nativeBalance = useNativeBalance({ state });

  return {
    balance0,
    balance1,
    nativeBalance,
  };
};

export default useTradeBalances;

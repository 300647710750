import React from 'react';
import Slider from 'react-slick';

type Props = {
  children: any;
};
export default function Carousel({ children }: Props) {
  return (
    <Slider
      dots
      infinite={false}
      arrows={false}
      dotsClass="slick-dots slick-thumb"
      appendDots={(dots: any) => (
        <div
          style={{
            padding: '10px',
            marginTop: 55,
          }}
        >
          <ul style={{ margin: '0px' }}> {dots} </ul>
        </div>
      )}
      customPaging={() => (
        <div className="py-2 px-1">
          <div className={`bar w-[30px] h-[2px] bg-[#DDE2EB]`} />
        </div>
      )}
      lazyLoad="ondemand"
    >
      {children}
    </Slider>
  );
}

import { useState, useEffect } from 'react';
import { emptyToken, getTokenOptions } from 'app/utils/tokens';
import { getPreselectedToken } from 'app/common/Withdraw';
import { AppState } from '../../../constants/types';

type Props = {
  defaultToken: string;
  state: AppState;
};

const useWithdrawTokens = ({ defaultToken, state }: Props) => {
  const { allTokens, walletBalances } = state;

  const [tokenOptions, setTokenOptions] = useState([]);
  const [token, setToken] = useState(emptyToken);

  useEffect(() => {
    if (allTokens && walletBalances) {
      const { options } = getTokenOptions(allTokens, walletBalances);
      setTokenOptions(options);

      const { preselected } = getPreselectedToken(options, defaultToken);

      if (!token?.address) setToken(preselected);
    }
  }, [allTokens, walletBalances, defaultToken, token]);

  const handleChangeToken = (value: any) => {
    setToken(value);
  };

  return {
    tokenOptions,
    token,
    handleChangeToken,
  };
};

export default useWithdrawTokens;

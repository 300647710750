import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Card from './Card';

type Props = {
  children?: React.ReactNode;
  onChange?: () => void;
  title?: string;
  subTitle?: string;
  iconSrc: string;
  noTitle?: boolean;
};

const CardDisclosure = ({ children, onChange, title, subTitle, iconSrc, noTitle }: Props) => {
  return (
    <>
      {noTitle ? (
        <>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button as="div" className="flex justify-between w-full">
                  <button
                    onClick={onChange}
                    className="flex justify-between w-full label text-blue10 uppercase hover:text-primaryPressed focus:outline-none"
                  >
                    <span className="flex items-center smallUpper text-xs">
                      <img
                        style={{ imageRendering: 'pixelated' }}
                        className="mb-0.5 mr-2"
                        src={iconSrc}
                        alt=""
                      />
                      {subTitle}
                    </span>
                    <ChevronDownIcon
                      className={`${
                        !onChange && open ? 'transform rotate-180' : ''
                      } w-5 h-5 text-grey40`}
                    />
                  </button>
                </Disclosure.Button>
                {children && (
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-xs text-gray-500">
                    {children}
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </>
      ) : (
        <Card shadow>
          <div className="p-4">
            <h3 className="subtitle2 uppercase">{title}</h3>
            <div className="border-b -mx-4 my-4 border-grey80" />
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button as="div" className="flex justify-between w-full">
                    <button
                      onClick={onChange}
                      className="flex justify-between w-full label text-blue10 uppercase hover:text-primaryPressed focus:outline-none"
                    >
                      <span className="flex items-center smallUpper text-xs">
                        <img
                          style={{ imageRendering: 'pixelated' }}
                          className="mb-0.5 mr-2"
                          src={iconSrc}
                          alt=""
                        />
                        {subTitle}
                      </span>
                      <ChevronDownIcon
                        className={`${
                          !onChange && open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-grey40`}
                      />
                    </button>
                  </Disclosure.Button>
                  {children && (
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      {children}
                    </Disclosure.Panel>
                  )}
                </>
              )}
            </Disclosure>
          </div>
        </Card>
      )}
    </>
  );
};

export default CardDisclosure;

import { Settings, Wallet } from '../constants/types';

export const toggles = [
  {
    key: 'enable_email_notifications',
    label: 'Enable email notifications',
    web: true,
    mobile: true,
  },
  {
    key: 'enable_push_notifications',
    label: 'Enable push notifications',
    web: false,
    mobile: true,
  },
];

export const mobileToggles = toggles.filter((toggle) => toggle.mobile);
export const webToggles = toggles.filter((toggle) => toggle.web);

export const supportEmailURL = 'mailto:support@wonder.fi';

export const getWalletSettings = (stateSettings: Settings, watchlist: Wallet[]) => {
  const settingsMap: any = {};
  for (const [key, value] of Object.entries(stateSettings)) {
    const [, walletId, property] = key.split(':');
    if (watchlist.some((wallet) => wallet.id === walletId)) {
      if (!(walletId in settingsMap)) settingsMap[walletId] = {};
      settingsMap[walletId][property] = value;
    }
  }
  return settingsMap;
};

import React from 'react';
import { classNames } from '../../../app/utils';
import Spinner from './Spinner';

// enum Variant {
//   primary = 'primary',
//   secondary = 'secondary',
//   ghost = 'ghost',
//   link = 'link',
//   destroy = 'destroy',
// }

// enum Shape {
//   circle = 'circle',
//   square = 'square',
// }
//
// enum Size {
//   xs = 'xs',
//   sm = 'sm',
//   md = 'md',
//   lg = 'lg',
// }

type Props = {
  children: React.ReactNode;
  onClick?: (e?: React.MouseEvent) => void;
  disabled?: boolean;
  variant?: string;
  shape?: string;
  size?: string;
  className?: string;
  loading?: boolean;
  full?: boolean;
  outline?: boolean;
};

const Button = ({
  children,
  onClick,
  disabled,
  variant,
  shape,
  size,
  className,
  loading,
  full,
  outline,
}: Props) => {
  return (
    <button
      className={classNames(
        'btn rounded-full focus:ring-0 focus:outline-none',
        outline ? 'btn-outline' : '',
        variant ? `btn-${variant}` : 'btn-primary',
        shape ? `btn-${shape}` : '',
        size ? `btn-${size}` : '',
        full ? `w-full` : '',
        className ? `${className}` : '',
      )}
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading ? <Spinner /> : children}
    </button>
  );
};

export default Button;

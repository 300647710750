import WalletPickerModal from '../Dashboard/WalletPicker/WalletPickerModal';
import AllowDialog from '../Trade/AllowDialog';
import TransactionLoading from '../Trade/TransactionLoading';
import WithdrawForm from './WithdrawForm';
import PendingTrxAlert from '../Shared/Alert/PendingTrxAlert';
import Overlay from '../Shared/Overlay';
import ExternalDialog from '../Trade/ExternalDialog';
import useWithdraw from 'app/common/Withdraw/hooks/useWithdraw';
import useWalletTransaction from '../Trade/hooks/useWalletTransaction';
import { useCallback, useContext, useEffect, useState } from 'react';
import { store } from '../../context/Context';
import { trackEvent } from '../../utils/tracking';
import { useRouter } from 'next/router';

const Withdraw = () => {
  const router = useRouter();
  const { state } = useContext(store);
  const { query } = router;

  const [error, setError] = useState(null);

  const onFinish = useCallback(() => {
    router.push(`/${query.wallet}/${query.network}/reports`);
  }, [router, query]);

  const {
    isWalletConnected,
    externalWalletError,
    openExternal,
    openWalletSwitch,
    handleConnectExternal,
    setOpenWalletSwitch,
    setOpenExternal,
    signMessage,
    sendTransaction,
  } = useWalletTransaction();

  const {
    allowanceValue,
    enableTrx,
    withdrawError,
    ethTrx,
    hasPendingTrx,
    loading,
    openAllowDialog,
    transfer,
    trxParams,
    createEnableTransaction,
    getAllowanceValue,
    getTrxParams,
    handleCancel,
    setHasPendingTrx,
    setOpenAllowDialog,
    submitTransfer,
    buttonText,
    broadcastingText,
  } = useWithdraw({
    state,
    trackEvent,
    signMessage,
    sendTransaction,
    setOpenExternal,
    onFinish,
  });

  useEffect(() => {
    if (withdrawError || externalWalletError) {
      setError(withdrawError || externalWalletError);
    }
  }, [withdrawError, externalWalletError]);

  return (
    <>
      <PendingTrxAlert visible={hasPendingTrx} setVisible={setHasPendingTrx} feature="Withdraw" />
      <div className="relative">
        <WithdrawForm
          allowance={allowanceValue}
          buttonText={buttonText}
          isWalletConnected={isWalletConnected}
          disable={loading || openAllowDialog || !isWalletConnected}
          error={error}
          loading={false}
          trxParams={trxParams}
          handleConnect={handleConnectExternal}
          getAllowanceValue={getAllowanceValue}
          getTrxParams={getTrxParams}
          onSubmit={submitTransfer}
          setError={setError}
        />
        {hasPendingTrx && <Overlay />}
      </div>
      <AllowDialog
        open={Boolean(openAllowDialog && transfer)}
        onAllow={createEnableTransaction}
        onClose={() => setOpenAllowDialog(false)}
        tokenName={transfer?.token?.symbol}
      />
      <ExternalDialog open={openExternal} handleBack={handleCancel} />
      <TransactionLoading trx={ethTrx || enableTrx} title={broadcastingText} />
      <WalletPickerModal open={openWalletSwitch} onClose={() => setOpenWalletSwitch(false)} />
    </>
  );
};

export default Withdraw;

import { classNames } from '../../../app/utils';

type Props = {
  children: any;
  padding?: string;
  shadow?: boolean;
  className?: string;
};

const Card = ({ children, shadow, className }: Props) => {
  return (
    <>
      <div
        className={classNames(
          `bg-white lg:min-w-0 xl:flex-1 rounded-xl `,
          shadow ? `shadow-light ` : '',
          className ? className : '',
        )}
      >
        {children}
      </div>
    </>
  );
};
export default Card;

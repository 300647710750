import { getPromotions, postPromotions, referralCodeObject } from 'app/common/Referral';
import { referralMessages } from 'app/constants';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { store } from '../../../../context/Context';
import { copyToClipboard } from '../../../../utils';

export default function useReferral() {
  const {
    state: { profile, user },
  } = useContext(store);
  const [welcomeReferral, setWelcomeReferral] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promotionLoading, setPromotionLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [userPromotionMsg, setUserPromotionMsg] = useState(null);
  const [successCopy, setSuccessCopy] = useState(false);
  const [copyAlert, setCopyAlert] = useState('');
  const [referral, setReferral] = useState('');
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage('');
      setCopyAlert('');
      setError(false);
      setSuccess(false);
      setSuccessCopy(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [message, copyAlert]);

  const handleReferral = (e: any) => {
    setReferral(e.target.value);
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    async function getallpromotionsForSettings() {
      try {
        setPromotionLoading(true);
        const userPromotion = await getPromotions(user);
        setWelcomeReferral(userPromotion?.unique_campaign_identifier);
        setUserPromotionMsg(userPromotion?.message);
        setPromotionLoading(false);
      } catch (error) {
        setError(true);
        setMessage(referralMessages.GET_PROMOTION_ERROR);
      }
    }
    getallpromotionsForSettings();
  }, [user, setMessage]);

  const addReferralCode = async () => {
    if (!user) {
      return;
    }
    const referralCode = referralCodeObject(profile.id, referral);
    setLoading(true);
    try {
      const res = await postPromotions(user, referralCode);
      if (!res) {
        setError(true);
        setMessage(referralMessages.REFERRAL_NOT_VALID);
        setLoading(false);
      } else {
        setMessage(referralMessages.REFERRAL_SUCCESSFULLY_REDEEMED);
        setSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      setError(true);
      setMessage(referralMessages.REFERRAL_CODE_ERROR);
      setLoading(false);
    }
  };

  const copyReferralCode = () => {
    setSuccessCopy(true);
    setCopyAlert('Promotion code copied to clipboard');
    copyToClipboard(welcomeReferral);
  };

  return {
    welcomeReferral,
    setWelcomeReferral,
    error,
    setError,
    loading,
    setLoading,
    promotionLoading,
    setPromotionLoading,
    success,
    setSuccess,
    message,
    setMessage,
    userPromotionMsg,
    setUserPromotionMsg,
    successCopy,
    setSuccessCopy,
    copyAlert,
    setCopyAlert,
    referral,
    handleReferral,
    isHidden,
    setIsHidden,
    addReferralCode,
    copyReferralCode,
  };
}

import { Menu, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { classNames } from 'app/utils';

type Props = {
  options: Array<any>;
  renderOptions: (props: any) => JSX.Element;
  renderExtraOptions?: () => ReactNode;
  renderValue?: () => JSX.Element;
  value?: string;
  controlled?: boolean;
  open?: boolean;
  renderAdditionalOption?: (props: any) => JSX.Element;
  menuItemsClassName?: string;
  menuClassName?: string;
  menuButtonClassName?: string;
  menuButtonBackgroundColor?: string;
};

export default function Dropdown({
  value,
  renderOptions,
  options,
  renderValue,
  controlled = false,
  open = false,
  renderAdditionalOption,
  menuItemsClassName = '',
  menuClassName = '',
  menuButtonClassName = '',
  menuButtonBackgroundColor = '',
}: Props) {
  return (
    <Menu
      as="div"
      className={classNames(menuClassName, 'relative inline-block text-right w-full ')}
    >
      <Menu.Button
        className="w-full text-button font-medium text-blue10 rounded-lg uppercase hover:opacity-75
        focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-100 focus-visible:ring-black"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
      >
        <div
          className={classNames(
            menuButtonClassName,
            menuButtonBackgroundColor ? `bg-${menuButtonBackgroundColor}` : 'bg-blue80',
            'w-full px-4 py-2 text-blue10 smallUpper rounded-lg flex items-center justify-between',
          )}
        >
          <span className="truncate mr-0 md:mr-5 w-full">
            {renderValue ? renderValue() : value}
          </span>
          <img src="/images/DropdownTriangle.png" alt="" width={8} />
        </div>
      </Menu.Button>

      <Transition
        show={controlled ? open : undefined}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          static={controlled}
          className={`focus:outline-none absolute left-0 w-full origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-10 max-h-80 overflow-auto p-[7px] mt-2 ${menuItemsClassName}`}
        >
          {options?.map((option, index) => {
            return (
              <Menu.Item key={option?.id ?? index}>
                {({ active }) => renderOptions({ option, active })}
              </Menu.Item>
            );
          })}
          {renderAdditionalOption && (
            <Menu.Item>{({ active }) => renderAdditionalOption({ active })}</Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

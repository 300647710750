import { getWalletPerformanceHistory, updateWalletBalance } from '../../utils/api';
import { Currency, Dispatch, Network, User, Wallet } from '../../constants/types';
import { poll } from '../../utils/helpers';
import { filterBalances } from '../../utils/tokens';
import { formatMoney, formatPercentage } from '../../utils';

let res: any;
export const getHistoricalPerformance = async (
  user: User,
  network: Network,
  wallet: Wallet,
  interval: string,
) => {
  try {
    res = await getWalletPerformanceHistory(user, network, wallet.id, interval);
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const getUpdatedHistoricalPerformance = async (
  user: User,
  network: Network,
  wallet: Wallet,
  interval: string,
) => {
  try {
    res = await poll({
      fn: async () => {
        return getWalletPerformanceHistory(user, network, wallet.id, interval);
      },
      validate: (res) => res.updating === false,
    });
  } catch (err) {
    console.error(err);
  }
};

export const getRate = (
  currency: Currency,
  historicPrices: [number, number][],
  timestamp: number,
) => {
  if (historicPrices && historicPrices.length) {
    let current = null;
    for (let i = 0; i < historicPrices.length; i++) {
      const potential = historicPrices[i];
      if (!current) {
        current = potential;
      } else {
        let diff1 = current[0] - timestamp;
        if (diff1 < 0) {
          diff1 *= -1;
        }
        let diff2 = potential[0] - timestamp;
        if (diff2 < 0) {
          diff2 *= -1;
        }
        if (diff2 < diff1) {
          current = potential;
        }
      }
    }
    if (current) {
      return current[1];
    }
  }

  return currency ? currency.rate : 1;
};
export const getChartHistoricalPerformance = (
  res: any,
  currency: Currency,
  historicPrices: [number, number][],
) => {
  const mainPerformance = res && res.historical_balances_usd;
  const _performance =
    mainPerformance &&
    Object.keys(mainPerformance).map((key) => ({
      x: Number(key) * 1000,
      y: mainPerformance[key] * getRate(currency, historicPrices, Number(key) * 1000),
      rate: getRate(currency, historicPrices, Number(key) * 1000),
    }));
  return _performance;
};

export const getPieChartPerformance = (
  primaryAccounts: any,
  allTokens: any,
  walletBalances: any,
) => {
  const res = primaryAccounts.map((account: any) => {
    const sum = filterBalances(allTokens, Object.values(walletBalances), account.tags[0])
      .filter((balance: any) => balance.token_address !== '') // Filter out empty
      .reduce((a: number, balanceB: any) => a + balanceB.balance_usd, 0);

    return {
      name: account.name,
      tag: account.tags[0],
      sum_usd: sum,
    };
  });

  //Parse for graph
  const data = res.map((accountPerformace: any) => {
    return {
      x: accountPerformace.name,
      y: parseFloat(accountPerformace.sum_usd.toFixed(2)),
    };
  });
  return data;
};

export const getTotalWalletBalance = (walletBalances: any) => {
  const balances = Object.values(walletBalances).map((token: any) => token.balance_usd);

  const totalWalletBalance: any = balances.reduce(
    (total: any, usdBalance: any) => total + usdBalance,
    0,
  );
  return totalWalletBalance;
};

export const hasWalletBalance = (walletBalances: any) => {
  const total = walletBalances ? getTotalWalletBalance(walletBalances) : 0;
  return total > 0;
};

export const getChangeBalance = (
  totalBalance: number,
  initialBalance: number,
  currency: Currency,
) => {
  return (
    (totalBalance >= initialBalance ? 'Up ' : 'Down ') +
    formatMoney(Math.abs(totalBalance - initialBalance), currency)
  );
};

export const getPieChartBreakdownPercentage = (account: number, sum: number) => {
  return account === 0 ? '0%' : formatPercentage((100 * (account || 1)) / sum, 1);
};

export const refreshWalletBalances = async (
  user: User,
  network: Network,
  wallet: Wallet,
  dispatch: Dispatch,
) => {
  try {
    const updateBalances = await updateWalletBalance(user, network, wallet.id);
    dispatch({
      type: 'update',
      key: 'walletBalances',
      value: updateBalances.data,
    });
  } catch (e) {
    console.error(e);
  }
};

import Button from '../../Shared/Button';
import { useCallback, useEffect } from 'react';
import { triggerVerifyEmail } from '../../../utils/api';
import {
  trackEvent,
  keys,
  trackGAEvent,
  trackFBQ,
  trackIREConversion,
  trackIoCVS,
} from '../../../utils/tracking';
import { useAuth0 } from '@auth0/auth0-react';
import Meta from '../../Shared/Meta';

type Props = {
  email: string;
};

const VerifyEmail = ({ email }: Props) => {
  const { logout, user } = useAuth0();

  const onClickResend = useCallback(() => {
    triggerVerifyEmail(email);
  }, [email]);

  useEffect(() => {
    if (user?.email) {
      // @ts-ignore
      trackEvent(keys.USER_SIGNUP, { userInfo: { email: user.email } });
      trackGAEvent({
        category: 'App',
        action: 'Signup',
        label: 'Confirmed',
        value: 37,
      });
      trackFBQ('Lead');
      trackIREConversion({
        orderId: user.sub || '',
        customerId: user.sub || '', // TODO Use user profile id instead
        customerEmail: user.email,
      });
      trackIoCVS('conversion');
    }
  }, [user]);

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <>
      <Meta title="Verify Email" />
      <div className="bg-blue80 flex justify-center items-center h-screen w-screen">
        <div className="bg-white w-[70%] max-w-[1240px] flex flex-col items-center py-[100px] px-[60px] rounded-[12px]">
          <div className="h-full flex flex-col justify-center">
            <p className="mb-10 text-center h1 text-blue10">
              Check your email for verification link
            </p>
            <img className="mx-auto max-h-72 mb-10" src="/images/verify-email-logo.png" alt="" />
            {email ? (
              <p className="mb-8 text-center body1 text-blue10">
                A link has been sent to
                <br />
                <span className="subtitle2 text-[18px]">{email}</span>
              </p>
            ) : (
              <p className="mb-8 text-center body1 text-blue10">
                A link has been sent your email address
              </p>
            )}
            <p className="mb-6 text-center body1 text-blue10">
              Please check your email for the verification link.
              <br />
              Don&apos;t forget to check your spam folder.
            </p>
            <Button variant="link" onClick={onClickResend}>
              <div className="text-primary text-center">Resend Link</div>
            </Button>
            <p className="mb-6 text-center body1 text-blue10">
              Need to login into another? Click the logout button below
            </p>
            <Button className="w-full sm:w-10/12 mx-auto" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default VerifyEmail;

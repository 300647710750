import { ethers } from 'ethers';
import { validationErrors } from '../../constants';
import {
  Network,
  Token,
  Transaction,
  Transfer,
  User,
  Wallet,
  Currency,
} from '../../constants/types';
import { getHexString, isValidAmount, isValidAmountBN, isZeroBN, parseAmount } from '../../utils';
import {
  approveSpendTrx,
  estimateTransferGas,
  getAllowance,
  getProviderGasPrice,
} from '../../utils/eth';
import { getWonderFiSigner } from '../../utils/torus';
import { isNativeToken } from '../../utils/tokens';
import { getGasQuote } from '../../utils/api';
import { inputUnits } from '../Trade';

export const createWithdrawEnableTransaction = async (
  transfer: Transfer,
  wallet: Wallet,
  user: User,
  network: Network,
  sendTransactionFn: (
    wallet: Wallet,
    transaction: Transaction,
  ) => Promise<ethers.providers.TransactionResponse>,
) => {
  try {
    const amount = ethers.constants.MaxUint256;

    let signer = undefined;
    if (wallet.source === 'torus') {
      signer = getWonderFiSigner(user, network);
    }
    const unsignedTrx = await approveSpendTrx(
      transfer.token.address,
      network?.withdraw_address,
      amount,
      network,
      signer,
    );

    // get gas price
    const gasPrice = await getProviderGasPrice(network);

    const trx = await sendTransactionFn(wallet, {
      from: wallet.address,
      to: unsignedTrx.to,
      data: unsignedTrx.data,
      gasPrice: gasPrice.toHexString(),
      value: getHexString('0'),
    });

    return { trx };
  } catch (e: any) {
    return { sendTrxError: e };
  }
};

export const handleWithdrawError = (e: any, defaultMessage: string) => {
  let message;

  const errorMsg = e?.message || e?.msg;

  if (errorMsg?.toUpperCase && errorMsg.toUpperCase().includes('INSUFFICIENT FUNDS')) {
    message = validationErrors.INSUFFICIENT_BALANCE_TO_COVER_FEE;
  } else if (errorMsg?.toUpperCase && errorMsg.toUpperCase().includes('NETWORK_ERROR')) {
    message = validationErrors.NETWORK_CONNECTION_FAILED;
  } else if (errorMsg?.length < 100) {
    message = errorMsg;
  } else {
    message = defaultMessage;
  }

  console.error(e);
  return message;
};

export const getPreselectedToken = (options: Token[], defaultToken: string) => {
  let preselected;
  const default0 = defaultToken && options.find((t) => t.ID === defaultToken);

  if (default0) {
    preselected = default0;
  } else {
    if (options.length > 0) preselected = options[0];
  }

  return { preselected };
};

export const withdrawValidations = {
  isViewedOnlyWallet: (wallet: Wallet) => wallet.source === 'watchlist',

  hasMissingFields: (input: string, token: Token) => !input || !token?.address,

  hasInvalidAmounts: (input: string, amount: string) =>
    !isValidAmount(input) || !isValidAmountBN(amount),

  hasInsufficientBalance: (balance: string, amount: string) =>
    ethers.BigNumber.from(balance).lt(ethers.BigNumber.from(amount)),

  hasSmallReceiveAmount: (amount: string, minReceive: number) =>
    !isZeroBN(amount) && minReceive <= 0,
};

export const getCommissionFee = async (
  input: string,
  token: Token,
  network: Network,
  ethPrice: number,
) => {
  let commission = 0;

  try {
    const gasPrice = await getGasQuote(network);
    const withdrawFeeEth = gasPrice?.current_gas?.withdraw_fee || 0;
    const withdrawFeeUsd = withdrawFeeEth * ethPrice;
    if (isValidAmount(input) && !isNativeToken(token, network) && token?.price_in_usd) {
      commission = withdrawFeeUsd / token.price_in_usd;
    }
  } catch (e) {
    console.error(e);
  }

  const commissionBN = parseAmount(commission.toString(), token.decimals);
  return { commission, commissionBN };
};

export const getMinReceive = (
  input: any,
  commission: number,
  token: Token,
  currency: Currency,
  unit: string,
) => {
  let receive;
  if (unit === inputUnits.fiat.id) {
    receive = Number(input) / (token.price_in_usd * currency.rate) - commission;
  } else {
    receive = Number(input) - commission;
  }
  if (receive > 0) return receive;
  else return 0;
};

export const getNetworkFee = (
  trxParams: Transaction,
  allowanceFee: string,
  token: Token,
  network: Network,
) => {
  if (isNativeToken(token, network) && trxParams?.gasPrice && trxParams?.gasLimit) {
    const limit = ethers.BigNumber.from(trxParams.gasLimit);
    const price = ethers.BigNumber.from(trxParams.gasPrice);
    const gasFee = limit.mul(price).toString();
    return gasFee;
  } else if (allowanceFee) {
    return allowanceFee;
  } else {
    return '0';
  }
};

export const _getTrxParams = async (
  user: User,
  network: Network,
  transfer: Transfer,
  wallet: Wallet,
) => {
  try {
    const gasPrice = await getProviderGasPrice(network);
    const signer = getWonderFiSigner(user, network);
    const gasLimit = await estimateTransferGas(
      signer,
      wallet.address,
      transfer.to,
      transfer.amount,
    );

    return {
      from: wallet.address,
      to: transfer.to,
      value: getHexString(transfer.amount),
      gasLimit: gasLimit.toHexString(),
      gasPrice: gasPrice.toHexString(),
    };
  } catch {
    return null;
  }
};

export const _getAllowanceValue = async (network: Network, token: Token, wallet: Wallet) => {
  try {
    const allowance = await getAllowance(wallet, token.address, network?.withdraw_address, network);

    return { allowance };
  } catch (e: any) {
    return { allowanceError: e, allowance: '0' };
  }
};

export const canUpdateTransferParams = (amount: string, isValidTo: boolean) => {
  return isValidAmountBN(amount) && isValidTo;
};

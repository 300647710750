import { useState, useEffect } from 'react';
import { AppState, Quote, Token } from '../../../constants/types';
import { getUpdatedInput, getUpdatedAmount, getMaxInput } from 'app/common/Trade';
import useOrderDetails from './useOrderDetails';
import { inputUnits } from 'app/common/Trade';

type Props = {
  token0: Token;
  token1: Token;
  rate0: number;
  rate1: number;
  balance0: string;
  quote: Quote;
  allowance: string;
  state: AppState;
};

const useTradeInputs = ({
  token0,
  token1,
  rate0,
  rate1,
  balance0,
  quote,
  allowance,
  state,
}: Props) => {
  const { wallet, network, currency } = state;

  const [amount0, setAmount0] = useState('0');
  const [amount1, setAmount1] = useState('0');
  const [input0, setInput0] = useState('');
  const [input1, setInput1] = useState('');
  const [currentUnit, setCurrentUnit] = useState(inputUnits.token.id);

  const { networkFee } = useOrderDetails({
    allowance,
    quote,
    order: { amount0, amount1, token0, token1 },
    state,
  });

  // clear inputs when token0 or wallet changes
  useEffect(() => {
    setInput1('');
    setInput0('');
    setAmount0('0');
    setAmount1('0');
  }, [token0, wallet, currentUnit]);

  // update input1 when token1 changes and input0 is not empty
  useEffect(() => {
    const isToken = currentUnit === inputUnits.token.id;
    if (isToken) {
      setInput1(getUpdatedInput(input0, rate0, token1, currentUnit));
    }
  }, [rate0, token1, input0, currentUnit]);

  useEffect(() => {
    setAmount1(quote?.buyAmount || '0');
  }, [quote]);

  const handleChangeInput0 = (value: any) => {
    setInput0(value);
    setInput1(getUpdatedInput(value, rate0, token1, currentUnit));
    setAmount0(getUpdatedAmount(value, token0, currency, currentUnit));
  };

  const handleChangeInput1 = (value: any) => {
    setInput1(value);
    setInput0(getUpdatedInput(value, rate1, token0, currentUnit));
    setAmount0(getUpdatedAmount(value, token0, currency, currentUnit));
  };

  const handleMax = () => {
    handleChangeInput0(getMaxInput(token0, balance0, networkFee, network, currency, currentUnit));
  };

  const handleInputUnit = (selectedUnit: string) => {
    setCurrentUnit(selectedUnit);
  };

  return {
    amount0,
    amount1,
    input0,
    input1,
    handleChangeInput0,
    handleChangeInput1,
    handleMax,
    currentUnit,
    handleInputUnit,
  };
};

export default useTradeInputs;

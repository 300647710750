import { ethers } from 'ethers';
import { Dispatch, Network, User, Wallet } from '../../constants/types';
import {
  addUserWallet,
  deleteUserWallet,
  getUserSettings,
  getUserWallets,
  updateUserWallet,
  updateWalletBalance,
} from '../../utils/api';
import { keys } from 'app/constants/tracking';

export const checkAddress = async (address: string) => {
  let validationError = '';
  let isValid = false;
  try {
    const isValidAddress = ethers.utils.isAddress(address);
    if (isValidAddress) {
      isValid = true;
    } else {
      validationError = 'Please enter a valid address';
    }
  } catch (e) {
    validationError = 'Address could not be validated';
  }
  return { isValid, validationError };
};

export const addItemToWatchlist = async (
  item: {
    address: string;
    name: string;
    source: string;
    icon_url?: string;
  },
  watchlist: Wallet[],
  user: User,
  network: Network,
  dispatch: Dispatch,
  trackEvent: (key: string, user: any, _attributes: any) => void,
) => {
  let validationError = '';

  if (watchlist.some((wallet) => wallet.address.toLowerCase() === item.address.toLowerCase())) {
    validationError = `This address is already in your account:
    ${item.address}`;

    return { validationError };
  }

  if (item.name !== '' && watchlist.some((wallet) => wallet.name === item.name)) {
    validationError = `This name is already in your account:
      ${item.name}`;
    return { validationError };
  }

  try {
    await addUserWallet(user, network, item);
    const newWatchlist = await getUserWallets(user, network);

    const wallet = newWatchlist.find(
      (wallet: Wallet) => wallet.address.toLowerCase() === item.address.toLowerCase(),
    );

    if (wallet) {
      const updateBalances = await updateWalletBalance(user, network, wallet.id);
      const userSettings = await getUserSettings(user, network);

      dispatch({
        type: 'update',
        key: 'watchlist',
        value: newWatchlist,
      });

      dispatch({
        type: 'update',
        key: 'wallet',
        value: wallet,
      });

      dispatch({
        type: 'update',
        key: 'walletBalances',
        value: updateBalances.data,
      });

      if (userSettings) {
        dispatch({
          type: 'update',
          key: 'settings',
          value: userSettings,
        });
      }
    }

    trackEvent(keys.WALLET_ADDED_SUCCESSFULLY, user, {
      address: item.address,
      source: item.source,
    });

    trackEvent(keys.NUMBER_OF_WALLETS, user, {
      numberOfWallets: newWatchlist.length,
    });

    return { validationError };
  } catch (e) {
    console.error(e);
    validationError = 'Wallet could not be added';
    return { validationError };
  }
};

export const editWallet = async (
  newWallet: Wallet,
  user: User,
  network: Network,
  stateWallet: Wallet,
  dispatch: Dispatch,
) => {
  try {
    await updateUserWallet(user, network, newWallet.id, {
      address: newWallet.address,
      name: newWallet.name,
      source: newWallet.source,
    });

    const newWatchlist = await getUserWallets(user, network);
    dispatch({
      type: 'update',
      key: 'watchlist',
      value: newWatchlist,
    });

    // Update currently selected wallet data, if currently selected wallet was edited
    if (newWallet.id === stateWallet.id) {
      const newWalletData = newWatchlist.find((wallet: Wallet) => wallet.id === newWallet.id);
      dispatch({
        type: 'update',
        key: 'wallet',
        value: newWalletData,
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const deleteWallet = async (
  wallet: Wallet,
  user: User,
  network: Network,
  stateWallet: Wallet,
  dispatch: Dispatch,
  trackEvent: (key: string, user: any, _attributes: any) => void,
) => {
  try {
    await deleteUserWallet(user, network, wallet?.id);
    const newWatchlist = await getUserWallets(user, network);
    dispatch({
      type: 'update',
      key: 'watchlist',
      value: newWatchlist,
    });

    trackEvent(keys.WALLET_DELETED, user, {
      address: wallet.address,
      source: wallet.source,
    });

    trackEvent(keys.NUMBER_OF_WALLETS, user, {
      numberOfWallets: newWatchlist.length,
    });

    // Switch back to first wallet, if currently selected wallet was deleted
    if (stateWallet?.id === wallet?.id) {
      dispatch({
        type: 'update',
        key: 'wallet',
        value: newWatchlist[0],
      });
    }
  } catch (e) {
    console.error(e);
  }
};

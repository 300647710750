import Link from 'next/link';
import Button from '../../components/Shared/Button';
import { wonderfiCopyRight } from 'app/constants';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';

export default function EmailVerified() {
  const router = useRouter();

  const { loginWithRedirect, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  useEffect(() => {
    (async () => {
      try {
        await getAccessTokenSilently();
        const claims = await getIdTokenClaims();
        if (claims?.email_verified) {
          router.push('/');
        }
      } catch (e: any) {
        loginWithRedirect({
          scope: 'openid profile email offline_access',
          redirectUri: window.location.origin,
        });
      }
    })();
  }, [getAccessTokenSilently, getIdTokenClaims, loginWithRedirect, router]);

  return (
    <div className="min-h-screen pt-16 pb-12 flex flex-col bg-gradient-to-br from-pink-800 via-purple-800 to-purple-900">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <img src="/email/logo.png" alt="logo" />
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-white text-4xl sm:text-5xl font-semibold tracking-wide">
              Welcome to WonderFi
            </p>
            <p className="mt-6 text-base text-white">
              Did you verify your email? Please click the button below to
              <br />
              login with email.
            </p>
            <Link href="/">
              <a>
                <Button className="mt-20 px-32 py-5" size="large">
                  Login
                </Button>
              </a>
            </Link>
            <div className="flex gap-x-6 justify-center items-center mt-20">
              <a
                href="https://twitter.com/wonderfi"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white font-medium underline"
              >
                <img src="/email/twitter.png" alt="twitter" />
              </a>
              <a
                href="https://t.me/wonder_fi"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white font-medium underline"
              >
                <img src="/email/telegram.png" alt="telegram" />
              </a>
              <a
                href="https://ca.linkedin.com/company/wonderfi"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white font-medium underline"
              >
                <img src="/email/linkedin.png" alt="linkedin" />
              </a>
            </div>
            <div className="text-white mt-20">
              <p>{wonderfiCopyRight}</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

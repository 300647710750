import { useRouter } from 'next/router';
import Link from 'next/link';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import CollectionList from './CollectionList';
import useNFTData from '../../../../app/common/NFT/hooks/useNFTData';
import { store } from '../../../context/Context';
import { useContext } from 'react';

const NFTSidebar = () => {
  const {
    state: { user, wallet: stateWallet, network: stateNetwork },
  } = useContext(store);
  const { nfts, loading } = useNFTData(user, stateWallet, stateNetwork);

  const router = useRouter();
  const {
    query: { wallet, network },
  } = router;

  return (
    <>
      <Link passHref href={`/${wallet}/${network}/nft/`}>
        <a className="btn-ghost round p-0 flex items-center ">
          <ArrowLeftIcon className="h-6 w-6 text-blue10 mr-4" />
          <div className="h2 text-blue10 normal-case">Back</div>
        </a>
      </Link>
      <CollectionList nfts={nfts} loading={loading} />
    </>
  );
};

export default NFTSidebar;

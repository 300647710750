type Props = {
  label: string;
};

const TokenInputLabel = ({ label }: Props) => {
  return (
    <div className="flex items-center ">
      <span className="bodySmall2 text-grey20 mr-2">{label}</span>
    </div>
  );
};

export default TokenInputLabel;

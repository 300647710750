import React, { createContext, useReducer, useEffect, useCallback, useState } from 'react';
import { getData, setData } from '../utils/storage';
import { Action } from '../constants/types';
import { idTokenToPrivateKey } from '../utils/torus';
import { initialState, reducer } from '../../app/context/reducer';
import { useRouter } from 'next/router';
import {
  fetchAllData,
  fetchCurrencies,
  fetchNetworks,
  fetchWalletBalances,
  loadUser,
  onNetworkChange,
  updateLocalStore,
} from '../../app/context/middleware';
import { useAuth0 } from '@auth0/auth0-react';
import VerifyEmail from '../components/Dashboard/Welcome/VerifyEmail';
import DashboardWrapper from '../components/Dashboard/DashboardWrapper';
import config from '../config';
import { MetaProps } from '../components/Shared/Meta';

const store = createContext({ state: initialState, dispatch: null });

const { Provider } = store;

type Props = {
  children: React.ReactNode;
  meta: MetaProps;
};
const StateProvider = ({ children, meta }: Props): JSX.Element => {
  const router = useRouter();
  const {
    query: { wallet, network },
  } = router;

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();

  const [loaded, setLoaded] = useState(false);
  const [emailVerified, setEmailVerified] = useState(true);
  const [tokenClaims, setTokenClaims] = useState(null);

  const onPageLoad = useCallback(async () => {
    // @ts-ignore
    const now = parseInt(Date.now() / 1000, 10);
    if (
      state?.user?.privateKey &&
      state?.user?.userInfo?.exp &&
      state.user.userInfo.exp > now + 600
    ) {
      return;
    }
    if (!isAuthenticated) {
      return;
    }

    try {
      await getAccessTokenSilently(); //Gets new id and access token if old is expired
    } catch (e: any) {
      const error = e?.error;
      if (error === 'login_required' || error === 'consent_required') {
        loginWithRedirect({
          scope: 'openid profile email offline_access',
          redirectUri: window.location.origin,
        });
        return;
      }
    }

    const claims = await getIdTokenClaims();

    if (!claims) {
      return;
    }

    if (!claims?.email_verified) {
      setEmailVerified(false);
      setTokenClaims(claims);
      return;
    }

    const privateKey = await idTokenToPrivateKey(claims.__raw);
    if (!privateKey) {
      loginWithRedirect({
        scope: 'openid profile email offline_access',
        redirectUri: window.location.origin,
      });
      return;
    }
    const user = {
      privateKey,
      userInfo: {
        idToken: claims.__raw,
        ...claims,
      },
    };
    setData('user', user);
    loadUser(getData, dispatch);
  }, [isAuthenticated, loginWithRedirect, getIdTokenClaims, state.user, getAccessTokenSilently]);

  useEffect(() => {
    (async () => {
      if (loaded) {
        return;
      }
      // check if we have valid data in localstorage first
      const storedUser = await getData('user');
      // @ts-ignore
      const now = parseInt(Date.now() / 1000, 10);
      if (
        storedUser?.privateKey &&
        storedUser?.userInfo?.exp &&
        storedUser.userInfo.exp > now + 600
      ) {
        setLoaded(true);
        loadUser(getData, dispatch);
        return;
      }
      if (isLoading) {
        return;
      }
      setLoaded(true);
      onPageLoad();
    })();
  }, [isLoading, onPageLoad, loaded]);

  useEffect(() => {
    const interval = setInterval(onPageLoad, 10000);
    return () => clearInterval(interval);
  }, [onPageLoad]);

  const fetchAll = useCallback(() => {
    fetchAllData(
      {
        user: state.user,
        wallet: state.wallet,
        network: state.network,
        walletIdOrAddress: wallet ? wallet.toString() : '',
      },
      config().idVerificationEnabled,
      dispatch,
    );
  }, [state.user, state.wallet, state.network, wallet]);

  useEffect(() => {
    fetchAll();
    const interval = setInterval(fetchAll, 120 * 1000);
    return () => clearInterval(interval);
  }, [fetchAll]);

  useEffect(() => {
    onNetworkChange({ network: state.network }, dispatch);
  }, [state.network]);

  useEffect(() => {
    fetchNetworks({ network: network ? network.toString() : '' }, dispatch);
  }, [network]);

  useEffect(() => {
    fetchCurrencies(dispatch);
  }, []);

  const dispatchWithStore = async (event: Action) => {
    await updateLocalStore(setData, event);
    dispatch(event);
  };

  const fetchBalances = useCallback(() => {
    fetchWalletBalances(
      { user: state.user, wallet: state.wallet, network: state.network },
      dispatch,
    );
  }, [state.wallet, state.user, state.network]);

  useEffect(() => {
    fetchBalances();
    const interval = setInterval(fetchBalances, 120 * 1000);
    return () => clearInterval(interval);
  }, [fetchBalances]);

  if (!emailVerified) {
    return <VerifyEmail email={tokenClaims?.email} />;
  }

  return (
    <Provider value={{ state, dispatch: dispatchWithStore }}>
      <DashboardWrapper meta={meta}>{children}</DashboardWrapper>
    </Provider>
  );
};

export { store, StateProvider };

import { useEffect, useState } from 'react';
import { getNativeToken } from '../../../utils/tokens';
import { Token, Network } from '../../../constants/types';
type Props = {
  allTokens: Token[];
  network: Network;
};

export default function useNativeToken({ allTokens, network }: Props) {
  const token = getNativeToken(allTokens, network);
  const [nativeToken, setNativeToken] = useState(token);

  useEffect(() => {
    const token = getNativeToken(allTokens, network);
    if (token) {
      setNativeToken(token);
    }
  }, [allTokens, network]);

  return nativeToken;
}

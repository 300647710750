import React from 'react';
import { EyeIcon, GlobeAltIcon } from '@heroicons/react/solid';
import { Wallet } from '../../constants/types';
import wallets from '../../../app/constants/wallets';

type Props = {
  wallet: Wallet;
  className?: string;
  style?: any;
};

type WalletIconProps = {
  Icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
  className?: string;
  style?: any;
};

const WalletIcon = ({ Icon, className, style }: WalletIconProps) => {
  return (
    <div className="flex items-center justify-center">
      <Icon className={`h-8 w-8 text-grey40 ${className}`} style={style} aria-hidden="true" />
    </div>
  );
};

export default function WalletImage({ wallet, className = '', style = {} }: Props) {
  if (wallet?.source === 'watchlist') {
    return <WalletIcon Icon={EyeIcon} className={className} style={style} />;
  }

  if (!wallet?.icon_url && wallet?.source === 'browser') {
    return <WalletIcon Icon={GlobeAltIcon} className={className} style={style} />;
  }

  return (
    <img
      className={`h-8 w-8 rounded-full ${className}`}
      style={style}
      src={wallet?.icon_url || wallets[wallet?.source]?.image}
      alt=""
    />
  );
}

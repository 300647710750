import { AppState, Transaction, Transfer, Wallet } from '../../../constants/types';

import { createWithdrawEnableTransaction, handleWithdrawError } from 'app/common/Withdraw';
import useApprovalTrx from '../../Trade/hooks/useApprovalTrx';

type Props = {
  state: AppState;
  onTransactionSent?: () => void;
  transfer?: Transfer;
  setOpenExternal?: (open: boolean) => void;
  sendTransaction: (wallet: Wallet, params: Transaction) => Promise<any>;
  trackEvent: (key: string, user: any, _attributes: any) => void;
};

const useWithdrawApproval = ({
  state,
  transfer,
  onTransactionSent,
  setOpenExternal,
  sendTransaction,
  trackEvent,
}: Props) => {
  const { user, wallet, network } = state;

  const {
    allowanceValue,
    checkingAllowance,
    enablingToken,
    enableTrx,
    approvalError,
    openAllowDialog,
    getAllowanceValue,
    setApprovalError,
    setEnableTrx,
    setOpenAllowDialog,
    setEnablingToken,
  } = useApprovalTrx({
    state,
    token: transfer?.token,
    trackEvent,
    onTransactionSent,
    setOpenExternal,
  });

  const createEnableTransaction = async () => {
    setEnablingToken(true);
    const { trx, sendTrxError } = await createWithdrawEnableTransaction(
      transfer,
      wallet,
      user,
      network,
      sendTransaction,
    );

    if (sendTrxError) {
      setEnablingToken(false);
      setApprovalError(
        handleWithdrawError(
          sendTrxError,
          `We were unable to change the allowance of your ${transfer?.token?.symbol || 'token'}`,
        ),
      );
      return;
    }

    if (trx && trx.hash) setEnableTrx(trx);
    else setEnableTrx({ hash: trx });
  };

  return {
    allowanceValue,
    checkingAllowance,
    enablingToken,
    enableTrx,
    approvalError,
    openAllowDialog,
    getAllowanceValue,
    setOpenAllowDialog,
    createEnableTransaction,
  };
};

export default useWithdrawApproval;

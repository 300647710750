import { useState } from 'react';
import { alertMessages } from '../../../../app/constants';
import NetworkPickerModal from '../../Dashboard/NetworkPicker/NetworkPickerModal';
import InfoAlert from './InfoAlert';

type Props = {
  visible: boolean;
  feature?: string;
};

const NetworkAlert = ({ visible, feature }: Props) => {
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  if (!visible) return null;

  return (
    <>
      <InfoAlert
        message={alertMessages.networkAlert.message(feature)}
        onClick={() => setOpenNetworkSwitch(true)}
        action={alertMessages.networkAlert.action}
      />
      <NetworkPickerModal open={openNetworkSwitch} onClose={() => setOpenNetworkSwitch(false)} />
    </>
  );
};

export default NetworkAlert;

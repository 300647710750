import { useState, useEffect } from 'react';
import { AppState, Token } from '../../../constants/types';
import { getAllowanceFee, hasAllowanceFee } from 'app/common/Trade';
import { getCommissionFee, getMinReceive, getNetworkFee } from 'app/common/Withdraw';
import { useCallback } from 'react';

type Props = {
  allowance: string;
  trxParams: any;
  amount: string;
  token: Token;
  input: string;
  state: AppState;
  currentUnit: string;
};

const useOrderDetails = ({
  allowance,
  amount,
  token,
  trxParams,
  input,
  state,
  currentUnit,
}: Props) => {
  const { user, network, ethPrice, currency } = state;

  const [totalReceive, setTotalReceive] = useState(0);
  const [commissionFee, setCommissionFee] = useState('0');
  const [networkFee, setNetworkFee] = useState('0');
  const [allowanceFee, setAllowanceFee] = useState('0');

  useEffect(() => {
    const timeoutFunction = async () => {
      const gasFee = await getAllowanceFee(user, network, token);
      setAllowanceFee(gasFee);
    };

    let timeout: NodeJS.Timeout;
    if (hasAllowanceFee(token, amount, allowance, network)) {
      clearTimeout(timeout);
      timeout = setTimeout(timeoutFunction, 500);
    } else {
      setAllowanceFee('0');
    }

    return () => clearTimeout(timeout);
  }, [allowance, amount, token, network, user]);

  useEffect(() => {
    setNetworkFee(getNetworkFee(trxParams, allowanceFee, token, network));
  }, [trxParams, token, allowanceFee, network]);

  const handleUpdateCommission = useCallback(async () => {
    const { commission, commissionBN } = await getCommissionFee(input, token, network, ethPrice);
    setCommissionFee(commissionBN);

    const receive = getMinReceive(input, commission, token, currency, currentUnit);

    setTotalReceive(receive);
  }, [input, token, network, ethPrice, currentUnit, currency]);

  useEffect(() => {
    const timeoutFunction = () => handleUpdateCommission();

    let timeout: NodeJS.Timeout;
    {
      clearTimeout(timeout);
      timeout = setTimeout(timeoutFunction, 700);
    }

    return () => clearTimeout(timeout);
  }, [handleUpdateCommission]);

  return {
    totalReceive,
    commissionFee,
    networkFee,
    allowanceFee,
  };
};

export default useOrderDetails;

import React, { useState, useEffect } from 'react';
import { NFT } from '../../constants/types';

import Spinner from '../Shared/Spinner';
import { classNames } from 'app/utils';

const handleIPFS = (string: string) => string.replace('ipfs://', 'https://ipfs.io/ipfs/');

type FallbackProps = {
  className?: string;
};
//TODO replace this with proper fallback once available
const fallback = ({ className }: FallbackProps) => (
  <div
    className={classNames(
      className,
      'h-full w-full overflow-hidden rounded-xl bg-cover bg-blue10 items-center justify-centerflex',
    )}
  ></div>
);

const renderLoading = ({ className }: FallbackProps) => (
  <div
    className={classNames(
      className,
      'h-full w-full overflow-hidden rounded-xl bg-cover bg-blue80 items-center justify-center flex',
    )}
  >
    <Spinner />
  </div>
);

type Props = {
  nft: NFT;
  className?: string;
};

const NFTImage = ({ nft, className }: Props) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { metadata } = nft;

  const handleImgError = () => {
    setError(true);
  };

  useEffect(() => {
    setLoading(true);
    if (error) {
      setError(false);
    }
  }, [nft]);

  if (!metadata || error) {
    return fallback({ className });
  }

  if (metadata.image || metadata.image_url) {
    return (
      <>
        <img
          onError={handleImgError}
          className={classNames(
            loading ? 'hidden' : 'block',
            'rounded-xl bg-cover bg-blue80',
            className,
          )}
          src={handleIPFS(metadata.image || metadata.image_url)}
          alt={`nft image - ${nft.name}`}
          onLoad={() => setLoading(false)}
        />

        {loading && renderLoading({ className })}
      </>
    );
  }
  return <>{fallback({ className })}</>;
};

export default NFTImage;

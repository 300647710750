module.exports = {
  appName: 'wonderfi',
  version: '1.0.0',
  environment: 'staging',
  infuraProjectId: 'e3ad6bfbc1454e34927871eb4f0451da',
  webURL: 'https://app.astero.fi',
  backendURL: 'https://api.astero.fi',
  trackingEnabled: false,
  showSecurity: true,
  darkLaunchClientId: '61fd8c758684e416c6d1357c',
  idVerificationEnabled: true,
  disableNFTRoute: false,
};

import { useContext, useEffect, useState } from 'react';
import { store } from '../../context/Context';
import WalletPickerModal from '../Dashboard/WalletPicker/WalletPickerModal';
import Overlay from '../Shared/Overlay';
import AllowDialog from './AllowDialog';
import NetworkAlert from '../Shared/Alert/NetworkAlert';
import PrendingTrxAlert from '../Shared/Alert/PendingTrxAlert';
import TradeForm from './TradeForm';
import TransactionLoading from './TransactionLoading';
import ExternalDialog from './ExternalDialog';
import useTrade from 'app/common/Trade/hooks/useTrade';
import { trackEvent } from '../../utils/tracking';
import useWalletTransaction from './hooks/useWalletTransaction';

type Props = {
  defaultToken0?: string;
  defaultToken1?: string;
  onFinish: () => void;
  onCancel: () => void;
};

const Trade = ({ defaultToken0, defaultToken1, onFinish }: Props) => {
  const { state } = useContext(store);

  const { network } = state;
  const [error, setError] = useState(null);

  const {
    isWalletConnected,
    externalWalletError,
    openExternal,
    openWalletSwitch,
    handleConnectExternal,
    setOpenWalletSwitch,
    setOpenExternal,
    signMessage,
    sendTransaction,
  } = useWalletTransaction();

  const {
    allowanceValue,
    disableSwap,
    disableForm,
    enableTrx,
    tradeError,
    ethTrx,
    hasPendingTrx,
    loading,
    openAllowDialog,
    order,
    quoteValues,
    quoteError,
    connectText,
    createEnableTransaction,
    getAllowanceValue,
    getQuoteValues,
    handleCancel,
    setHasPendingTrx,
    setOpenAllowDialog,
    submitOrder,
    broadcastingText,
    swapText,
  } = useTrade({
    state,
    isWalletConnected,
    onFinish,
    trackEvent,
    signMessage,
    setOpenExternal,
    sendTransaction,
  });

  useEffect(() => {
    if (tradeError || externalWalletError) {
      setError(tradeError || externalWalletError);
    }
  }, [tradeError, externalWalletError]);

  return (
    <div>
      <div className="my-2 space-y-1">
        <NetworkAlert visible={!network?.swap_enabled} feature="Swap" />
        <PrendingTrxAlert visible={hasPendingTrx} setVisible={setHasPendingTrx} />
      </div>
      <div className="relative">
        <TradeForm
          allowance={allowanceValue}
          isWalletConnected={isWalletConnected}
          connectText={connectText}
          defaultToken0={defaultToken0}
          defaultToken1={defaultToken1}
          disableSwap={disableSwap}
          error={error}
          loading={loading}
          quote={quoteValues}
          quoteError={quoteError}
          swapText={swapText}
          handleConnectExternal={handleConnectExternal}
          getAllowanceValue={getAllowanceValue}
          getQuoteValues={getQuoteValues}
          onSubmit={submitOrder}
          setError={setError}
        />
        {disableForm && <Overlay />}
      </div>
      <AllowDialog
        open={Boolean(openAllowDialog)}
        onAllow={createEnableTransaction}
        onClose={() => setOpenAllowDialog(false)}
        tokenName={order?.token0?.symbol}
      />
      <ExternalDialog open={openExternal} handleBack={handleCancel} />
      <TransactionLoading trx={ethTrx || enableTrx} title={broadcastingText} />
      <WalletPickerModal open={openWalletSwitch} onClose={() => setOpenWalletSwitch(false)} />
    </div>
  );
};

export default Trade;

import { useState, useCallback } from 'react';
import { handleTradeError, _getAllowanceValue, canUpdateAllowance } from 'app/common/Trade';
import { validationErrors } from 'app/constants';
import { Token, AppState } from '../../../constants/types';
import { keys } from '../../../constants/tracking';
import useSendEthTrx from './useSendEthTrx';

type Props = {
  onTransactionSent?: () => void;
  token: Token;
  trackEvent: (key: string, user: any, _attributes: any) => void;
  state: AppState;
  setOpenExternal?: (open: boolean) => void;
};

const useApprovalTrx = ({
  token,
  onTransactionSent,
  setOpenExternal,
  trackEvent,
  state,
}: Props) => {
  const { user, wallet, network } = state;

  const [allowanceValue, setAllowanceValue] = useState('0');
  const [checkingAllowance, setCheckingAllowance] = useState(false);
  const [openAllowDialog, setOpenAllowDialog] = useState(false);
  const [enablingToken, setEnablingToken] = useState(false);
  const [enableTrx, setEnableTrx] = useState(null);
  const [approvalError, setApprovalError] = useState('');

  const handleCancel = useCallback(() => {
    setCheckingAllowance(false);
    setOpenAllowDialog(false);
    setEnablingToken(false);
    setOpenExternal(false);
  }, [setOpenExternal]);

  const onApprovedToken = () => {
    // Track approval event
    trackEvent(keys.APPROVE_TOKEN, user, {
      token: token?.symbol,
      spender: network?.withdraw_address,
    });
    // Proceed to transaction
    onTransactionSent();
  };

  useSendEthTrx({
    ethTrx: enableTrx,
    setEthTrx: setEnableTrx,
    setEthTrxError: setApprovalError,
    setSendingEthTrx: setEnablingToken,
    handleCancel,
    onTransactionSent: onApprovedToken,
    state,
  });

  const getAllowanceValue = useCallback(
    async (token: Token) => {
      if (!canUpdateAllowance(token, network)) {
        setAllowanceValue('0');
        return;
      }
      setCheckingAllowance(true);
      const { allowance, allowanceError } = await _getAllowanceValue(network, token, wallet);

      if (allowanceError) {
        setApprovalError(handleTradeError(allowanceError, validationErrors.FAILED_ALLOWANCE));
        setAllowanceValue('0');
        handleCancel();
        return;
      }

      setAllowanceValue(allowance);
      setCheckingAllowance(false);

      return allowance;
    },
    [network, wallet, handleCancel],
  );

  return {
    allowanceValue,
    checkingAllowance,
    enablingToken,
    enableTrx,
    approvalError,
    openAllowDialog,
    getAllowanceValue,
    setApprovalError,
    setEnableTrx,
    setEnablingToken,
    setOpenAllowDialog,
  };
};

export default useApprovalTrx;

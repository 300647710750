import React, { forwardRef } from 'react';
import { classNames } from '../../../../app/utils';

type Props = {
  label?: string;
  error?: string;
  className?: string;
  value?: string;
  maxLength?: number;
  [key: string]: any;
};

const TextInput = forwardRef<HTMLInputElement, Props>(
  ({ label, error, className = '', value, maxLength, ...props }: Props, ref) => {
    const [isFocused, setIsFocused] = React.useState(false);

    const inputStyle = () => {
      if (error) return 'input-error';
      if (isFocused) return 'bg-transparent';
      return 'border-blue80 bg-blue80 focus:outline-none focus:ring-0';
    };

    return (
      <>
        {Boolean(label) && <label className="label text-grey20">{label}</label>}
        <input
          type="text"
          className={classNames('input w-full placeholder-grey20', inputStyle(), className)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          spellCheck={false}
          ref={ref}
          value={value}
          maxLength={maxLength}
          {...props}
        />
        {Boolean(error) && (
          <label className="label">
            <span className="text-error text-small">{error}</span>
          </label>
        )}
        {maxLength && (
          <div className="small1 text-grey20 mt-1 text-right">{`${value.length}/${maxLength}`}</div>
        )}
      </>
    );
  },
);

TextInput.displayName = 'TextInput';
export default TextInput;

import { useState, useEffect } from 'react';
import { Network, NFT, User, Wallet } from '../../../constants/types';
import { getNFTs } from '../../../utils/api';

const useNFTData = (user: User, wallet: Wallet, network: Network) => {
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const res = await getNFTs(user, wallet, network);
        if (res && res.length) {
          const filteredNfts = res.filter((nft: NFT) => ['ERC721', 'ERC1155'].includes(nft.type));
          setNfts(filteredNfts);
        } else {
          setNfts([]);
        }
      } catch (error) {
        console.error(error);
        setNfts([]);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [user, wallet, network]);

  return {
    nfts,
    loading,
  };
};

export default useNFTData;

export type WalletItem = {
  id: string;
  image: string;
  name: string;
  disabled?: boolean;
};

type WalletItems = {
  [key: string]: WalletItem;
};

const wallets: WalletItems = {
  walletconnect: {
    id: 'walletconnect',
    image: 'https://gblobscdn.gitbook.com/spaces%2F-LJJeCjcLrr53DcT1Ml7%2Favatar.png?alt=media',
    name: 'WalletConnect',
  },
  coinbase: {
    id: 'coinbase',
    image: 'https://avatars.githubusercontent.com/u/18060234?s=200&v=4',
    name: 'Coinbase Wallet',
    disabled: true,
  },
  metamask: {
    id: 'metamask',
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/440px-MetaMask_Fox.svg.png',
    name: 'Metamask',
    disabled: true,
  },
  ledger: {
    id: 'ledger',
    image: 'https://avatars.githubusercontent.com/u/9784193?s=200&v=4',
    name: 'Ledger',
    disabled: true,
  },
  torus: {
    id: 'torus',
    image: '/images/wonderfi-wallet-picker-icon.png',
    name: 'WonderFi Wallet',
    disabled: true,
  },
};

export default wallets;

import { useCallback, useState } from 'react';
import { handleTradeError, sendEthTradeTransaction } from 'app/common/Trade';
import { validationErrors } from 'app/constants';
import { Order, AppState, Transaction, Wallet } from '../../../constants/types';
import useSendEthTrx from './useSendEthTrx';
import { ethers } from 'ethers';

type Props = {
  order: Order;
  onTransactionSent: () => void;
  setOpenExternal: (open: boolean) => void;
  getQuoteValues: (order: Order, walletAddress?: string) => Promise<any>;
  sendTransaction: (
    wallet: Wallet,
    params: Transaction,
  ) => Promise<ethers.providers.TransactionResponse>;
  state: AppState;
};

const useEthTrx = ({
  onTransactionSent,
  setOpenExternal,
  getQuoteValues,
  sendTransaction,
  state,
}: Props) => {
  const { wallet } = state;

  const [ethTrx, setEthTrx] = useState(null);
  const [sendingEthTrx, setSendingEthTrx] = useState(false);
  const [ethTrxError, setEthTrxError] = useState('');

  const handleCancel = useCallback(() => {
    setSendingEthTrx(false);
    setOpenExternal(false);
  }, [setOpenExternal]);

  useSendEthTrx({
    state,
    ethTrx,
    setEthTrx,
    setEthTrxError,
    setSendingEthTrx,
    handleCancel,
    onTransactionSent,
  });

  const sendEthTransaction = async (quote: {
    gas: string;
    gasPrice: string;
    to: string;
    data: string;
    value: string;
  }) => {
    setSendingEthTrx(true);

    const { trx, sendTrxError } = await sendEthTradeTransaction(quote, wallet, sendTransaction);
    if (sendTrxError) {
      setEthTrxError(handleTradeError(sendTrxError, validationErrors.FAILED_CREATE_TRANSACTION));
      handleCancel();
      return;
    }

    if (trx && trx.hash) setEthTrx(trx);
    else setEthTrx({ hash: trx });
  };

  const createEthTransaction = async (order: Order) => {
    try {
      // Get quote
      const quote = await getQuoteValues(order, wallet.address);

      // Send trx
      await sendEthTransaction(quote);
    } catch (e: any) {
      setEthTrxError(handleTradeError(e, validationErrors.FAILED_CREATE_TRANSACTION));
      handleCancel();
    }
  };

  return {
    ethTrx,
    sendingEthTrx,
    ethTrxError,
    createEthTransaction,
    handleCancelEthTrx: handleCancel,
  };
};

export default useEthTrx;

import { SyntheticEvent, useContext } from 'react';
import { store } from '../../context/Context';
import { useRouter } from 'next/router';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import AppInfo from '../../package.json';
import Link from 'next/link';
import { supportEmailURL } from '../../../app/utils/settings';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../config';
import colors from 'app/constants/colors';
import {
  GeneralIcon,
  ManageWalletIcon,
  SecurityIcon,
  HelpIcon,
  LogoutIcon,
  ReferralIcon,
} from './SettingsIcons';

declare global {
  interface Window {
    zE: any;
  }
}

const SideSettings = () => {
  const {
    dispatch,
    state: { user, userAccount },
  } = useContext(store);
  const router = useRouter();
  const {
    asPath,
    query: { wallet, network },
  } = router;
  const { logout } = useAuth0();

  const selectedSideMenu = (path: string) => {
    if (asPath === `/${wallet}/${network}/${path}`) {
      return colors.primary;
    } else {
      return colors.blue10;
    }
  };

  const routes = [
    {
      path: `/${wallet}/${network}/settings`,
      label: 'General',
      icon: <GeneralIcon fill={selectedSideMenu('settings')} />,
    },
    {
      path: `/${wallet}/${network}/wallets`,
      label: <span className="ml-1">Manage Wallets</span>,
      icon: <ManageWalletIcon fill={selectedSideMenu('wallets')} />,
    },
  ];

  if (config().showSecurity) {
    routes.push({
      path: `/${wallet}/${network}/security`,
      label: <span className="ml-1.5">Security</span>,
      icon: <SecurityIcon fill={selectedSideMenu('security')} />,
    });
  }

  routes.push({
    path: `/${wallet}/${network}/referral`,
    label: 'Referral',
    icon: <ReferralIcon fill={selectedSideMenu('referral')} />,
  });

  const handleHelp = () => {
    if (window?.zE) window?.zE?.activate();
    else router.push(supportEmailURL);
  };

  const handleLogout = () => {
    dispatch({
      type: 'logout',
    });
    logout({
      returnTo: window.location.origin,
    });
  };
  const addDefaultSrc = (ev: SyntheticEvent<HTMLImageElement>): void => {
    (ev.target as HTMLImageElement).src = '/images/wonderfi-wallet-picker-icon.png';
  };

  return (
    <>
      <Card shadow className="h-full">
        <div className="w-full text-center flex flex-col space-y-8 xl:h-full p-4 xl:min-h-[45rem]">
          {user && user.userInfo && (
            <>
              <div className="flex align-middle items-center gap-4 mt-6">
                <img
                  onError={addDefaultSrc}
                  alt="user image"
                  src={user.userInfo.picture}
                  className="w-12 h-12 rounded-full"
                />
                <div>
                  <div className="truncate bodySmall1 text-blue10 text-left">Hello:</div>
                  <div className="truncate bodySmall2 text-blue10">{user.userInfo.email}</div>
                  {userAccount?.status === 'APPROVED' ? (
                    <>
                      <p className="badge badge-success block badge-sm my-4">
                        {userAccount?.status}
                      </p>
                    </>
                  ) : userAccount?.status ? (
                    <>
                      <p className="badge badge-error block badge-sm my-4">{userAccount?.status}</p>
                      <Link href={`/${wallet}/${network}/verification`}>
                        <a className="btn btn-xs rounded-full btn-outline">Start verification</a>
                      </Link>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="w-full border-t border-gray-300" />
            </>
          )}
          <div className="space-y-4">
            {routes.map((route) => (
              <Link key={route.path} href={route.path}>
                <a className="block">
                  <Button
                    variant={asPath === route.path ? 'secondary' : 'ghost'}
                    className="w-full flex justify-start rounded-lg"
                  >
                    {route.icon ? <span className="mr-3">{route.icon}</span> : null}
                    {route.label}
                  </Button>
                </a>
              </Link>
            ))}
            <div>
              <Button variant="ghost" className="w-full flex justify-start" onClick={handleHelp}>
                <HelpIcon /> <span className="ml-4">Help</span>
              </Button>
            </div>
          </div>
          <div className="flex-1" />
          <div>
            <Button variant="ghost" className="w-full flex justify-start" onClick={handleLogout}>
              <LogoutIcon /> <span className="ml-4">Logout</span>
            </Button>
          </div>
        </div>
      </Card>
      <p className="bodySmall2 text-grey20 mt-4">App version {AppInfo.version}</p>
    </>
  );
};

export default SideSettings;

import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { classNames } from 'app/utils/';

type Props = {
  message: string;
  onClick?: (value: any) => void;
  action?: string;
  className?: string;
};

export default function SuccessAlert({ message, action, onClick, className }: Props) {
  if (!message) return null;

  return (
    <div
      className={classNames(
        'alert alert-info text-success bg-green80 p-3 z-10',
        className ? className : '',
      )}
    >
      <div className="flex-1 items-center">
        <div>
          <CheckCircleIcon className="h-5 w-5 mr-2 block" />
        </div>
        <p className="bodySmall2">
          {message}
          &nbsp;&nbsp;
          {action && (
            <span
              className="bodySmall2 underline cursor-pointer hover:opacity-75"
              onClick={onClick}
            >
              {action}
            </span>
          )}
        </p>
      </div>
    </div>
  );
}

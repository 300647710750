import BasicModal from '../Shared/BasicModal';
import { MetaProps } from '../Shared/Meta';
import LandingPage from './LandingPage';

type Props = {
  handleClose?: () => void;
  redirectUri?: string;
  meta: MetaProps;
  open?: boolean;
  redirectURL?: string;
};
const defaultHandleClose = () => {
  return;
};

const LandingPageContainer = ({
  handleClose = defaultHandleClose,
  meta,
  redirectURL,
  open = true,
}: Props) => {
  return (
    <BasicModal open={open} onClose={handleClose} padding="p-6 lg:p-8">
      <LandingPage meta={meta} redirectURL={redirectURL} />
    </BasicModal>
  );
};
export default LandingPageContainer;

import { useContext, useEffect, useState } from 'react';
import { store } from '../../../context/Context';
import Button from '../../Shared/Button';
import { addWonderFiWallet } from '../../../../app/common/Welcome';
import Meta from '../../Shared/Meta';
import { trackEvent, keys } from '../../../utils/tracking';

type Props = {
  setOnboardingReady: (vlaue: boolean) => any;
  onBoardingReady: boolean;
};

const features = [
  {
    text: 'Acquire and swap the top DeFi assets',
    image: '/icons/wallet-ready-1.png',
  },
  {
    text: 'Own the market by holding DeFi Indexes',
    image: '/icons/wallet-ready-2.png',
  },
  {
    text: 'Earn interest on crypto over time',
    image: '/icons/wallet-ready-3.png',
  },
  {
    text: 'Track the performance of your portfolio',
    image: '/icons/wallet-ready-4.png',
  },
];

type AccountFeatureProps = {
  feature: {
    text: string;
    image: string;
  };
  className?: string;
};
const AccountFeature = ({ feature, className }: AccountFeatureProps) => {
  const { text, image } = feature;
  return (
    <div className={`border-[1px] rounded-xl py-4 px-6 flex items-center ${className}`}>
      <img className="h-[34px] w-[34px]" src={image} alt=""></img>
      <div className="body2 ml-4 text-[10px] md:text-[12px]">{text}</div>
    </div>
  );
};
const WelcomePage = ({ setOnboardingReady }: Props) => {
  const { state, dispatch } = useContext(store);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state.user) {
      trackEvent(keys.EMAIL_VERIFIED, state.user);
    }
  }, [state.user]);

  useEffect(() => {
    setOnboardingReady(false);
  }, []); //eslint-disable-line

  useEffect(() => {
    if (state.watchlist?.length) {
      setOnboardingReady(true);
    }
  }, [state.watchlist]); //eslint-disable-line

  const createWonderFiWallet = async () => {
    setLoading(true);
    await addWonderFiWallet(state.user, state.network, dispatch);
    setOnboardingReady(true);
    setLoading(false);
  };

  if (state.watchlist?.length) {
    return null;
  }

  return (
    <div className="bg-blue80 flex justify-center items-center min-h-screen w-screen py-8">
      <Meta title={'Welcome'} />
      <div className="bg-white w-[80%] max-w-[1240px] flex flex-col items-center xl:items-start xl:p-[90px] px-[20px] py-[42px] rounded-[12px]">
        <div className="font-black text-blue10 text-[30px] text-center md:text-left">
          Your WonderFi account is ready!
        </div>
        <div className="subtitle1 mt-4 mb-4 text-center md:text-left">
          Create, track and manage a DeFi portfolio in one place.
        </div>
        <div className="flex items-center xl:flex-row flex-col-reverse">
          <div className="basis-1/2 flex">
            <div className="">
              {features.map((feature) => (
                <AccountFeature key={feature.text} feature={feature} className="my-4" />
              ))}
            </div>
          </div>
          <div className="w-full max-w-[430px] xl:max-w-1/2 min-w-[200px]">
            <video width="100%" autoPlay muted>
              <source src={'/videos/wallet-ready-animation.mp4'} type="video/mp4" />
            </video>
          </div>
        </div>
        <Button
          className="xl:mt-4 mt-10 flex mx-auto w-full max-w-[265px] xl:max-w-[415px]"
          onClick={createWonderFiWallet}
          disabled={loading}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};
export default WelcomePage;

import React from 'react';
import { shortNameorAddress } from '../../../app/utils';
import { Wallet } from '../../constants/types';
import WalletImage from './WalletImage';
import StatusChip from '../Shared/StatusChip';

type Props = {
  wallet: Wallet;
};

export default function ConnectedWallet({ wallet }: Props) {
  return (
    <div className="flex items-center truncate">
      <WalletImage wallet={wallet} />
      <div className="ml-3 truncate">
        <div className="flex items-end mb-0.5">
          <p className="labelUltraBold uppercase text-blue10 truncate mr-2">
            {shortNameorAddress(wallet)}
          </p>
          {wallet?.source === 'walletconnect' && <StatusChip label="Mobile App" />}
        </div>
        <p className="bodySmall2 text-grey20 truncate">{wallet?.address}</p>
      </div>
    </div>
  );
}

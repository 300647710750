import { useContext } from 'react';
import { store } from '../../context/Context';
import Button from '../Shared/Button';
import AddressInput from '../Shared/Input/AddressInput';
import Spinner from '../Shared/Spinner';
import TotalReceive from '../Trade/TotalReceive';
import TokenInput from '../Trade/TokenInput';
import TokenInputLabel from '../Trade/TokenInputLabel';
import TransactionFees from '../Trade/TransactionFees';
import ValidationError from '../Trade/ValidationError';
import VerticalSwap from '../../public/images/verticalSwap.svg';
import useWithdrawForm from 'app/common/Withdraw/hooks/useWithdrawForm';
import { inputUnits } from 'app/common/Trade/';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SwapCurrencySwitcher from '../Trade/SwapCurrencySwitcher';

type Props = {
  allowance: string;
  buttonText: string;
  isWalletConnected: boolean;
  defaultToken?: string;
  disable: boolean;
  error: string;
  loading: boolean;
  trxParams: any;
  handleConnect: () => void;
  getAllowanceValue: (token: any) => any;
  getTrxParams: (transfer: any) => void;
  onSubmit: (values: any) => void;
  setError?: (validation?: string) => void;
};

const WithdrawForm = ({
  allowance,
  buttonText,
  isWalletConnected,
  defaultToken,
  disable,
  error,
  loading,
  trxParams,
  handleConnect,
  getAllowanceValue,
  getTrxParams,
  onSubmit,
  setError,
}: Props) => {
  const { state } = useContext(store);
  const { wallet } = state;
  const { webEnableCurrencySwapForm } = useFlags();

  const {
    input,
    handleChangeInput,
    token,
    tokenOptions,
    handleChangeToken,
    balance,
    handleMax,
    handleChangeTo,
    commissionFee,
    networkFee,
    totalReceive,
    handleSubmit,
    disableSubmit,
    currentUnit,
    handleInputUnit,
  } = useWithdrawForm({
    state,
    allowance,
    defaultToken,
    disable,
    error,
    trxParams,
    getAllowanceValue,
    getTrxParams,
    onSubmit,
    setError,
  });

  return (
    <div className="form-control mt-1 mb-5 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-16">
      {webEnableCurrencySwapForm && (
        <SwapCurrencySwitcher
          className="mb-4 sm:mb-0 -mt-0.5 sm:absolute -top-16 left-32"
          inputUnits={inputUnits}
          onChange={handleInputUnit}
          value={currentUnit}
        />
      )}
      <div className="flex flex-col justify-between">
        <div>
          <TokenInputLabel label="From:" />
          <TokenInput
            amount={input}
            onChangeAmount={handleChangeInput}
            token={token}
            tokenOptions={tokenOptions}
            onChangeToken={handleChangeToken}
            balance={balance}
            showMax
            handleMax={handleMax}
            hasDisabledTokens
            showConversion
            unit={currentUnit}
          />
        </div>
        <div className="mx-auto my-6">
          <VerticalSwap alt="vertical-swap" />
        </div>
        <div>
          <div className="mb-4">
            <TokenInputLabel label="To:" />
          </div>
          <AddressInput onChange={handleChangeTo} />
        </div>
      </div>
      <div className="flex flex-col">
        <TransactionFees
          commissionFee={commissionFee}
          networkFee={networkFee}
          tokenUnits={token}
          validation={error}
        />
        <TotalReceive amount={totalReceive} token={token} />
        <div className="flex flex-col grow place-content-end">
          <ValidationError message={error} />
          {isWalletConnected ? (
            <Button full onClick={handleSubmit} disabled={disableSubmit}>
              {loading ? <Spinner /> : ''}
              &nbsp;
              {buttonText}
            </Button>
          ) : (
            <Button full onClick={handleConnect}>
              {wallet.source === 'watchlist' ? 'Switch Wallet' : 'Connect wallet'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WithdrawForm;

import { classNames } from '../../../app/utils';

export default function Divider({ text = 'OR', className = '', borderStyle = 'border-grey80' }) {
  return (
    <div className={classNames('relative', className)}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className={classNames('w-full border-t', borderStyle)} />
      </div>
      <div className="relative flex justify-center">
        <span className="px-2 bg-white bodySmall2 text-grey20">{text}</span>
      </div>
    </div>
  );
}

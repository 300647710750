import Trade from '.';
import FormModal from '../Shared/FormModal';

type Props = {
  token?: string;
  action?: string;
  open: boolean;
  onClose: (transactionSent: boolean) => void;
};

export const tradeActions = {
  BUY: 'buy',
  SELL: 'sell',
};

const TradeContainer = ({ token, action, open, onClose }: Props) => {
  return (
    <>
      <FormModal open={open} onClose={() => onClose(false)} title="Swap">
        <Trade
          defaultToken0={action === tradeActions.SELL ? token : null}
          defaultToken1={action === tradeActions.BUY ? token : null}
          onCancel={() => onClose(false)}
          onFinish={() => onClose(true)}
        />
      </FormModal>
    </>
  );
};

export default TradeContainer;

import { useState, useEffect } from 'react';
import { AppState, Token, Transfer } from '../../../constants/types';
import { validationErrors } from 'app/constants';
import { checkInsufficientFunds } from 'app/common/Trade';
import { canUpdateTransferParams, withdrawValidations } from 'app/common/Withdraw';

type Props = {
  state: AppState;
  transfer: Transfer;
  balance: string;
  input: string;
  isValidTo: boolean;
  nativeBalance: string;
  networkFee: string;
  totalReceive: number;
  setValidation: (error: string) => void;
  getTrxParams: (transfer: Transfer) => void;
  getAllowanceValue: (token: Token) => void;
};

const useWithdrawValidations = ({
  state,
  transfer,
  balance,
  input,
  isValidTo,
  nativeBalance,
  networkFee,
  totalReceive,
  setValidation,
  getTrxParams,
  getAllowanceValue,
}: Props) => {
  const { wallet, network } = state;

  const [missingFields, setMissingFields] = useState(true);
  const [insufficientFunds, setInsufficientFunds] = useState(false);

  const { token, to, amount } = transfer;

  useEffect(() => {
    setValidation('');
    setMissingFields(false);

    if (withdrawValidations.isViewedOnlyWallet(wallet)) {
      setValidation(validationErrors.VIEW_ONLY_WALLET);
      return;
    }

    if (withdrawValidations.hasMissingFields(input, token)) {
      setMissingFields(true);
      return;
    }

    if (withdrawValidations.hasInvalidAmounts(input, amount)) {
      setValidation(validationErrors.ENTER_VALID_AMOUNT);
      return;
    }

    if (withdrawValidations.hasSmallReceiveAmount(amount, totalReceive)) {
      setValidation(validationErrors.RECIEVE_AMOUNT_SMALL);
      return;
    }

    if (withdrawValidations.hasInsufficientBalance(balance, amount)) {
      setValidation(validationErrors.INSUFFICIENT_BALANCE);
      return;
    }
    if (insufficientFunds) {
      setValidation(validationErrors.INSUFFICIENT_BALANCE_TO_COVER_FEE);
      return;
    }

    if (!to) {
      setMissingFields(true);
      return;
    }

    if (!isValidTo) {
      setValidation(validationErrors.INVALID_WALLET_ADDRESS);
      return;
    }

    // All validations passed
    setValidation('');
    setMissingFields(false);
  }, [
    balance,
    input,
    isValidTo,
    totalReceive,
    amount,
    token,
    wallet,
    to,
    insufficientFunds,
    setValidation,
  ]);

  useEffect(() => {
    setInsufficientFunds(checkInsufficientFunds(token, amount, nativeBalance, networkFee, network));
  }, [nativeBalance, networkFee, amount, token, network]);

  useEffect(() => {
    const timeoutFunction = () =>
      getTrxParams({
        token,
        amount,
        to,
      });

    let timeout: NodeJS.Timeout;
    if (canUpdateTransferParams(amount, isValidTo)) {
      clearTimeout(timeout);
      timeout = setTimeout(timeoutFunction, 500);
    }

    return () => clearTimeout(timeout);
  }, [amount, token, to, isValidTo, getTrxParams]);

  useEffect(() => {
    getAllowanceValue(token);
  }, [token, getAllowanceValue]);

  return {
    missingFields,
    insufficientFunds,
  };
};

export default useWithdrawValidations;

import { useEffect, useState } from 'react';
import { tradeActions } from 'app/common/Trade';
import { AppState, Order, Transaction, Wallet } from '../../../constants/types';
import { isNativeToken } from 'app/utils/tokens';
import { keys } from '../../../constants/tracking';
import useTradeEth from './useTradeEth';
import useTradeNonEth from './useTradeNonEth';
import useTradeQuote from './useTradeQuote';

type Props = {
  state: AppState;
  onFinish: () => void;
  trackEvent: (key: string, user: any, _attributes: any) => void;
  signMessage: (wallet: Wallet, message: string) => Promise<string>;
  sendTransaction: (wallet: Wallet, params: Transaction) => Promise<any>;
  setOpenExternal: (value: boolean) => void;
  isWalletConnected: boolean;
};

const useTrade = ({
  state,
  onFinish,
  trackEvent,
  signMessage,
  sendTransaction,
  setOpenExternal,
  isWalletConnected,
}: Props) => {
  const { user, wallet, network } = state;

  const [hasPendingTrx, setHasPendingTrx] = useState(false);
  const [order, setOrder] = useState(null);
  const [tradeError, setTradeError] = useState(null);

  const onTransactionSent = () => {
    // Track swap event
    trackEvent(keys.SWAP_TOKENS, user, {
      source: order?.token0?.symbol,
      destination: order?.token1?.symbol,
      sourceAmount: order?.amount0,
      destinationAmount: order?.amount1,
    });

    onFinish();
  };

  const { loadingQuote, quoteValues, quoteError, getQuoteValues } = useTradeQuote({ state });

  const { createEthTransaction, sendingEthTrx, ethTrxError, ethTrx, handleCancelEthTrx } =
    useTradeEth({
      order,
      onTransactionSent,
      setOpenExternal,
      sendTransaction,
      getQuoteValues,
      state,
    });

  const {
    allowanceValue,
    approvalError,
    checkingAllowance,
    enablingToken,
    enableTrx,
    nonEthTrxError,
    openAllowDialog,
    sendingNonEthTrx,
    signingMessage,
    createEnableTransaction,
    createNonEthTransaction,
    getAllowanceValue,
    handleCancelNonEthTrx,
    setOpenAllowDialog,
  } = useTradeNonEth({
    order,
    onTransactionSent,
    setOpenExternal,
    getQuoteValues,
    state,
    trackEvent,
    signMessage,
    sendTransaction,
  });

  useEffect(() => {
    if (ethTrxError || nonEthTrxError || approvalError || quoteError) {
      setTradeError(ethTrxError || nonEthTrxError || approvalError || quoteError);
    }
  }, [ethTrxError, nonEthTrxError, approvalError, quoteError]);

  const handleCancel = () => {
    handleCancelEthTrx();
    handleCancelNonEthTrx();
  };

  const submitOrder = async (order: Order) => {
    setOrder(order);

    if (isNativeToken(order.token0, network)) {
      createEthTransaction(order);
    } else {
      createNonEthTransaction(order);
    }
  };

  const swapText = () => {
    if (checkingAllowance) return tradeActions.CHECKING_ALLOWANCE;
    if (loadingQuote) return tradeActions.GETTING_QUOTE;
    if (sendingEthTrx || sendingNonEthTrx) return tradeActions.SENDING_TRANSACTION;
    if (signingMessage) return tradeActions.SIGNING_MESSAGE;
    if (enablingToken) return `${tradeActions.APPROVING} ${order?.token0?.symbol}...`;
    return tradeActions.CONFIRM_AND_TRADE;
  };

  const broadcastingText = () => {
    if (enablingToken) return `${tradeActions.APPROVING} ${order?.token0?.symbol}...`;
    return tradeActions.BROADCASTING_TRANSACTION;
  };

  const connectText = () => {
    return wallet.source === 'watchlist' ? tradeActions.SWITCH_WALLET : tradeActions.CONNECT_WALLET;
  };

  const loading =
    sendingEthTrx ||
    sendingNonEthTrx ||
    checkingAllowance ||
    signingMessage ||
    loadingQuote ||
    enablingToken;

  const disableSwap =
    loading ||
    openAllowDialog ||
    hasPendingTrx ||
    !network?.swap_enabled ||
    !quoteValues ||
    !isWalletConnected;

  const disableForm = hasPendingTrx || !network?.swap_enabled;

  return {
    allowanceValue,
    disableSwap,
    disableForm,
    enableTrx,
    tradeError,
    ethTrx,
    hasPendingTrx,
    loading,
    openAllowDialog,
    order,
    quoteValues,
    quoteError,
    createEnableTransaction,
    getAllowanceValue,
    getQuoteValues,
    handleCancel,
    setTradeError,
    setHasPendingTrx,
    setOpenAllowDialog,
    submitOrder,
    broadcastingText: broadcastingText(),
    connectText: connectText(),
    swapText: swapText(),
  };
};

export default useTrade;

import { hasWalletBalance } from 'app/common/Overview';
import { useContext, useMemo } from 'react';
import { store } from '../../context/Context';
import WithdrawContainer from '../Withdraw/WithdrawContainer';

const Fund = () => {
  const {
    state: { walletBalances },
  } = useContext(store);
  const hasBalance: boolean = useMemo(() => hasWalletBalance(walletBalances), [walletBalances]);

  return <>{hasBalance && <WithdrawContainer />}</>;
};

export default Fund;

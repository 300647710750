import { useContext } from 'react';
import { store } from '../../../context/Context';
import { capitalize, classNames } from '../../../../app/utils';
import Dropdown from '../../Shared/Input/Dropdown';
import { useRouter } from 'next/router';
import { Network } from '../../../constants/types';
import DropdownItem from '../../Shared/Input/DropdownItem';
import { GlobeAltIcon } from '@heroicons/react/solid';
import config from '../../../config';
import useAlert from '../../../hooks/useAlert';

type Props = {
  onPress?: () => void;
  className?: string;
};
const NetworkPicker = ({
  onPress = () => {
    return;
  },
  className,
}: Props) => {
  const {
    state: { networks, network, wallet },
  } = useContext(store);
  const router = useRouter();
  const {
    query: { token, contract, id },
  } = router;
  const { handleSuccess } = useAlert();

  const onNetworkPress = (item: Network) => {
    onPress();
    const isNFTDetailsRoute = contract && id;
    if (token) {
      router.push(`/${wallet?.address || 'explore'}/${item.prefix}`);
    } else if (isNFTDetailsRoute) {
      router.push(`/${wallet?.address || 'explore'}/${item.prefix}/nft`);
    } else {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          network: item.prefix,
        },
      });
      handleSuccess(`Network switched to ${item.name}`);
    }
  };

  const networkURL = new URL(network?.network_icon, config().webURL).toString();

  return (
    <div className={`${classNames(className ? className : 'xl:ml-4 xl:w-[240px]')}`}>
      <Dropdown
        options={networks}
        renderValue={() => (
          <div className="flex items-center">
            {network?.network_icon ? (
              <img
                src={networkURL}
                style={{ width: 24, height: 24 }}
                alt={`${network.name}_logo`}
              />
            ) : (
              <GlobeAltIcon style={{ width: 24, height: 24 }} />
            )}
            <span className="ml-2 text-blue10 bodySmall1 font-black truncate capitalize">
              {network?.name}
            </span>
          </div>
        )}
        renderOptions={({ option: item, active }) => {
          const isSelected = capitalize(network?.name) === capitalize(item.name);

          return (
            <DropdownItem
              key={item.id}
              isSelected={isSelected}
              onClick={() => onNetworkPress(item)}
              value={capitalize(item.name)}
              active={active}
              icon={
                item?.network_icon ? (
                  <img
                    src={item?.network_icon}
                    style={{ width: 24, height: 24 }}
                    alt={`${item.name}_logo`}
                  />
                ) : (
                  <GlobeAltIcon style={{ width: 24, height: 24 }} />
                )
              }
            />
          );
        }}
      />
    </div>
  );
};

export default NetworkPicker;

type Props = {
  title: string;
  content: string;
  children: React.ReactNode;
};

const Tooltip = ({ title, content, children }: Props) => {
  return (
    <span className="relative inline-block group">
      {children}
      <span className="invisible group-hover:visible absolute z-50 py-2.5 px-4 bg-white text-left shadow-light rounded-lg left-2.5 top-11">
        <div className="bodySmall2 text-blue10">{title}</div>
        <div className="small1 text-grey20 w-[163px] mt-2">{content}</div>
      </span>
    </span>
  );
};

export default Tooltip;

import { useContext } from 'react';
import { store } from '../../../context/Context';
import { shortNameorAddress } from '../../../../app/utils';
import Dropdown from '../../Shared/Input/Dropdown';
import { useRouter } from 'next/router';
import DropdownItem from '../../Shared/Input/DropdownItem';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import { Wallet } from '../../../constants/types';
import WalletImage from '../../Wallets/WalletImage';
type Props = {
  onPress?: () => void;
};
const WalletPicker = ({
  onPress = () => {
    return;
  },
}: Props) => {
  const {
    state: { wallet, watchlist, network },
  } = useContext(store);
  const router = useRouter();
  const { query } = router;

  const onWalletPress = (item: Wallet) => {
    onPress();
    const isNFTDetailsRoute = query.contract && query.id;
    if (isNFTDetailsRoute) {
      router.push(`/${item.address}/${network.prefix}/nft`);
    } else {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          wallet: item.address,
        },
      });
    }
  };

  if (!wallet) {
    return null;
  }

  return (
    <div className="xl:ml-4 xl:w-[240px]">
      <Dropdown
        menuItemsClassName="w-[240px]"
        renderValue={() => (
          <div className="flex items-center">
            <WalletImage wallet={wallet} style={{ width: 24, height: 24 }} />
            <span className="ml-2 text-blue10 bodySmall1 font-black truncate normal-case">
              {shortNameorAddress(wallet)}
            </span>
          </div>
        )}
        options={watchlist}
        renderOptions={({ option: item, active }) => {
          const isSelected = item.id === wallet.id;
          return (
            <DropdownItem
              key={item.id}
              isSelected={isSelected}
              onClick={() => onWalletPress(item)}
              value={shortNameorAddress(item)}
              active={active}
              icon={<WalletImage wallet={item} style={{ width: 24, height: 24 }} />}
            />
          );
        }}
        renderAdditionalOption={({ active }) => {
          return (
            <DropdownItem
              key={'manage_wallets'}
              onClick={(e) => {
                e.preventDefault();
                router.push(`/${wallet.address}/${network.prefix}/wallets`);
              }}
              value={'Manage wallets'}
              active={active}
              icon={
                <div className="w-6 h-6 rounded-full flex justify-center items-center ">
                  <AdjustmentsIcon className="w-6 h-6 text-primary" aria-hidden="true" />
                </div>
              }
            />
          );
        }}
      />
    </div>
  );
};

export default WalletPicker;

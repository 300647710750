import { encrypt, decrypt } from '../../app/utils/encryption';

export const setData = async (key: string, value: any) => {
  try {
    localStorage.setItem(key, encrypt(value));
  } catch (e) {
    console.error(e);
  }
};

export const getData = async (key: string) => {
  try {
    const jsonValue = localStorage.getItem(key);
    return jsonValue != null ? decrypt(jsonValue) : null;
  } catch (e) {
    return null;
  }
};

import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import SearchBar from '../../Shared/Input/SearchBar';
import Card from '../../Shared/Card';
import { classNames } from 'app/utils';
import { getNFTName, getNFTUniqueId } from 'app/utils/nft';
import Link from 'next/link';
import { NFT } from '../../../constants/types';
import NFTImage from '../NFTImage';
import Spinner from '../../Shared/Spinner';

type Props = {
  nfts: any[];
  loading: boolean;
};

export default function CollectionList({ nfts, loading }: Props) {
  const router = useRouter();
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);
  const {
    query: { wallet, network },
  } = router;
  useEffect(() => {
    const filtered = nfts.filter((nft) => {
      return (
        nft?.metadata?.name?.toLowerCase().includes(search.toLowerCase()) ||
        nft?.id?.toLowerCase().includes(search.toLowerCase())
      );
    });
    setFiltered(filtered);
  }, [nfts, search]);

  return (
    <>
      <SearchBar
        className="flex items-center relative w-full mb-6"
        value={search}
        onChangeText={setSearch}
        onPressClear={() => {
          setSearch('');
        }}
      />
      <Card shadow>
        <div className="p-5 h2">All Collection</div>
        {!filtered || loading ? (
          <div className="border-t border-color-grey80 h-32 w-full overflow-hidden rounded-xl bg-cover items-center justify-center flex flex-col">
            <Spinner />
            <div className="mt-2 text-grey20">Loading Your Collection...</div>
          </div>
        ) : (
          filtered.map((nft: NFT) => {
            const isActive = router.asPath.indexOf(`${nft.address}/${nft.id}`) !== -1;

            return (
              <Link
                passHref
                href={`/${wallet}/${network}/nft/${nft.address}/${nft.id}`}
                key={getNFTUniqueId(nft)}
              >
                <div
                  className={classNames(
                    'w-full border-t border-color-grey80 flex items-center p-5 cursor cursor-pointer',
                    isActive ? 'bg-activeBackgroundBlue' : '',
                  )}
                >
                  <NFTImage nft={nft} className="rounded-full w-8 h-8 mr-3" />
                  <div className="bodySmall2 text-blue10 truncate">{getNFTName(nft)}</div>
                </div>
              </Link>
            );
          })
        )}
      </Card>
    </>
  );
}

import Meta, { MetaProps } from '../Shared/Meta';

type Props = {
  meta: MetaProps;
};

const DashboardLoader = ({ meta }: Props) => {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <Meta {...meta} />
      <img
        src="/images/wonderfi-icon-highres.png"
        alt="WonderFi"
        className="animate-pulse"
        style={{ height: '4em' }}
      />
    </div>
  );
};

export default DashboardLoader;

import BasicModal from '../Shared/BasicModal';
import Spinner from '../Shared/Spinner';

type Props = {
  open: boolean;
  handleBack: any;
};

export default function ExternalDialog({ open, handleBack }: Props) {
  return (
    <BasicModal open={open} onClose={handleBack} title="Confirm transaction">
      <div className="my-4">
        <p className="body1 text-grey10">Head to your wallet to confirm this action</p>
      </div>
      <div className="mt-5 flex justify-center">
        <Spinner />
      </div>
    </BasicModal>
  );
}

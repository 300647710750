import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { validationErrors } from 'app/constants';
import { store } from '../../../context/Context';
import {
  connectWindowProvider,
  isWindowProviderConnected,
  sendWindowTransaction,
  signWindowMessage,
} from '../../../utils/eth';
import WalletConnect from '../../../utils/walletconnect';
import { Transaction, Wallet } from '../../../constants/types';
import { sendWonderFiTransaction, signWonderFiMessage } from 'app/utils/torus';

const useWalletTransaction = () => {
  const router = useRouter();
  const {
    state: { wallet, network, user },
  } = useContext(store);

  const [isWalletConnected, setIsWalletConnected] = useState(true);
  const [openWalletSwitch, setOpenWalletSwitch] = useState(false);
  const [openExternal, setOpenExternal] = useState(false);
  const [externalWalletError, setExternalWalletError] = useState('');

  useEffect(() => {
    switch (wallet.source) {
      case 'torus':
        setIsWalletConnected(true);
        break;
      case 'walletconnect': {
        const connector = WalletConnect();
        if (connector?.connected) {
          connector.updateSession(connector.session);
          setIsWalletConnected(true);
        } else {
          setIsWalletConnected(false);
        }
        break;
      }
      case 'browser': {
        const isConnected = isWindowProviderConnected(wallet.address);
        setIsWalletConnected(isConnected);
        break;
      }
      default:
        setIsWalletConnected(false);
    }
  }, [wallet]);

  const handleConnectExternal = async () => {
    try {
      switch (wallet.source) {
        case 'watchlist':
          setOpenWalletSwitch(true);
          break;
        case 'walletconnect': {
          const connector = WalletConnect();
          const session = await connector.connect();
          if (
            session?.accounts?.length &&
            session.accounts[0].toLowerCase() === wallet.address.toLowerCase()
          ) {
            setIsWalletConnected(true);
          } else {
            setExternalWalletError(validationErrors.WALLET_NOT_CONNECTED);
          }
          break;
        }
        case 'browser': {
          const accounts = await connectWindowProvider();
          if (accounts?.length && accounts[0].toLowerCase() === wallet.address.toLowerCase()) {
            setIsWalletConnected(true);
          } else {
            setExternalWalletError(validationErrors.WALLET_NOT_CONNECTED);
          }
          break;
        }
        default:
          throw new Error(validationErrors.WALLET_NOT_SUPPORTED);
      }
    } catch (e) {
      console.error(e);
      router.push(`/${wallet?.address}/${network.prefix}/wallets`);
    }
  };

  const signMessage = async (wallet: Wallet, message: string) => {
    switch (wallet.source) {
      case 'watchlist':
        throw new Error(validationErrors.WALLET_NOT_CONNECTED);
      case 'walletconnect': {
        const connector = WalletConnect();
        setOpenExternal(true);
        return connector.signPersonalMessage([message, wallet.address]);
      }
      case 'torus':
        return signWonderFiMessage(user, message, network);
      case 'browser':
        return signWindowMessage(wallet.address, message);
      default:
        throw new Error(validationErrors.WALLET_NOT_SUPPORTED);
    }
  };

  const sendTransaction = async (wallet: Wallet, params: Transaction) => {
    switch (wallet.source) {
      case 'watchlist':
        throw new Error(validationErrors.WALLET_NOT_CONNECTED);
      case 'walletconnect': {
        const connector = WalletConnect();
        setOpenExternal(true);
        return connector.sendTransaction(params);
      }
      case 'torus':
        return sendWonderFiTransaction(user, params, network);
      case 'browser':
        return sendWindowTransaction(params);
      default:
        throw new Error(validationErrors.WALLET_NOT_SUPPORTED);
    }
  };

  return {
    isWalletConnected,
    openWalletSwitch,
    openExternal,
    externalWalletError,
    handleConnectExternal,
    setOpenExternal,
    setOpenWalletSwitch,
    signMessage,
    sendTransaction,
  };
};

export default useWalletTransaction;

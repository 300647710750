import mixpanel from 'mixpanel-browser';
export { keys } from 'app/constants/tracking';
import sha1 from 'crypto-js/sha1';
import ReactGA from 'react-ga';
import AppInfo from '../package.json';
import { User } from 'app/constants/types';
import config from '../config';

mixpanel.init('5a316142e49618350fedf7ab8edb14a3');

export const trackEvent = (key: string, user: User = null, _attributes: any = {}) => {
  const attributes = _attributes ? { ..._attributes } : {};
  if (user && user.userInfo) {
    attributes.email = user.userInfo.email;
  }
  attributes.version = AppInfo.version;
  attributes.platform = 'web-app';
  try {
    if (config().trackingEnabled) {
      mixpanel.track(key, attributes);
    } else {
      // eslint-disable-next-line
      console.debug(key, attributes);
    }
  } catch (e) {
    console.error(e);
  }
};

type GAEvent = {
  category: string;
  action: string;
  label?: string;
  value?: number;
};
export const trackGAEvent = (event: GAEvent) => {
  try {
    if (config().trackingEnabled) {
      ReactGA.event(event);
    }
  } catch (e) {
    console.error(e);
  }
};

type IREConversionEvent = {
  orderId: string;
  customerId: string;
  customerEmail?: string;
};

export const trackIREConversion = ({ customerEmail, ...params }: IREConversionEvent) => {
  const emailSha1 = customerEmail ? sha1(customerEmail).toString() : '';
  try {
    if (config().trackingEnabled && (window as any)?.ire) {
      (window as any).ire(
        'trackConversion',
        29276,
        { customerEmail: emailSha1, ...params },
        { verifySiteDefinitionMatch: true },
      );
    }
  } catch (e) {
    console.error(e);
  }
};

type IREIdentifyEvent = {
  customerId: string;
  customerEmail: string;
};

export const trackIREIdentify = ({ customerEmail, ...params }: IREIdentifyEvent) => {
  const emailSha1 = customerEmail ? sha1(customerEmail).toString() : '';
  try {
    if (config().trackingEnabled && (window as any)?.ire) {
      (window as any).ire('identify', { customerEmail: emailSha1, ...params });
    }
  } catch (e) {
    console.error(e);
  }
};

export const trackFBQ = (event: string, params = {}) => {
  try {
    if (config().trackingEnabled && (window as any)?.fbq) {
      (window as any).fbq('track', event, params);
    }
  } catch (e) {
    console.error(e);
  }
};

export const trackIoCVS = (event: string) => {
  try {
    if (config().trackingEnabled && (window as any)?.ioCVsEvent) {
      (window as any).ioCVsEvent(event);
    }
  } catch (e) {
    console.error(e);
  }
};

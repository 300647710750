import { ethers, BigNumber } from 'ethers';
import { Wallet, Order, Transfer, Quote, Network, GasQuote } from '../constants/types';
import erc20 from '../constants/abis/erc-20';
import abiWithdraw from '../constants/abis/withdraw';
import { parseAmount } from '../utils';

export const etherscanUrl = (network: Network) => network.explore_url;

let ethProvider: ethers.providers.BaseProvider;
export const getEthProvider = (network: Network) => {
  if (!ethProvider) {
    ethProvider = ethers.getDefaultProvider(network?.web3_endpoint);
  }
  return ethProvider;
};

export const getWithdrawContract = async (network: Network) => {
  const provider = getEthProvider(network);
  return new ethers.Contract(network?.withdraw_address, abiWithdraw, provider);
};

export const getWithdrawContractWithSigner = async (
  signer: ethers.providers.JsonRpcSigner | ethers.Wallet,
  network: Network,
) => {
  if (!signer) return null;
  return new ethers.Contract(network?.withdraw_address, abiWithdraw, signer);
};

export const getContract = async (tokenAddress: string, network: Network) => {
  if (!tokenAddress) return null;
  const provider = getEthProvider(network);
  return new ethers.Contract(tokenAddress, erc20, provider);
};

export const getContractWithSigner = async (
  tokenAddress: string,
  signer: ethers.providers.JsonRpcSigner | ethers.Wallet,
) => {
  if (!tokenAddress || !signer) return null;
  return new ethers.Contract(tokenAddress, erc20, signer);
};

export const getAllowance = async (
  wallet: Wallet,
  tokenAddress: string,
  spender: string,
  network: Network,
) => {
  try {
    const contract = await getContract(tokenAddress, network);
    if (!contract) {
      throw new Error("Can't access contract for selected token and wallet");
    }

    const allowance = await contract.allowance(wallet.address, spender);
    return allowance.toString();
  } catch (e) {
    console.error(e);
    return '0';
  }
};

export const getProviderGasPrice = async (network: Network) => {
  const provider = getEthProvider(network);
  const gasPrice = await provider.getGasPrice();

  return gasPrice;
};

export const getGasPrice = async (gasQuote: GasQuote) => {
  const getGasPrice = gasQuote?.current_gas?.gas_price.toString();
  const gasPrice = parseAmount(getGasPrice, 9); // Gas price is in gwei units
  const gasPriceToBigNumber = BigNumber.from(gasPrice);

  return gasPriceToBigNumber;
};

export const estimateTransferGas = async (
  signer: ethers.providers.JsonRpcSigner | ethers.Wallet,
  from: string,
  to: string,
  value: string,
) => {
  const contract = await getContractWithSigner(to, signer);

  if (!contract) {
    throw new Error("Can't access contract for selected token and wallet");
  }

  const gasLimit = await contract.estimateGas.transferFrom(from, to, value);
  const safeGasLimit = gasLimit.mul(15).div(10); // Increase estimated gas by 50% to account for unknowns
  return safeGasLimit;
};

export const estimateApproveGas = async (
  signer: ethers.providers.JsonRpcSigner | ethers.Wallet,
  tokenAddress: string,
  spender: string,
) => {
  const contract = await getContractWithSigner(tokenAddress, signer);
  if (!contract) {
    throw new Error("Can't access contract for selected token and wallet");
  }

  const gasLimit = await contract.estimateGas.approve(spender, ethers.constants.MaxUint256);
  return gasLimit;
};

export const approveSpendTrx = async (
  tokenAddress: string,
  spender: string,
  amount: ethers.BigNumber,
  network: Network,
  signer?: ethers.providers.JsonRpcSigner | ethers.Wallet,
) => {
  let contract;
  let gasLimit = undefined;
  if (signer) {
    contract = await getContractWithSigner(tokenAddress, signer);
    gasLimit = await contract?.estimateGas.approve(spender, amount);
  } else {
    contract = await getContract(tokenAddress, network);
  }

  if (!contract) {
    throw new Error("Can't access contract for selected token and wallet");
  }

  const data = await contract.populateTransaction.approve(spender, amount);
  return {
    ...data,
    gasLimit: gasLimit ? gasLimit.toHexString() : undefined,
  };
};

export const getTransaction = async (hash: string, network: Network) => {
  const provider = getEthProvider(network);
  if (!provider) {
    return null;
  }
  return provider.getTransaction(hash);
};

export const getTransactionCount = async (wallet: string, network: Network) => {
  const provider = getEthProvider(network);
  if (!provider) {
    return null;
  }
  return provider.getTransactionCount(wallet);
};

export const parseWeiToEther = (weiValue: number): string => {
  const value = BigNumber.from(String(weiValue));
  return ethers.utils.formatEther(value);
};

export const getSwapMessage = async (
  order: Order,
  quote: Quote,
  wallet: Wallet,
  network: Network,
) => {
  const contract = await getWithdrawContract(network);

  const orderParams = {
    token0: order.token0.address,
    token0Amount: order.amount0,
    token1: order.token1.address,
    token1Amount: order.amount1,
  };
  const quoteParams = {
    to: quote.to,
    data: quote.data,
    allowanceTarget: quote.allowanceTarget,
    fee: quote.wonderfi_fee,
  };

  const message = await contract.getSwapMessageHash(orderParams, quoteParams, wallet.address);

  return { message };
};

export const getTransferMessage = async (transfer: Transfer, wallet: Wallet, network: Network) => {
  const contract = await getWithdrawContract(network);

  const message = await contract.getTransferMessageHash(
    transfer.token.address,
    transfer.amount,
    transfer.to,
    wallet.address,
  );

  return { message };
};

export const getWallet = (privateKey: string) => new ethers.Wallet(privateKey);

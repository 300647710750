import { useContext, useState, useEffect, useMemo } from 'react';
import Script from 'next/script';
import Header from './Header';
import { store } from '../../context/Context';
import WelcomePage from './Welcome/WelcomePage';
import Sidebar from './Sidebar';
import Card from '../Shared/Card';
import { useRouter } from 'next/router';
import Unavailable from '../Access/Unavailable';
import { trackEvent, keys, trackIREIdentify } from '../../utils/tracking';
import { useAuth0 } from '@auth0/auth0-react';
import DashboardLoader from './DashboardLoader';
import Footer from './Footer';
import { pingStatus } from '../../utils/api';
import EmailVerified from '../../pages/email-verified';
import { MetaProps } from '../Shared/Meta';
import Error404 from '../Shared/Error404';
import LandingPageContainer from './LandingPageContainer';

declare global {
  interface Window {
    zESettings: any;
  }
}

type Props = {
  children: React.ReactNode;
  meta: MetaProps;
};

const DashboardWrapper = ({ children, meta }: Props) => {
  const router = useRouter();
  const { state } = useContext(store);
  const [onBoardingReady, setOnboardingReady] = useState(true);
  const [ping, setPing] = useState(null);
  const [auth0Target, setAuth0Target] = useState('');

  const [isNotFound, setIsNotFound] = useState(null);

  const { wallet, network } = router.query;

  const isAuthorize = useMemo(() => router.asPath.indexOf('authorize') !== -1, [router]);

  const isOpen = useMemo(
    () => router.asPath === '/' || router.asPath.indexOf('/explore') === 0,
    [router],
  );

  const { watchlist, primaryAccounts, networks } = state;
  const { isAuthenticated, isLoading, handleRedirectCallback } = useAuth0();

  useEffect(() => {
    if (isAuthorize) {
      return;
    }
    if (networks && networks?.length && network) {
      const isNetworkValid = networks.find(
        (n) => n.id === parseInt(network.toString(), 10) || n.prefix === network.toString(),
      );
      if (!isNetworkValid) {
        setIsNotFound(true);
        return;
      }
    }
    //user login
    if (state.user && watchlist?.length) {
      if (router.asPath === '/') {
        router.push(`/${watchlist[0].address}/${networks[0].prefix}/home`);
        return;
      }
      if (router.asPath.indexOf('/explore') === 0) {
        router.push(router.asPath.replace('explore', watchlist[0].address));
        return;
      }

      //Redirect if /wallet/network/nft/contract without any nft id
      const nftContractWithoutID =
        router.asPath.includes('/nft/') && (!router.query.contract || !router.query.id);
      if (nftContractWithoutID) {
        router.push(`/${router.query.wallet}/${router.query.network}/nft`);
        return;
      }

      if (wallet) {
        const isWalletValid = watchlist.find(
          (w) => w.address.toLocaleLowerCase() === wallet.toString().toLocaleLowerCase(),
        );
        if (!isWalletValid) {
          setIsNotFound(true);
          return;
        }

        if (!network) {
          router.push(`/${wallet}/${networks[0].prefix}/home`);
          return;
        }
      }
    } else if (!state.user && networks?.length) {
      // no user
      if (router.asPath === '/') {
        router.push(`/explore/${networks[0].prefix}`);
        return;
      }
      if (!network) {
        setIsNotFound(true);
        return;
      }
    }
    setIsNotFound(false);
  }, [isOpen, state.user, networks, wallet, watchlist, network, router, isAuthorize]);

  useEffect(() => {
    if (!state.user) {
      return;
    }
    trackEvent(keys.SCREEN_LOADED, state.user, { screen: router.pathname, path: router.asPath });
  }, [router.pathname, router.asPath, state.user]);

  useEffect(() => {
    trackIREIdentify({
      customerId: state.profile?.id || '',
      customerEmail: state.user?.userInfo?.email || '',
    });
  }, [router.pathname, router.asPath, state.user, state.profile]);

  useEffect(() => {
    pingStatus().then(setPing);
  }, []);

  useEffect(() => {
    const checkAuth0Target = async () => {
      try {
        const { appState } = await handleRedirectCallback();
        // @ts-ignore
        if (appState?.target) {
          // @ts-ignore
          setAuth0Target(appState.target);
          return;
        }
      } catch {
        setAuth0Target('');
      }
    };
    checkAuth0Target();

    return () => {
      setAuth0Target('');
    };
  }, [handleRedirectCallback, router]);

  useEffect(() => {
    if (isAuthorize) {
      return;
    }
    // this is redirect target after login
    if (watchlist && watchlist.length && networks && networks.length) {
      if (auth0Target) {
        const validAuth0Target = auth0Target.replace('explore', watchlist[0].address);
        router.push(validAuth0Target);
        return;
      }
    }
  }, [watchlist, networks, router, isAuthorize, auth0Target]);

  if (!ping) {
    return <DashboardLoader meta={meta} />;
  }

  if (!ping.ok) {
    const location = ping.msg ? ping.msg.split(':')[1] : 'Ontario';
    return <Unavailable region={location} country={''} />;
  }

  if (isLoading && !isAuthenticated) {
    return <DashboardLoader meta={meta} />;
  }
  if (!primaryAccounts) {
    return <DashboardLoader meta={meta} />;
  }

  if (['email-verified'].some((path) => router.asPath.indexOf(path) !== -1)) {
    return <EmailVerified />;
  }

  let showWarning = false;
  let province = '';
  if (
    window &&
    window.location &&
    window.location.host.indexOf('localhost') === -1 &&
    state.loggedUser
  ) {
    const { sub_division_name, country_name, has_balance } = state.loggedUser;
    if (country_name === 'Canada') {
      if (sub_division_name !== 'British Columbia' && sub_division_name !== 'Alberta') {
        if (!has_balance) {
          return <Unavailable region={sub_division_name} country={country_name} />;
        }
        showWarning = true;
        province = sub_division_name;
      }
    }
    if (country_name === 'United States') {
      if (!has_balance) {
        return <Unavailable region={sub_division_name} country={country_name} />;
      }
      showWarning = true;
      province = sub_division_name;
    }
  }

  if (state.user && !state.watchlist) {
    return <DashboardLoader meta={meta} />;
  }

  if (state.user && (!state.wallet || !onBoardingReady)) {
    return (
      <WelcomePage setOnboardingReady={setOnboardingReady} onBoardingReady={onBoardingReady} />
    );
  }

  if (!isAuthorize) {
    if (!wallet && !isOpen) {
      return <DashboardLoader meta={meta} />;
    }
  }

  if (isNotFound) {
    return <Error404 />;
  }

  if (!isOpen && !state.user) {
    return <LandingPageContainer meta={meta} />;
  }

  return (
    <div className="h-screen flex flex-col">
      <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=f74e57e4-49ab-4039-9334-6f658c51aad2"
        strategy="lazyOnload"
        onLoad={() => {
          window.zESettings = {
            webWidget: {
              offset: { vertical: '12px' },
            },
          };
        }}
      />
      <div className="relative z-10">
        <Header />
      </div>
      <div className="flex-1 overflow-auto scroll-smooth">
        <main className="pt-0 xl:py-12 mx-auto w-full h-full">
          <div className="relative flex flex-col h-full">
            {/* 3 column wrapper */}
            <div className="flex-grow w-full h-full max-w-7xl mx-auto flex flex-col">
              {/* Left sidebar & main wrapper */}
              <div className="flex-1 min-w-0 mt-7 xl:mt-0  xl:flex">
                <Sidebar />
                <Card>
                  <div className="h-full">
                    <div className="px-6 py-4 xl:p-0 xl:pl-9 xl:mt-1">
                      {showWarning && (
                        <div className="bg-red80 flex items-center justify-center rounded-md p-4 mb-6">
                          <span className="bodySmall2 text-error">
                            As part of our continuing mission to make decentralized finance
                            accessible, we have been working closely with regulators in relation to
                            DeFi and the WonderFi App. While this process is ongoing, WonderFi has
                            agreed to restrict {province} residents from accessing the WonderFi App.
                            <br />
                            <br />
                            Based on information we have received, it appears that you are located
                            in {province} and that you currently hold assets in your WonderFi
                            wallet. You will continue to have access to the WonderFi App until Oct
                            31, 2022. Please transfer your assets out of your WonderFi wallet by
                            midnight on Oct 31, 2022.
                            <br />
                            <br />
                            Please contact our support team at{' '}
                            <a href="mailto:support@wonder.fi">support@wonder.fi</a> prior to
                            transferring assets out of your WonderFi wallet so we can arrange to
                            cover all related transaction and network fees.
                          </span>
                        </div>
                      )}
                      {children}
                    </div>
                  </div>
                </Card>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardWrapper;

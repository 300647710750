import { ethers } from 'ethers';
import { Network, Wallet, NFT, transferNFT } from '../constants/types';
import erc1155 from '../constants/abis/erc-1155';
import erc721 from '../constants/abis/erc-721';
import { getEthProvider } from './eth';
import nftTransfer from '../constants/abis/nft-transfer';

const NFTstandards = { erc1155: 'ERC1155', erc721: 'ERC721' };
export const nftLink = {
  goBig: 'https://www.playgobig.com/',
  appleStore: 'https://apps.apple.com/us/app/go-big-feat-godzilla-vs-kong/id1575818020',
  googleStore: 'https://play.google.com/store/apps/details?id=com.sunmachineentertainment.gobig',
};
export const getStandardAbi = (standard: string) => {
  let abi;
  if (standard === NFTstandards.erc1155) {
    abi = erc1155;
  } else {
    abi = erc721;
  }
  return abi;
};

export const getContract = (NFTAddress: string, network: Network, standard: string) => {
  if (!NFTAddress) return null;
  const standardAbi = getStandardAbi(standard);
  const provider = getEthProvider(network);
  return new ethers.Contract(NFTAddress, standardAbi, provider);
};

export const getContractWithSigner = async (
  tokenAddress: string,
  signer: ethers.providers.JsonRpcSigner | ethers.Wallet,
  standard: string,
) => {
  if (!tokenAddress || !signer) return null;
  const standardAbi = getStandardAbi(standard);

  return new ethers.Contract(tokenAddress, standardAbi, signer);
};

export const getIsApproved = async (wallet: Wallet, NFT: NFT, network: Network) => {
  try {
    const contract = getContract(NFT.address, network, NFT.type);
    if (!contract) {
      throw new Error("Can't access contract for selected nft and wallet");
    }

    const getApprovedNFT = await contract.isApprovedForAll(
      wallet.address,
      network.nft_transfer_address,
    );

    return { isApproved: getApprovedNFT };
  } catch (e) {
    return { isApproved: false, allowanceError: e };
  }
};

export const getNFTContract = async (network: Network) => {
  const provider = getEthProvider(network);
  return new ethers.Contract(network?.nft_transfer_address, nftTransfer, provider);
};

export const getTransferMessage = async (
  transfer: transferNFT,
  wallet: Wallet,
  network: Network,
) => {
  const contract = await getNFTContract(network);

  let message;
  if (transfer.nft.type === NFTstandards.erc1155) {
    message = await contract.getTransfer1155MessageHash(
      transfer.nft.address,
      transfer.nft.id,
      transfer.quantity,
      wallet.address,
      transfer.toAddress,
    );
  } else {
    message = await contract.getTransfer721MessageHash(
      transfer.nft.address,
      transfer.nft.id,
      wallet.address,
      transfer.toAddress,
    );
  }

  return { message };
};

export const approveTransferTrx = async (
  NFT: NFT,
  network: Network,
  signer?: ethers.providers.JsonRpcSigner | ethers.Wallet,
) => {
  let contract;
  let gasLimit = undefined;
  const approval = true;

  if (signer) {
    contract = await getContractWithSigner(NFT?.address, signer, NFT?.type);
    gasLimit = await contract?.estimateGas.setApprovalForAll(
      network?.nft_transfer_address,
      approval,
    );
  } else {
    contract = await getContract(NFT.address, network, NFT.type);
  }

  if (!contract) {
    throw new Error("Can't access contract for selected token and wallet");
  }

  const data = await contract.populateTransaction.setApprovalForAll(
    network?.nft_transfer_address,
    approval,
  );

  return {
    ...data,
    gasLimit: gasLimit ? gasLimit.toHexString() : undefined,
  };
};

export const estimateTransferApproveGas = async (
  signer: ethers.providers.JsonRpcSigner | ethers.Wallet,
  nft: NFT,
  network: Network,
) => {
  const approval = true;
  const contract = await getContractWithSigner(nft?.address, signer, nft?.type);
  if (!contract) {
    throw new Error("Can't access contract for selected token and wallet");
  }

  const gasPrice = await contract?.estimateGas.setApprovalForAll(
    network?.nft_transfer_address,
    approval,
  );
  return gasPrice;
};

export const estimateTransferGas = async (
  nft: NFT,
  from: string,
  to: string,
  quantity: string,
  network: Network,
  signer?: ethers.providers.JsonRpcSigner | ethers.Wallet,
  data: Array<any> = [],
) => {
  let contract;
  if (signer) {
    contract = await getContractWithSigner(nft?.address, signer, nft?.type);
  } else {
    contract = await getContract(nft.address, network, nft.type);
  }
  if (!contract) {
    throw new Error("Can't access contract for selected token and wallet");
  }
  let gasLimit;
  if (nft.type === NFTstandards.erc1155) {
    gasLimit = await contract.estimateGas.safeTransferFrom(from, to, nft.id, quantity, data, {
      from,
    });
  } else {
    // TODO: gasLimit is needed to test once we have 721 nft
    gasLimit = await contract.estimateGas.transferFrom(from, to, nft.id, {
      from,
    });
  }
  const safeGasLimit = gasLimit.mul(15).div(10); // Increase estimated gas by 50% to account for unknowns
  return safeGasLimit;
};

export const getNFTUniqueId = (nft: NFT) => {
  return `${nft.address}_${nft.id}`;
};

export const getNFTName = (nft: NFT, fallback: string = 'Unnamed') => {
  if (!nft?.metadata) return fallback;
  let name = nft.metadata?.name || nft.metadata?.title;
  if (!name) {
    const id = nft.id.length <= 10 ? nft.id : `${nft.id.substring(0, 10)}...`;
    const type = nft.symbol || nft.name;
    name = `${type} #${id}`;
  }
  return name;
};
